import React, { useEffect, useRef, useState } from 'react'
// import './Style.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { ButtonGroup, Col, Row, Spinner } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { Table } from 'reactstrap';
import axiosClient, { link } from '../../../../axios-client';
import axios from 'axios';
import { toast } from 'react-toastify';
import { TablePagination } from '@mui/material';
import { useStateContext } from '../../../../context/ContextProvider';
import { useTranslation } from 'react-i18next';
// import Adzerk from '@adzerk/management-sdk';
// const apiKey = '0483FF10a0C13a4E89a9766a56ACE2B34D0D'
// const endpoint = 'https://cors-anywhere.herokuapp.com/https://api.kevel.co/v1/campaign/?advertiserId=3264604';

const Adzerk = require('@adzerk/decision-sdk');
const apiKey = '0483FF10a0C13a4E89a9766a56ACE2B34D0D'


function Campaign_Agancy() {
    const { t } = useTranslation()
    const { user } = useStateContext()
    const { id, userId } = useParams();

    // Variables 
    const [dataCampaign, setDataCampaign] = useState([])

    const [name, setName] = useState('')
    const [checkDailyCapAmount, setCheckDailyCapAmount] = useState(false)
    const [dailyCapAmount, setDailyCapAmount] = useState()
    const [checkLifetimeCapAmount, setCheckLifetimeCapAmount] = useState(false)
    const [lifetimeCapAmount, setLifetimeCapAmount] = useState()
    const [checkEnableFrequencyCapping, setCheckEnableFrequencyCapping] = useState(false)
    const [freqCap, setFreqCap] = useState()
    const [freqCapDuration, setFreqCapDuration] = useState()
    const [freqCapType, setFreqCapType] = useState()
    const [dontAffectParentFreqCap, setDontAffectParentFreqCap] = useState(false)

    const [selectedCampaignId, setSelectedCampaignId] = useState()

    const [loading, setLoading] = useState()

    ////////////////////////////////the size of the table//////////////////////////////
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);

    const handlePageChange = (event, value) => {
        setPage(value);
    };
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////


    // Get Campaign
    const fetchCampaign = async (advertiserId, userId) => {
        if (user) {
            setLoading(true)
            try {
                const response = await axios.get(`${link}/get-campaigns/?page=${page + 1}&page_size=${pageSize}&advertiserId=${id}&user=${userId}`)
                setDataCampaign(response?.data?.results)
                setTotalPages(Math.ceil(response.data.count / pageSize));
                console.log('RESPOOOONSE : ', response);
                setLoading(false)

            } catch (error) {
                console.log('Error Fetching : ', error);
                setLoading(false)

            }
        }
    }

    useEffect(() => {
        if (user) {
            fetchCampaign(user?.advertiser, user?.id)
        }
        console.log('USEEEEEEEEEER : ', user);

    }, [page, pageSize, user])







    return (
        !loading ?
            <div>

                <div className="d-flex justify-content-between align-items-center mt-3 ">
                    <h3>{t("Liste des campaigns")}</h3>
                   
                </div>

                {
                    dataCampaign.length !== 0 ?
                        <Table className='mt-4' style={{ width: '100%', borderColor: 'lightgray' }} responsive>
                            <thead>
                                <tr>
                                    {/* <th scope="col">Active</th> */}
                                    <th scope="col">{t("Nom")}</th>
                                    <th scope="col">ID</th>
                                    <th scope="col">{t("Début")}</th>
                                    <th scope="col">{t("Fin")}</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Impressions</th>
                                    <th scope="col">{t("Clics")}</th>
                                    <th scope="col">{t("Clics uniques")}</th>
                                    <th scope="col">Conversions</th>
                                    <th scope="col">{t("Revenue")}</th>
                                    <th scope="col">CTR %</th>
                                    <th scope="col">GMV</th>
                                    <th scope="col">ROAS</th>
                                    {/* <th scope="col">Advertiser</th> */}
                                    {/* <th scope="col">Options</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {dataCampaign.map((val, key) => {
                                    return (
                                        <tr key={key}>
                                            {/* <td className='d-flex justify-content-center align-self-center'>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" onClick={() => updateIsActive(val.Id, val.IsActive)} checked={val.IsActive} type="checkbox" role="switch" id="flexSwitchCheckDefault" style={{ cursor: 'pointer' }} />
                                                </div>
                                            </td> */}
                                            <td>

                                                <Link to={`/advertiser-flights/${val.Id}`} className=" waves-effect" onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}>
                                                    <span>{val.Name}</span>
                                                </Link>
                                                {/* <a className='d-flex flex-column' onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}>
                                        <span>Second campaign</span>
                                    </a> */}
                                            </td>
                                            <td>{val.Id}</td>
                                            <td>
                                                {val.StartDate ? new Date(val.StartDate).toLocaleString(t("fr-FR"), {
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                }) : null}
                                            </td>
                                            <td>
                                                {val.EndDate ? new Date(val.EndDate).toLocaleString(t("fr-FR"), {
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                }) : null}

                                            </td>
                                            <td>
                                                <div style={{ height: 15, width: 15, borderRadius: '50%', background: 'red' }} />
                                            </td>
                                            <td>{val.impressions}</td>
                                            <td>{val.clicks}</td>
                                            <td>{val.uniqueclicks ? val.uniqueclicks : '-'}</td>
                                            <td>{val.conversions}</td>
                                            <td>${val.revenue}</td>
                                            <td>
                                                {(parseInt(val.clicks) && parseInt(val.impressions) && parseInt(val.impressions) !== 0) ?
                                                    parseFloat((val.clicks / val.impressions) * 100).toFixed(2) + '%'
                                                    : '-'}
                                            </td>
                                            <td>${val.gmv}</td>
                                            <td>
                                                {(parseInt(val.gmv) && parseInt(val.revenue) && parseInt(val.revenue) !== 0) ?
                                                    parseFloat((val.gmv / val.revenue) * 100).toFixed(2) + '%'
                                                    : '-'}
                                            </td>
                                            {/* <td>
                                                <a className='d-flex flex-column' onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}>
                                                    <span>Test Account</span>
                                                </a>
                                            </td> */}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        :
                        <span>{t("Aucun élément trouvé.")}</span>
                }

                {/* PAGINATION */}
                {
                    dataCampaign.length !== 0 &&
                    <Row>
                        <Col lg={12} className="d-flex justify-content-end">

                            <TablePagination
                                component="div"
                                count={totalPages * pageSize} // Total number of items
                                page={page}
                                onPageChange={handlePageChange}
                                rowsPerPage={pageSize}
                                onRowsPerPageChange={handlePageSizeChange}

                                rowsPerPageOptions={[10, 20, 30, 40, 50]} // Define page size options
                                showFirstButton={true}
                                showLastButton={true}
                                sx={{
                                    ".MuiTablePagination-displayedRows": {
                                        margin: 0
                                    },
                                    ".MuiTablePagination-selectLabel": {
                                        display: 'none',
                                        margin: 0
                                    },
                                    ".MuiSelect-select": {
                                        backgroundColor: "#ededed"
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                }
                {/* PAGINATION */}


            </div >
            :
            <>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10
                }}>
                    <span>Loading</span>
                    <Spinner animation="grow" size='sm' />
                </div>
            </>
    )
}

export default Campaign_Agancy