import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

import Breadcrumb from "./Breadcrumb/reducer";
import userReducer from "./reducers/userReducers";
// import factureReducer from "./reducers/factureReducer";
// import clientReducer from "./reducers/clientReducer";
import { paiementReducer } from "./reducers/PaiementReducer";
import { devisReducer } from "./reducers/DevisReducer";





const rootReducer = combineReducers({
  // public
  Layout,
  //Breadcrumb items
  Breadcrumb,

  users: userReducer,
  // factures: factureReducer,
  paiements: paiementReducer,
  devis: devisReducer


})

export default rootReducer
