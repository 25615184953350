import { Avatar, AvatarGroup, TablePagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Badge, Button, Col, Form, InputGroup, Modal, Offcanvas, Row, Spinner, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaFilter } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../../../context/ContextProvider'
import axiosClient, { link } from '../../../axios-client'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoSearchSharp } from 'react-icons/io5'

const styles = `
.custom-avatar-group .MuiAvatar-root.MuiAvatarGroup-avatar {
  background-color: #fff; /* Custom background color */
  color: #bfbfbf; /* Custom text color */
  border: 2px solid #f5f5f5; /* Custom border color */
  border-radius: 10px; /* Custom border radius */
}

`
function Mes_Demandes() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user, updateCmdCountAgancy } = useStateContext()
  const [paginationData, setPaginationData] = useState()
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEtat, setSelectedEtat] = useState('');
  const [showCanvas, setShowCanvas] = useState(false);
  const [showCanvasImages, setShowCanvasImages] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [loading, setLoading] = useState()

  const handleCloseCanvas = () => setShowCanvas(false);
  const toggleShowCanvas = () => setShowCanvas(true);
  const handleCloseCanvasImages = () => setShowCanvasImages(false); // Updated handler
  const toggleShowCanvasImages = (images) => { // Updated function
    setSelectedImages(images);
    setShowCanvasImages(true);
  };
  const [data, setData] = useState([])
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  const [id, setId] = useState(null);
  const [demandeVideos, setDemandeVideos] = useState();

  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);

  ////////////////////////////////the size of the table//////////////////////////////
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////
  const getVideoNameFromLink = (link) => {
    // Extract the video name from the link
    const parts = link.split('/');
    const fileName = parts[parts.length - 1];
    const name = fileName.split('.').slice(0, -1).join('.'); // Remove file extension
    return name;
  };
  useEffect(() => {
    setLoading(true)
    axiosClient.get(`demandes/getMyRequests/?page=${page + 1}&page_size=${pageSize}&user=${user.id}&etat=${selectedEtat}&search_term=${searchTerm}`)
      .then((response) => {
        let filteredData = response.data.results;
        setPaginationData(response.data);

        setTotalPages(Math.ceil(response.data.count / pageSize));
        setData(filteredData);
        setLoading(false)

      })
      .catch((error) => {
        console.error(error);
        setLoading(false)
      });

  }, [user, page, pageSize, selectedEtat, searchTerm, selectedVideo])
  const handleDelete = (id) => {
    if (!id) return;

    axiosClient.delete(`/demandes/${id}/`)
      .then((response) => {
        // Remove the deleted user from the displayed data
        setData((prevData) => prevData.filter((row) => row.id !== id));

        // Show success toast notification
        toast.success(t("La commande a été supprimée avec succès"), {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });

        // Close the delete confirmation modal or any other related UI element
        handleCloseDelete();
      })
      .catch((error) => {
        console.error("Error deleting user:", error);

        // Optionally, show an error toast notification
        toast.error(t("Une erreur s'est produite lors de la suppression de commande"), {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  }
  const handleRowClick = async (id, seenAgancy, etat) => {
    try {
      if (!seenAgancy) {
        // Make an API call to check if there is an AdminResponse for this demande (id)
        const response = await axiosClient.get(`/responses/?demande_id=${id}`);
        console.log(response.data.results);

        // Check if the response exists by inspecting the response data
        if (response.data.results.length > 0) {
          // Proceed to update the seen_agancy if a response exists
          const formData = new FormData();
          formData.append("seen_agancy", true);

          const patchResponse = await axiosClient.patch(`/demandes/${id}/`, formData);
          console.log("Updated seen_agancy:", patchResponse.data);

          // Update the local state to reflect the change
          setData(rows => rows.map(row => {
            if (row.id === id) {
              return {
                ...row,
                seen_admin: true
              };
            }
            return row;
          }));

          // Update command count for the agency (and wait for the update to complete)
          await updateCmdCountAgancy();
        } else {
          console.log("No response found for this demande. Skipping seen_agancy update.");
        }
      }

      // After everything is done, navigate to the order details page
      navigate(`/order_details`, {
        state: {
          idRequest: id
        }
      });
    } catch (error) {
      console.error("Error handling row click:", error);
    }
  };



  return (
    !loading ?
    <div>
      <ToastContainer />
      <style>{styles}</style>
      <div className="d-flex justify-content-between align-items-center my-3 mb-4">
        <h3>{t("Mes commandes")}</h3>
        <div>
          <Button variant="primary" size='md' style={{ fontSize: "16px" }} onClick={toggleShowCanvas} className="me-2">
            <FaFilter />
          </Button>
          {/* <div></div> */}
          <Button style={{ fontSize: "16px" }} onClick={() => navigate("/send-request")} size='md' variant="secondary  ">{t("Envoyer une commande")}</Button>
        </div>
      </div>
      <Table style={{ borderColor: "lightgray" }} responsive hover>
        <thead>
          <tr>
            <th>{t("Objet")}</th>
            <th>Description</th>
            <th>{t("Status")}</th>
            <th>Images</th>
            <th>{t("Vidéos")}</th>
            <th>{t("Date de création")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody >
          {(paginationData?.length === 0 && !searchTerm && !selectedEtat) &&
            <tr>
              <td colSpan={9}>{t("Aucune ligne correspondante n'a été trouvée.")}</td>
            </tr>
          }
          {(paginationData?.length !== 0) &&
            data.map((demande) => (
              <tr className={` align-middle ${(!demande.seen_agancy && demande.etat === "traitée") ? 'table-secondary' : ''}`} style={{ cursor: "pointer" }} onClick={() => handleRowClick(demande.id, demande.seen_agancy, demande.etat)} key={demande.id} >

                <td>
                  {demande.object}
                </td>
                <td>
                  {demande.description}
                </td>
                <td  >
                  <h5>
                    <Badge pill bg={demande.etat === "non traitée" ? "info" : demande.etat === "traitée" ? "success" : demande.etat === "en cours" ? "warning" : ""}>
                      {demande.etat}
                    </Badge>

                  </h5>


                </td>


                <td >{demande.images.length > 0 ? <AvatarGroup className="custom-avatar-group"
                  style={{ display: "flex", alignItems: "center", justifyContent: "start" }} onClick={() => toggleShowCanvasImages(demande.images)}
                  max={3}>
                  {demande.images.map((img) => (
                    <Avatar style={{ background: "white", borderColor: "#f5f5f5", borderRadius: 10, objectFit: "cover" }} src={img.image} />
                  ))}

                </AvatarGroup> : <p>----</p>}
                </td>
                <td>{demande.videos.length > 0 ? <Button onClick={() => {
                  setDemandeVideos(demande)
                  setShowVideoModal(true)
                }}>{t("Vidéos")}</Button> : <p>----</p>} </td>

                <td>{new Date(demande.date_creation).toLocaleDateString(t("fr-FR"))}</td>
                <td>
                  <div className='d-flex justify-content-between'>
                    {/* <span onClick={() => navigate(`/edit-request`, {
                      state: {
                        idDemande: demande.id
                      }
                    })} class='mdi mdi-pencil' style={{ fontSize: 23, cursor: "pointer" }}></span> */}
                    <span

                      onClick={(e) => {
                        e.stopPropagation()
                        if (demande.etat !== "traitée") {
                          navigate(`/edit-request`, {
                            state: { idDemande: demande.id }
                          });
                        }
                      }}
                      className="mdi mdi-pencil"
                      style={{
                        fontSize: 23,
                        cursor: demande.etat === "traitée" ? "not-allowed" : "pointer",
                        color: demande.etat === "traitée" ? "lightgray" : "inherit" // Optionally change color if disabled
                      }}
                    ></span>


                    <span onClick={() => {

                      handleShowDelete()
                      setId(demande.id)
                      setDemandeVideos(demande)
                    }}
                      class='mdi mdi-close' style={{ fontSize: 23, cursor: "pointer" }}></span>
                  </div>
                </td>
              </tr>
            ))}


          {(((searchTerm || selectedEtat) && data?.length === 0)) && (
            <tr>
              <td colSpan={9}>{t("Aucune ligne correspondante n'a été trouvée.")}</td>
            </tr>
          )}
        </tbody>
      </Table>
      {/* {paginationData?.length !== 0 && */}
      {paginationData?.length > 0 &&
        <Row>
          <Col lg={12} className="d-flex justify-content-end">

            <TablePagination
              component="div"
              count={totalPages * pageSize} // Total number of items
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handlePageSizeChange}

              rowsPerPageOptions={[10, 20, 30, 40, 50]} // Define page size options
              showFirstButton={true}
              showLastButton={true}
              sx={{
                ".MuiTablePagination-displayedRows": {
                  margin: 0
                },
                ".MuiTablePagination-selectLabel": {
                  display: 'none',
                  margin: 0
                },
                ".MuiSelect-select": {
                  backgroundColor: "#ededed"
                }
              }}
            />
          </Col>
        </Row>
      }

      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Supprimer")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            {t("Annuler")}
          </Button>
          <Button variant='danger' onClick={() => handleDelete(id)}>
            {t("Supprimer")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showVideoModal} onHide={() => {
        setShowVideoModal(false)
        setSelectedVideo(null)
      }} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t("Liste des Vidéos")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={4}>
              {/* Video Titles List */}
              <ul className="list-group">
                {console.log(demandeVideos)}
                {demandeVideos?.videos.map((video, index) => (

                  <li
                    key={index}
                    className={`list-group-item ${selectedVideo === video ? "active" : ""}`}
                    onClick={() => setSelectedVideo(video)}
                    style={{ cursor: "pointer" }}
                  >

                    {getVideoNameFromLink(video.video)}
                  </li>
                ))}
              </ul>
            </Col>
            <Col md={8}>
              {/* Video Display */}
              {selectedVideo?.video ? (
                <video
                  src={selectedVideo.video}
                  controls
                  style={{ width: "100%", height: "auto" }}
                />
              )
                :
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: '#f2f2f2', width: "100%", height: "100%" }}>
                  {t("Choisissez un vidéo")}
                </div>
              }
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setShowVideoModal(false)
            setSelectedVideo(null)
          }}>
            {t("Fermer")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* } */}
      <Offcanvas style={{ width: "250px", backgroundColor: '#232939' }} scroll={true} backdrop={false} show={showCanvas} onHide={handleCloseCanvas}>
        <Offcanvas.Header style={{ color: 'white' }} closeButton>
          <Offcanvas.Title style={{ fontSize: "20px" }} >{t("Filtres")}</Offcanvas.Title>
          <style>
            {`
      .btn-close {
        filter: invert(1);
      }
    `}
          </style>
        </Offcanvas.Header>
        <Offcanvas.Body className='py-3'>

          <div className='d-flex' style={{ flexDirection: 'column', gap: 15 }}>
            <InputGroup >
              <InputGroup.Text style={{ background: "white", width: 'fit-content', borderRight: "none" }} >
                <IoSearchSharp style={{ padding: 0, margin: 0 }} />
              </InputGroup.Text>

              <Form.Control
                value={searchTerm}
                onChange={(e) => {
                  setPage(0)
                  setSearchTerm(e.target.value)
                }}
                style={{ paddingLeft: 0, marginLeft: 0, background: "white", borderLeft: "none" }}
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                placeholder={t('Recherche')}
              />
            </InputGroup>
            <span style={{ color: 'white' }}>{t("Statut")} :</span>
            <Form.Select value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value)} aria-label="Default select example">
              <option value='' >{t("Sélectionnez le statut")}</option>
              <option value="non traitée">Non traitée</option>
              <option value="en cours">En cours</option>
              <option value="traitée">Traitée</option>
            </Form.Select>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas backdrop={true} style={{ height: "250px", backgroundColor: '#2329397a' }} show={showCanvasImages} onHide={handleCloseCanvasImages} placement="bottom">
        <Offcanvas.Header className='px-3 pt-3 pb-0 m-0' closeButton>
        </Offcanvas.Header>
        <style>
          {`
                    .btn-close {
                        filter: invert(1);
                    }
                    `}
        </style>
        <Offcanvas.Body>
          {selectedImages.length > 0 ? (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: "center", alignItems: "center" }}>
              {selectedImages.map((img, index) => (
                <img
                  key={index}
                  src={img.image}
                  alt={`Image ${index}`}
                  style={{ width: '200px', height: '170px', objectFit: 'cover', cursor: 'pointer' }}
                  onClick={() => window.open(img.image, '_blank')}
                />
              ))}
            </div>
          ) : (
            <p>{t("Aucune image disponible")}</p>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
     :
     <>
         <div style={{
             display: 'flex',
             flexDirection: 'row',
             alignItems: 'center',
             gap: 10
         }}>
             <span>Loading</span>
             <Spinner animation="grow" size='sm' />
         </div>
     </>
  )
}

export default Mes_Demandes