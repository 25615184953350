import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import { PDFDocument, rgb } from 'pdf-lib';




import axiosClient from '../../../axios-client';


// import PDFModal from './PDFModal';

import { Bounce, ToastContainer, toast } from 'react-toastify';


import './index.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';






import { Document, Page } from 'react-pdf';
// import { fetchFacturesFailure, fetchFacturesRequest, fetchFacturesSuccess } from '../../store/actions/factureActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { FaFileInvoice } from 'react-icons/fa';
import { fetchDevisFailure, fetchDevisRequest, fetchDevisSuccess } from '../../store/actions/DevisActions';
import { fetchPaiementsFailure, fetchPaiementsRequest, fetchPaiementsSuccess } from '../../store/actions/PaiementActions';
// import pdfUrl from '../../../assets/docs/pdftest1.pdf';

export default function Index() {
    const [ads, setAds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [advertisers, setAdvertisers] = useState([]);
    const { t } = useTranslation();
    const [numPages, setNumPages] = useState(null);

    const [scale, setScale] = useState(1.0); // default scale
    const [pdfUrl, setPdfUrl] = useState('');
    const { devis } = useSelector(state => state.devis);
    const { paiements } = useSelector(state => state.paiements);

    const dispatch = useDispatch()

    const [devis_, setDevis_] = useState(null);
    const [paiements_, setPaiement_] = useState(null);

    const notifySuccess = (text) => toast.success(text, {
        position: "top-center",
        autoClose: 400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
    });

    const notifySuccessDownload = (text) => toast.success(text, {
        position: "top-center",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
    });

    const notifyError = (text) => toast.error(text, {
        position: "top-center",
        autoClose: 400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
    });


    const facture = {
        invoice_number: '12345',
        issue_date: '2024-08-08',
        due_date: '2024-08-22',
        subtotal: '1000.00',
        tax: '100.00',
        total_amount: '1100.00',
        status: 'Paid',
        description: 'Sample invoice description',
        payment_terms: 'Net 30',
        currency: 'USD',
    };


    const toggleModal = () => {
        if (!modalOpen) {
            generatePDF();
        }
        setModalOpen(!modalOpen);
    };

    useEffect(() => {
        dispatch(fetchDevisRequest());

        axiosClient.get(`devis/`)
            .then(response => {
                dispatch(fetchDevisSuccess(response.data.results));

                setDevis_(response.data.results)
                // setTotalPages(Math.ceil(response.data.count / 10));
            })
            .catch(error => {
                dispatch(fetchDevisFailure(error.message));
            });
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchPaiementsRequest());

        axiosClient.get(`paiements/`)
            .then(response => {
                dispatch(fetchPaiementsSuccess(response.data.results));

                setPaiement_(response.data.results)
                // setTotalPages(Math.ceil(response.data.count / 10));
            })
            .catch(error => {
                dispatch(fetchPaiementsFailure(error.message));
            });
    }, [dispatch]);

    // Function to combine devis and paiements data
    const combineDevisAndPaiements = (devisList, paiementsList) => {
        // Create a map for devis with its paiements
        const devisMap = new Map();

        // Initialize the devis map with each devis and an empty paiements array
        devisList.forEach(devis => {
            devisMap.set(devis.id, {
                ...devis,
                paiements: [] // Initialize with empty paiements
            });
        });

        // Populate the paiements in the correct devis entry
        paiementsList.forEach(paiement => {
            const devisId = paiement.devis;
            if (devisMap.has(devisId)) {
                devisMap.get(devisId).paiements.push(paiement);
            }
        });

        // Convert the map values to an array
        return Array.from(devisMap.values());
    };

    // Combine the data
    const combinedData = combineDevisAndPaiements(devis, paiements);

    console.log("Combined data is the ", combinedData);
    // console.log("all factures", factures)



    console.log("all  devis", devis)

    console.log("all  paiments", paiements)


    // Combined data structure
    const generatePDF = async () => {
        // Check if combinedData is populated
        if (!combinedData || combinedData.length === 0) {
            alert("No devis available to generate PDF.");
            return;
        }

        try {
            const pdfDoc = await PDFDocument.create();
            const page = pdfDoc.addPage([600, 800]); // Adjust size as needed
            const { width, height } = page.getSize();

            // Iterate through combinedData and generate content for each devis
            combinedData.forEach((devis, index) => {
                const yOffset = height - 50 - (index * 200); // Adjust yOffset to avoid overlap

                // Facture details
                page.drawText(`Facture n° : ${devis.id}`, { x: 225, y: yOffset, size: 25 });
                page.drawText(`Campagne : ${devis.campagne}`, { x: 50, y: yOffset - 20, size: 12 });
                page.drawText(`Date de Création : ${new Date(devis.date_creation).toLocaleDateString()}`, { x: 50, y: yOffset - 40, size: 12 });
                page.drawText(`Montant : ${devis.montant} €`, { x: 50, y: yOffset - 60, size: 12 });
                page.drawText(`Statut : ${devis.statut}`, { x: 50, y: yOffset - 80, size: 12 });

                // Add a section for paiements
                page.drawText(`Paiements:`, { x: 50, y: yOffset - 100, size: 16 });

                // Define base starting yOffset and spacing between payments
                const baseYOffset = yOffset - 120; // Starting position for the first payment
                const spacing = 100; // Space between each payment entry

                devis.paiements.forEach((paiement, paiementIndex) => {
                    // Calculate the vertical position for each payment
                    const paiementYOffset = baseYOffset - (paiementIndex * spacing);

                    // Draw text for each paiement entry with clear separation
                    page.drawText(`Paiement ${paiementIndex + 1}:`, { x: 50, y: paiementYOffset, size: 12 });
                    page.drawText(`Agences Name: ${paiement.agence_name}`, { x: 50, y: paiementYOffset - 20, size: 12 });
                    page.drawText(`Montant: ${paiement.montant} €`, { x: 50, y: paiementYOffset - 40, size: 12 });
                    page.drawText(`Date: ${new Date(paiement.date).toLocaleDateString()}`, { x: 50, y: paiementYOffset - 60, size: 12 });
                    page.drawText(`Méthode: ${paiement.methode}`, { x: 50, y: paiementYOffset - 80, size: 12 });
                });
            });

            // Serialize the PDFDocument to bytes
            const pdfBytes = await pdfDoc.save();

            // Format the current date for the filename
            const now = new Date();
            const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
            const filename = `facture-${formattedDate}.pdf`;

            // Create FormData to send PDF and email
            const formData = new FormData();
            formData.append('pdf', new Blob([pdfBytes], { type: 'application/pdf' }), filename);
            formData.append('email', 'hatimabarki3@gmail.com');
            formData.append('devisId', combinedData[0].id);

            // Send POST request with FormData
            try {
                const response = await axiosClient.post('/send-pdf-email/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.status === 200 && response.data.message) {
                    notifySuccess("Un email a été envoyé à votre adresse avec votre facture !");
                } else {
                    alert('Failed to send PDF.');
                }
            } catch (error) {
                console.error('Error sending PDF:', error);
                alert('There was an error sending the PDF.');
            }

            // Create a Blob and generate a URL for the PDF
            const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfUrl(pdfUrl);
        } catch (error) {
            console.error("Error generating PDF:", error);
            alert("There was an error generating the PDF.");
        }
    };

    const downloadPDF = (pdfUrl) => {
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'facture.pdf'; // Name the file for download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    useEffect(() => {
        console.log('Current language:', i18n.language);
        console.log('Translation for test_tanslation:', t('test_tanslation'));
    }, [i18n.language]); // Log when language changes

    const [modalOpen, setModalOpen] = useState(false);

    const handleSaveChanges = () => {
        if (pdfUrl) {
            downloadPDF(pdfUrl);
        } else {
            alert('No PDF available to download.');
        }
    };

    // const toggleModal = () => setModalOpen(!modalOpen);
    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={400}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition="bounce"
            />


            <h1>{t('bienvenue')}</h1>

            <div className="App">
                <Button color="primary" onClick={toggleModal}>
                    <FaFileInvoice style={{ marginRight: '8px' }} />
                    Factures
                </Button>
                <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
                    <ModalHeader toggle={toggleModal}>Modal Title</ModalHeader>
                    <ModalBody>
                        <div style={{ height: '70vh' }}>
                            {pdfUrl && (
                                <iframe
                                    src={pdfUrl}
                                    style={{ width: '100%', height: '70vh' }}
                                    frameBorder="0"
                                />
                            )}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>
                            Close
                        </Button>
                        <Button color="primary" onClick={handleSaveChanges}>
                            Download
                        </Button>                    </ModalFooter>
                </Modal>
            </div>
        </div>
    )
}
