import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Table, Carousel, Spinner } from 'react-bootstrap';
import './custom.css';
import { Avatar, AvatarGroup, Pagination, Stack } from '@mui/material';
import { MdCampaign, MdOutlineRadio, MdPublishedWithChanges } from 'react-icons/md';
import Slider from 'react-infinite-logo-slider'
import axiosClient, { link, pagination_size } from '../../../axios-client';
import { FaUserTie } from "react-icons/fa6";
import { GiMusicalNotes } from "react-icons/gi";
import { FaAd } from "react-icons/fa";
import ApexCharts from 'apexcharts';

import CryptoJS from 'crypto-js';
import { Height, Style } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const CardComponent = ({ bgGradientClass, newCount, oldCount, loading, description, svgPath }) => (
    <Col xs={12} sm={6} md={6} lg={4} xl={3}>
        <div className={`relative p-3 rounded-xl overflow-hidden ${bgGradientClass}`} style={{ height: '100px', borderRadius: "10px" }}>
            <div className="relative z-10 mb-1 text-white " style={{ fontWeight: "bold", fontSize: 25 }}> {loading ? <Spinner size='md' style={{ color: '#ffffff66' }} animation="border" /> : oldCount > 0 && oldCount} {newCount > 0 && <a style={{color:"#cbffdc"}}>{ `+${newCount}`}</a>} </div>
            <div className="relative z-10 text-white" style={{ fontWeight: "semi-bold", fontSize: 16 }}>{description}</div>
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ position: "absolute", right: 0, bottom: 0, height: 140, width: 140, marginRight: -30, marginBottom: -30, opacity: 25 }} className="-mr-8 -mb-8 text-white opacity-25 z-0">
                {svgPath}
            </svg>
        </div>

    </Col>
);

function DashAdmin() {

    const [loading, setLoading] = useState(true);
    const {t}=useTranslation()
    const [agencyNewCount, setAgencyNewCount] = useState(0)
    const [agencyOldCount, setAgencyOldCount] = useState(0)

    const [campaignNewCount, setCampaignNewCount] = useState(0)
    const [campaignOldCount, setCampaignOldCount] = useState(0)

    const [flightNewCount, setFlightNewCount] = useState(0)
    const [flightOldCount, setFlightOldCount] = useState(0)
    const [adsCount, setAdsCount] = useState(0)
    const [adsList, setAdsList] = useState([])
    useEffect(() => {
        Promise.all([
            axiosClient.get('count_agency/'),
            axiosClient.get('count_campaign/'),
            axiosClient.get('count_flights/')
        ])
            .then(([agencyRes, campaignRes, flightsRes]) => {
                setAgencyNewCount(agencyRes.data.new_count);
                setAgencyOldCount(agencyRes.data.old_count);

                setCampaignNewCount(campaignRes.data.new_count);
                setCampaignOldCount(campaignRes.data.old_count);

                setFlightNewCount(flightsRes.data.new_count);
                setFlightOldCount(flightsRes.data.old_count);
                setAdsCount(flightsRes.data.total_creatives);
                setAdsList(flightsRes.data.ads);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // useEffect(() => {
    //     axiosClient.get('count_agency/')
    //         .then((response) => {
    //             setAgencyNewCount(response.data.new_count);
    //             setAgencyOldCount(response.data.old_count);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });

    //     axiosClient.get('count_campaign/')
    //         .then((response) => {
    //             setCampaignNewCount(response.data.new_count);
    //             setCampaignOldCount(response.data.old_count);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    //     axiosClient.get('count_flights/')
    //         .then((response) => {
    //             setFlightNewCount(response.data.new_count);
    //             setFlightOldCount(response.data.old_count);
    //             setAdsCount(response.data.total_creatives);
    //             setAdsList(response.data.ads);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });

    // }, [])
    const [chartData, setChartData] = useState({
        series: [],
        options: {}
    });


    useEffect(() => {
        // Fetch advertisers data
        axiosClient.get('top_advertisers_stats/')
            .then((response) => {
                const data = response.data;

                // Extract names, impressions, and clicks
                const names = data.map(item => item.name);
                const impressions = data.map(item => item.impressions);
                const clicks = data.map(item => item.clicks);

                // Set the chart options
                const options = {
                    series: [
                        {
                            name: 'Impressions',
                            data: impressions,
                        },
                        {
                            name: t('Clics'),
                            data: clicks,
                        }
                    ],
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: { enabled: false },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    title: {
                        text: t('Top Annonceurs'),
                        align: 'left',

                    },
                    xaxis: {
                        categories: names,
                    },
                    yaxis: [
                        {
                            title: { text: 'Impressions' },
                        },
                        {
                            opposite: true,
                            title: { text: 'Clics' }
                        }
                    ],
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return val;
                            }
                        }
                    }
                };

                // Render the chart
                var chartOrigin = document.querySelector('#chart');
                if (chartOrigin) {
                    var chart = new ApexCharts(document.querySelector('#chart'), options);
                    chart.render();
                }
                // const chart = new ApexCharts(document.querySelector("#chart"), options);
                // chart.render();
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);



    return (
        <div>
            <div className="py-3 d-flex align-items-center justify-content-center">
                <div className='px-3' style={{ width: "100%" }}>
                    <Row className="g-3">
                        <CardComponent
                            bgGradientClass="bg-gradient-teal-green"
                            newCount={agencyNewCount}
                            oldCount={agencyOldCount}
                            amount={0}
                            description={t("Annonceurs")}
                            svgPath={
                                <FaUserTie size={22} />
                            }
                            loading={loading}
                        />
                        <CardComponent
                            bgGradientClass="bg-gradient-blue"
                            newCount={campaignNewCount}
                            oldCount={campaignOldCount}
                            amount={0}
                            description={t("Campagnes")}
                            svgPath={
                                <MdCampaign size={22} />
                            }
                            loading={loading}
                        />
                        <CardComponent
                            bgGradientClass="bg-gradient-red"
                            newCount={flightNewCount}
                            oldCount={flightOldCount}
                            amount={0}
                            description="Flights"
                            svgPath={
                                <MdPublishedWithChanges size={22} />
                            }
                            loading={loading}
                        />
                        <CardComponent
                            bgGradientClass="bg-gradient-yellow"
                            oldCount={adsCount}
                            description={t("Totale d'annonces")}
                            svgPath={
                                <FaAd size={22} />
                            }
                            loading={loading}
                        />

                    </Row>
                </div>


            </div>

            <Row style={{ padding: 0, margin: 0 }} >
                <div className='px-3 pt-4 col-xl-8 col-12 ' >
                    <Card className='p-0 bg-white' style={{ borderRadius: '10px', height: "100%" }}>
                        <Card.Body className='p-0 pt-4 bg-white'>

                            <div style={{ borderRadius: 10 }} className=' mx-3 '>
                                <div id="chart"></div>
                            </div>

                        </Card.Body>
                    </Card>
                </div>
                <div className=' px-3 pt-4 col-xl-4 col-12'>
                    <Card className='p-0 bg-white' style={{ borderRadius: '10px', height: "100%" }}>
                        <Card.Body>
                            <Card.Title className='py-2 '>Aperçu des annonces</Card.Title>

                            <Carousel indicators={false}>
                                {adsList.map((row) => (
                                    <Carousel.Item interval={3000}>
                                        <img
                                            style={{ height: "300px", objectFit: "cover", }}
                                            className="d-block  w-100"
                                            src={row}
                                            loading="lazy"
                                        />
                                    </Carousel.Item>
                                ))}

                            </Carousel>
                        </Card.Body>
                    </Card>
                </div>
            </Row>

        </div>


    )
}

export default DashAdmin