
import { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Card, Container, DropdownDivider, Form } from 'react-bootstrap';
import axiosClient from '../../../axios-client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css'

const ResetPassword = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('')

  const [email, setEmail] = useState("")
  const navigate = useNavigate()

  const { token } = useParams();

  const onSubmit = ev => {
    ev.preventDefault();
    if (email === "") {
      setMessage(t("Veuillez entrer votre adresse email"))

    } else {
      axiosClient.get('reset_email/', { params: { email: email } })
        .then((response) => {
          if (response.status === 203) {
            setMessage(response.data.message)

          } else {
            toast.success(t('Un e-mail de réinitialisation de mot de passe a été envoyé, vérifiez votre boîte de réception'), {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
            setMessage('')
            setEmail("")
            setTimeout(() => {
              navigate('/login')
            }, 5000);
          }

        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (

    <div className=" bg-image" style={{ backgroundImage: `url(https://img.freepik.com/free-photo/top-viewtop-view-manager-employee-doing-teamwork-business-office-looking-charts-laptop-display_482257-2443.jpg?t=st=1723040773~exp=1723044373~hmac=27a66410abb3661b6c5fb4a1ae5ce29ff37f2d4b817c5bd740fc588ffcbd7625&w=826)`, backgroundSize: 'cover', backgroundRepeat: "no-repeat", height: '100vh', width: "100%" }}    >
      <ToastContainer />

      <div className='' style={{ position: 'relative', height: '100%', width: "100%", background: 'rgba(0, 0, 0, 0.7)', overflow: 'hidden' }}>
        <div className=' col-xl-4 col-lg-5 col-md-6 col-12' style={{ display: "flex", height: '100%', width: "100%", alignItems: "center", justifyContent: "center" }}>
          <Card className=' wrapper mx-2 mb-5 p-lg-4 p-md-3 p-3 shadow' style={{ background: 'rgba(0, 0, 0, 0)', width: "450px" }}>
            <Card.Body className='p-1 '>

              <h2 className="fw-bold mb-4 mt-2 text-center" style={{ color: "white" }} >{t("Mot de passe oublié ?")}</h2>
              <p style={{ color: 'white', fontSize: '1rem', marginBottom: '24px' }}>{t("Entrez votre adresse email")}</p>

              {/* <Row>
          <Col md={6}>
            <Form.Group className='mb-4'>
              <Form.Label>First name</Form.Label>
              <Form.Control type='text' />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className='mb-4'>
              <Form.Label>Last name</Form.Label>
              <Form.Control type='text' />
            </Form.Group>
          </Col>
        </Row> */}
              {message &&
                <Alert variant={"danger"}>
                  {message}
                </Alert>
              }

              <Form.Group style={{ background: 'transparent', borderBottom: "2px solid white", outline: "none" }} className='mb-4'>
                <Form.Control style={{ background: 'transparent', border: "none", color: "white", outline: "none", borderRadius: "0" }} placeholder={t("votre-email@exemple.com")} onChange={(e) => setEmail(e.target.value)} size='lg' type='email' />

              </Form.Group>




              <Button onClick={onSubmit} className='mybtn w-100 mb-2' size='lg'> {t("Demander une réinitialisation")}</Button>
              <hr style={{ borderColor: "white" }} />
              <div className='d-flex  justify-content-center '>
                <h5 onClick={() => navigate("/login")} style={{ color: "white", textDecoration: 'underline', cursor: 'pointer' }}>{t("Annuler")}</h5>
              </div>

            </Card.Body>
          </Card>
        </div>
      </div>

    </div>

  )
}

export default ResetPassword