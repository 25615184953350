import { Link } from '@mui/material';
import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button, Spinner, Alert, InputGroup } from 'react-bootstrap';
import { useStateContext } from '../../../context/ContextProvider';
import axiosClient from '../../../axios-client';
import { useNavigate } from 'react-router-dom/dist';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css'
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
function Login() {
  const { t } = useTranslation()
  const titleColor = "white";
  const textColor = "gray.400";
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const { setToken, isLoading, setIsLoading } = useStateContext()
  const [message, setMessage] = useState('')
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const onSubmit = ev => {
    ev.preventDefault()
    setIsLoading(true)
    const payload = {
      email,
      password,
    }

    axiosClient.post('token/user/', payload)
      .then(({ data }) => {
        setToken(data.access);
        setIsLoading(false);
        // localStorage.setItem("ACCESS_TOKEN", data.access)
        localStorage.setItem("role", data.role)

        navigate('/')
      })
      .catch((err) => {
        const response = err.response;

        if (response.data.message === "Votre compte est désactivé") { setMessage(t(`${response.data.message}`)); }
        else
          setMessage(t("Email ou mot de passe incorrect"));

        setIsLoading(false)
        console.error("error: ", err);
      })

  }
  const isFormFilled = email && password;

  return (
    <div className="bg-image" style={{ backgroundImage: `url(https://img.freepik.com/free-photo/top-viewtop-view-manager-employee-doing-teamwork-business-office-looking-charts-laptop-display_482257-2443.jpg?t=st=1723040773~exp=1723044373~hmac=27a66410abb3661b6c5fb4a1ae5ce29ff37f2d4b817c5bd740fc588ffcbd7625&w=826)`, backgroundSize: 'cover', backgroundRepeat: "no-repeat", minHeight: '100vh', width: "100%", overflowY: 'auto' }}>
      <ToastContainer />
      <div className='' style={{ position: 'relative', minHeight: '100vh', width: "100%", background: 'rgba(0, 0, 0, 0.5)' }}>
        <div className='col-xl-4 col-lg-5 col-md-6 col-12' style={{ display: "flex", minHeight: '100vh', width: "100%", alignItems: "center", justifyContent: "center" }}>

          <Card className=' wrapper mx-2 mb-5 p-lg-4 p-md-3 p-3 shadow' style={{ background: 'rgba(0, 0, 0, 0)', width: '450px' }}>
            <Card.Body className='p-1 '>

              <h1 className="fw-bold mb-4 mt-2 text-center" style={{ color: "white" }} >{t("Se connecter")}</h1>


              {message &&
                <Alert variant={"danger"}>
                  {message}
                </Alert>
              }

              <Form.Group style={{ background: 'transparent', borderBottom: "2px solid white", outline: "none" }} className='mb-4'>
                <Form.Label style={{ color: "white", fontWeight: "lighter", marginBottom: 0 }} >Email</Form.Label>
                <Form.Control autocomplete="off" style={{ background: 'transparent', border: "none", color: "white", outline: "none", borderRadius: "0" }} placeholder={t('Entrez votre email')} onChange={(e) => setEmail(e.target.value)} size='lg' type='email' />
              </Form.Group>

              
              <Form.Group
                style={{ background: 'transparent', borderBottom: "2px solid white", outline: "none" }}
                className='mb-4'
              >
                <Form.Label
                  style={{ color: "white", fontWeight: "lighter", marginBottom: 0 }}
                >
                  {t("Mot de passe")}
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    autoComplete="new-password"
                    style={{ background: 'transparent', border: "none", color: "white", outline: "none", borderRadius: "0" }}
                    placeholder={t('Entrez votre mot de passe')}
                    onChange={(e) => setPassword(e.target.value)}
                    size='lg'
                    type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                  />
                  <Button
                    variant="trabsoarent"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEye color='white' size={"15px"}/> : <FaEyeSlash color='white'  size={"15px"}/>}  {/* Toggle between eye and eye-slash icons */}
                  </Button>
                </InputGroup>
              </Form.Group>

              {/* <div className='d-flex  mb-4'>
                <span onClick={() => navigate("/reset_password")} style={{ color: "white", textDecoration: 'underline', cursor: 'pointer' }}>Mot de passe oublié ?</span>
              </div> */}
              <div className="forget">
                <span onClick={() => navigate("/reset_password")} style={{ color: "white", textDecoration: 'underline', cursor: 'pointer' }}>{t("Mot de passe oublié ?")}</span>
              </div>

              <Button onClick={onSubmit} isLoading={isLoading} disabled={!isFormFilled} className='mybtn w-100' >  {isLoading ? <Spinner size="sm" /> : t('Connexion')}</Button>

              <div class="register">
                <p>{t("Vous n'avez pas de compte ?")} <span onClick={() => navigate("/register")} style={{ color: "white", textDecoration: 'underline', cursor: 'pointer' }}>{t("Registre")}</span></p>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

    </div>
  );
}

export default Login;
