import { createContext, useContext, useState, useEffect } from "react";
import axiosClient, { link } from "../axios-client";
import { Navigate } from "react-router-dom";
import axios from "axios";
const StateContext = createContext({
  currentUser: null,
  token: null,
  notification: null,
  setUser: () => { },
  setToken: () => { },
  setNotification: () => { },
  setIsLoading: () => { },

});

export const ContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [token, _setToken] = useState(localStorage.getItem("U_T"));
  const [count, setCount] = useState(0)
  const [countAgancy, setCountAgancy] = useState(0)

  //const [notification, _setNotification] = useState('');
  const [isLoading, _setIsLoading] = useState(false);


  useEffect(() => {
    if (token) {
      axiosClient
        .get("auth/user/")
        .then(({ data }) => {
          setUser(data);
          // console.log(data);

        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("U_T", token);
    } else {
      localStorage.removeItem("U_T");
    }
  };

  function updateUser() {
    axiosClient
      .get("auth/user/")
      .then(({ data }) => {
        setUser(data);
        // console.log(data);

      })
      .catch((err) => {
        console.log(err);
      });
  }

  
  function updateCmdCountAdmin() {
    axios.get(link+`count_cmd_admin/`)
      .then((response) => {
        setCount(response.data.count);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function updateCmdCountAgancy() {
    axiosClient
      .get("auth/user/")
      .then(({ data }) => {
        axiosClient.get(`count_cmd_agancy/${data.id}/`)
        .then((response) => {
          setCountAgancy(response.data.count);

        })
        .catch((error) => {
          console.log(error);
        });
      })
      .catch((err) => {
        console.log(err);
      });


  }

  const setIsLoading = (v) => {
    _setIsLoading(v);
  };





  return (
    <StateContext.Provider
      value={{

        user,
        setUser,
        token,
        setToken,
        setIsLoading,
        isLoading,
        updateUser,
        count,
        setCount,
        updateCmdCountAdmin,
        updateCmdCountAgancy,
        setCountAgancy,
        countAgancy
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
export const useStateContext = () => useContext(StateContext);
