import { success } from "toastr";

// Define action types for Devis
export const FETCH_DEVIS_REQUEST = 'FETCH_DEVIS_REQUEST';
export const FETCH_DEVIS_SUCCESS = 'FETCH_DEVIS_SUCCESS';
export const FETCH_DEVIS_FAILURE = 'FETCH_DEVIS_FAILURE';

export const ADD_DEVIS_REQUEST = 'ADD_DEVIS_REQUEST';
export const ADD_DEVIS_SUCCESS = 'ADD_DEVIS_SUCCESS';
export const ADD_DEVIS_FAILURE = 'ADD_DEVIS_FAILURE';

export const UPDATE_DEVIS_REQUEST = 'UPDATE_DEVIS_REQUEST';
export const UPDATE_DEVIS_SUCCESS = 'UPDATE_DEVIS_SUCCESS';
export const UPDATE_DEVIS_FAILURE = 'UPDATE_DEVIS_FAILURE';

export const DELETE_DEVIS_REQUEST = 'DELETE_DEVIS_REQUEST';
export const DELETE_DEVIS_SUCCESS = 'DELETE_DEVIS_SUCCESS';
export const DELETE_DEVIS_FAILURE = 'DELETE_DEVIS_FAILURE';

// Fetch devis
export const fetchDevisRequest = () => ({
    type: FETCH_DEVIS_REQUEST
});

export const fetchDevisSuccess = (devis) => ({
    type: FETCH_DEVIS_SUCCESS,
    payload: devis
});

export const fetchDevisFailure = (error) => ({
    type: FETCH_DEVIS_FAILURE,
    payload: error
});

// Add devis
export const addDevisRequest = (devisData) => ({
    type: ADD_DEVIS_REQUEST,
    payload: devisData
});

export const addDevisSuccess = () => ({
    type: ADD_DEVIS_SUCCESS
});

export const addDevisFailure = (error) => ({
    type: ADD_DEVIS_FAILURE,
    payload: error
});

// Update devis
export const updateDevisRequest = (devisData) => ({
    type: UPDATE_DEVIS_REQUEST,
    payload: devisData
});

export const updateDevisSuccess = () => ({
    type: UPDATE_DEVIS_SUCCESS
});

export const updateDevisFailure = (error) => ({
    type: UPDATE_DEVIS_FAILURE,
    payload: error
});

// Delete devis
export const deleteDevisRequest = (devisId) => ({
    type: DELETE_DEVIS_REQUEST,
    payload: devisId
});

export const deleteDevisSuccess = () => ({
    type: DELETE_DEVIS_SUCCESS
});

export const deleteDevisFailure = (error) => ({
    type: DELETE_DEVIS_FAILURE,
    payload: error
});
