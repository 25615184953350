import axios from "axios";

// export const link = process.env.REACT_APP_API_LINK;
// export const link = "http://127.0.0.1:8000";
export const link = 'https://api-ads.digi-screen.com'


const axiosClient = axios.create({
  // baseURL: process.env.REACT_APP_API_LINK,
  // baseURL: "http://127.0.0.1:8000",
  // baseURL: "http://192.168.100.183:8000/",
  baseURL: "https://api-ads.digi-screen.com",


});


export const idTemplate = 9298;

export const pagination_size = 10

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("U_T");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});


export default axiosClient;