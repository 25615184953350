
import { useParams } from 'react-router-dom';

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import axiosClient, { link } from '../../../axios-client';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css'

const ResetPwdPage = () => {
  const { t } = useTranslation();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { userId, token, expires } = useParams();
  const navigate = useNavigate();
  const [isExpired, setIsExpired] = useState(false);
  ////////////////////////// VALIDATION DE MOT DE PASSE /////////////////////////////////////////
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordColor, setPasswordColor] = useState('');
  useEffect(() => {
    const expirationTime = new Date(expires);
    const currentTime = new Date();

    if (expirationTime > currentTime) {
      setIsExpired(false);
    } else {
      setIsExpired(true);
    }
  }, [expires]);


  const checkPasswordStrength = () => {
    const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
    const minLengthRegex = /^.{8,}$/;
    const startLength = /^.{2,}$/;
    const digitRegex = /\d/;
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;

    let missingRequirements = [];

    if (!specialchar.test(newPassword)) {
      missingRequirements.push(t("caractère spécial"));
    } else if (!lowercaseRegex.test(newPassword)) {
      missingRequirements.push(t("minuscule"));
    } else if (!uppercaseRegex.test(newPassword)) {
      missingRequirements.push(t("majuscule"));
    } else if (!digitRegex.test(newPassword)) {
      missingRequirements.push(t("chiffres"));
    } else if (!minLengthRegex.test(newPassword)) {
      missingRequirements.push(t("longueur minimale de 8 caractères"));
    }

    if (missingRequirements.length > 0) {
      const missingText = `${t("Vous avez besoin de")} ${missingRequirements.join(", ")} ${t("dans votre mot de passe.")}`;
      setPasswordStrength(missingText);
      setPasswordColor('#ff98a9');
    } else {
      setPasswordStrength(t('Le mot de passe est correct!'));
      setPasswordColor('#a7f2a7');
    }
  }
  useEffect(() => {
    checkPasswordStrength();
  }, [newPassword]);



  // check the password complexity
  const isPasswordValid = (password) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
    const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
    const minLengthRegex = /^.{8,}$/;
    const digitRegex = /\d/;
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;

    let errors = [];

    if (!minLengthRegex.test(password)) {
      errors.push(t('Le mot de passe doit comporter au moins 8 caractères.'));
    }

    if (!digitRegex.test(password)) {
      errors.push(t('Le mot de passe doit contenir au moins un chiffre.'));
    }

    if (!lowercaseRegex.test(password)) {
      errors.push(t('Le mot de passe doit contenir au moins une lettre minuscule.'));
    }

    if (!uppercaseRegex.test(password)) {
      errors.push(t('Le mot de passe doit contenir au moins une lettre majuscule.'));
    }
    if (!specialchar.test(password)) {
      errors.push(t('Le mot de passe doit contenir au moins un caractère spécial (@#$%^&+=).'));
    }
    if (password.length > 20) {
      errors.push(t('Le mot de passe ne doit pas dépasser 20 caractères.'));
    }

    if (errors.length > 0) {
      setMessage(errors[0]);
      return false;
    }

    return passwordRegex.test(password);
  };

  // reset password 
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!isPasswordValid(newPassword)) {
      return;
    }
    try {
      const response = await axios.put(`${link}/reset_password/${userId}/`, {
        new_password: newPassword,
        confirm_password: confirmPassword,
      });
      if (response.data.success) {

        toast.success(t('le mot de passe est modifié avec succès'), {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        setNewPassword('')
        setConfirmPassword('')
        setMessage('')
        setTimeout(() => {
          navigate('/login')
        }, 3000);

      }
      setMessage(response.data.error);
    } catch (err) {
      setError(err.response.data.error);
    }
  };

  return (
    <div className=" bg-image" style={{ backgroundImage: `url(https://img.freepik.com/free-photo/top-viewtop-view-manager-employee-doing-teamwork-business-office-looking-charts-laptop-display_482257-2443.jpg?t=st=1723040773~exp=1723044373~hmac=27a66410abb3661b6c5fb4a1ae5ce29ff37f2d4b817c5bd740fc588ffcbd7625&w=826)`, backgroundSize: 'cover', backgroundRepeat: "no-repeat", height: '100vh', width: "100%" }}    >
      <ToastContainer />
      <div className='' style={{ position: 'relative', height: '100%', width: "100%", background: 'rgba(0, 0, 0, 0.7)', overflow: 'hidden' }}>
        <div className=' col-xl-4 col-lg-5 col-md-6 col-12' style={{ display: "flex", height: '100%', width: "100%", alignItems: "center", justifyContent: "center" }}>

          {isExpired ? (
            <Alert variant={"danger"}>
              {t("Le lien a expiré . Vous avez dépassé le temps imparti")}
            </Alert>

          ) : (
            <Card className=' wrapper mx-2 mb-5 p-lg-4 p-md-3 p-3 shadow' style={{ background: 'rgba(0, 0, 0, 0)', width: "450px" }}>
              <Card.Body className='p-1 '>

                <h3 className="fw-bold mb-4 mt-2 text-center" style={{ color: "white" }} >{t("Réinitialisation de mot de passe")}</h3>

                {/* <Row>
          <Col md={6}>
            <Form.Group className='mb-4'>
              <Form.Label>First name</Form.Label>
              <Form.Control type='text' />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className='mb-4'>
              <Form.Label>Last name</Form.Label>
              <Form.Control type='text' />
            </Form.Group>
          </Col>
        </Row> */}
                {message &&
                  <Alert variant={"danger"}>
                    {message}
                  </Alert>
                }

                <Form.Group style={{ background: 'transparent', borderBottom: "2px solid white", outline: "none" }} className='mb-4'>
                  <Form.Label style={{ color: "white" }}>{t("Nouveau mot de passe")}</Form.Label>
                  <Form.Control style={{ background: 'transparent', border: "none", color: "white", outline: "none", borderRadius: "0" }} value={newPassword} onChange={(e) => setNewPassword(e.target.value)}
                    onCopy={(e) => e.preventDefault()} size='lg' type='password' />

                </Form.Group>
                {newPassword.length > 0 && (
                  <div className='mb-2'>
                    <span style={{ color: passwordColor }}>
                      {passwordStrength}
                    </span>
                  </div>

                )}

                <Form.Group style={{ background: 'transparent', borderBottom: "2px solid white", outline: "none" }} className='mb-4'>
                  <Form.Label style={{ color: "white" }}>{t("Confirmer mot de passe")}</Form.Label>
                  <Form.Control style={{ background: 'transparent', border: "none", color: "white", outline: "none", borderRadius: "0" }} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} size='lg' type='password' />

                </Form.Group>




                <Button className='mybtn w-100 mb-2' onClick={handleFormSubmit} size='lg'> {t("Réinitialiser")}</Button>
                {/* <hr style={{ borderColor: "white" }} />
              <div className='d-flex  justify-content-center '>
                <h5 onClick={() => navigate("/login")} style={{ color: "white", textDecoration: 'underline', cursor: 'pointer' }}>Annuler</h5>
              </div> */}

              </Card.Body>
            </Card>
          )}
        </div>
      </div>

    </div>
  );

}

export default ResetPwdPage