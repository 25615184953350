import React from "react"
// import { Redirect } from "react-router-dom"






import Dash from "../pages/dash_"
import Campaign from "../pages/campaign/Campaign"
import Flight from "../pages/campaign/flights/Flight"
import AddFlight from "../pages/campaign/flights/AddFlight"
import AllUsers from "../pages/Users/AllUsers"

const adminRoutes = [

  { path: "/dash", component: Dash },
  { path: "/campaigns", component: Campaign },
  { path: "/flights", component: Flight },
  { path: "/flights/:id", component: Flight },
  { path: "/add-flight/:id", component: AddFlight },
  { path: "/users", component: AllUsers },


]



export { adminRoutes }