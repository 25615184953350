import React, { useState, useEffect } from 'react';
import { Button, Form, Alert, Modal, Image, Row, Col, Card, CardBody, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
import axiosClient from '../../../axios-client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { IoMdClose } from "react-icons/io";
import { useStateContext } from '../../../context/ContextProvider';

function Add_demande() {
    const { t } = useTranslation()
    const { user,updateCmdCountAdmin } = useStateContext()
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState(false);
    const [imageList, setImageList] = useState([]);
    const [selectedImageList, setSelectedImageList] = useState([]);
    const [inputKey, setInputKey] = useState(0);
    const [videoList, setVideoList] = useState([]); // Added for multiple videos
    const [selectedVideoList, setSelectedVideoList] = useState([]); // Added for multiple videos
    const [isLoading, setIsLoading] = useState();
    const [uploaded, setUploaded] = useState([])
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);

    const handleShow = () => setShowModal(true);


    useEffect(() => {
    }, [user.id]);

    const handleChangeImage = (e) => {
        const images = e.target.files;
        const extensions = ['.png', '.jpg', '.jpeg', '.webp'];

        if (images && images.length > 0) {
            if (selectedImageList.length + images.length > 5) {
                toast.error(t("Vous ne pouvez sélectionner que 5 images au maximum !"), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                setInputKey((prevKey) => prevKey + 1);
                return;
            }

            const selectedImages = Array.from(images);

            selectedImages.forEach(async (image) => {
                if (!extensions.includes(image.name.substring(image.name.lastIndexOf(".")))) {

                    toast.error(t("Extension de fichier non valide. Veuillez utiliser des fichiers .png, .jpg, .jpeg ou .webp."), {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    const imageURL = URL.createObjectURL(image);
                    const resizedImage = await new Promise((resolve, reject) => {
                        Resizer.imageFileResizer(
                            image,
                            451,
                            800,
                            'png',
                            100,
                            0,
                            (uri) => resolve(uri),
                            'file'
                        );
                    });
                    setImageList((prevImageList) => [...prevImageList, resizedImage]);
                    setSelectedImageList((prevSelectedImages) => [...prevSelectedImages, imageURL]);
                }
            });

            setInputKey((prevKey) => prevKey + 1);
        }
    };

    const handleVideoChange = (e) => {
        const videos = e.target.files;

        if (videos && videos.length > 0) {
            if (selectedVideoList.length + videos.length > 5) {
                toast.error(t("Vous ne pouvez sélectionner que 5 vidéos au maximum !"), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                setInputKey((prevKey) => prevKey + 1);
                return;
            }

            const selectedVideos = Array.from(videos);

            selectedVideos.forEach((video) => {
                const videoURL = URL.createObjectURL(video);
                setVideoList((prevVideoList) => [...prevVideoList, video]);
                setSelectedVideoList((prevSelectedVideos) => [...prevSelectedVideos, videoURL]);
            });

            setInputKey((prevKey) => prevKey + 1);
        }
    };

    const addImages = (data) => {
        const formDataArray = imageList.map((image) => {
            const formData = new FormData();
            formData.append('demande', data.id);
            formData.append('image', image);
            return formData;
        });

        Promise.all(formDataArray.map((formData, index) => {
            let progressBar = 0;

            return axiosClient.post("/demande-images/", formData, {
                onUploadProgress: (data) => {
                    progressBar = Math.round((data.loaded / data.total) * 100);
                    setUploaded({
                        item: index,
                        uploaded,
                        progressBar,
                    });
                },
            })
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.error('Error uploading image:', error.response?.data || error.message);
                    throw error;
                });

        }))
            .then((responses) => {
                const newImages = responses.map(response => ({
                    id: response.id,
                    demande: data.id,
                    image: response.image,
                }));

                data.images = newImages;
                console.log('List image inside loop: ', data);
                // updateCmdCountAdmin()
                navigate('/my-requests');
                
            })
            .catch((error) => {
                console.error('Error uploading images:', error.response?.data || error.message);
            });
    };

    const addVideos = (data) => {
        const formDataArray = videoList.map((video) => {
            const formData = new FormData();
            formData.append('demande', data.id);
            formData.append('video', video);
            return formData;
        });

        Promise.all(formDataArray.map((formData, index) => {
            return axiosClient.post("/demande-videos/", formData)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.error('Error uploading video:', error.response?.data || error.message);
                    throw error;
                });
        }))
            .then((responses) => {
                const newVideos = responses.map(response => ({
                    id: response.id,
                    demande: data.id,
                    video: response.video,
                }));

                data.videos = newVideos;
                console.log('List video inside loop: ', data);
            })
            .catch((error) => {
                console.error('Error uploading videos:', error.response?.data || error.message);
            });
    };

    const handleRemoveImage = (index) => {
        const newSelectedImageList = [...selectedImageList];
        newSelectedImageList.splice(index, 1);

        setSelectedImageList(newSelectedImageList);
    };

    const handleRemoveVideo = (index) => {
        const newSelectedVideoList = [...selectedVideoList];
        newSelectedVideoList.splice(index, 1);

        setSelectedVideoList(newSelectedVideoList);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formData = new FormData();

        formData.append('agency', user?.id);
        formData.append('object', title);
        formData.append('description', description);
        formData.append('status', status);

        setMessage('');

        axiosClient.post('/demandes/', formData)
            .then((response) => {
                addImages(response.data);
                addVideos(response.data);
                // updateCmdCountAdmin()
                setIsLoading(false)
            })
            .catch((err) => {
                const response = err.response;
                setMessage(response.data);
            });
    };
    const handleConfirmSubmit = (e) => {
        onSubmit(e);
        setShowModal(false);  // Close the modal after submission
    };

    const isEmpty = !title.trim() || !description.trim();
    return (
        <React.Fragment>
            <ToastContainer />
            <h3 className="px-2 my-2 my-sm-4">{t("Nouvelle commande")}</h3>
            <Card className="container py-3">
                <CardBody className="container px-3 px-sm-5 py-2">
                    <div className="container mt-4" >
                        {message && <Alert variant="danger">{message}</Alert>}
                        <Form style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>

                            <Form.Group className='mb-3' controlId="Title">
                                <Form.Label>{t("Objet")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t("Titre de votre commande")}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="description">
                                <Form.Label>{t("Description")}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder={t("Entrez la description ici")}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className='' controlId="imageUpload">
                                <Form.Label>{t("Images")}</Form.Label>
                                <Form.Control
                                    key={inputKey}
                                    type="file"
                                    accept=".jpg,.jpeg,.png,.webp"
                                    multiple
                                    onChange={handleChangeImage}
                                // style={{ padding: 10 }}
                                />
                            </Form.Group>

                            <div className="container my-2">
                                <Row className="g-3">
                                    {selectedImageList?.map((url, index) => (
                                        <Col
                                            key={index}
                                            xs={6} sm={6} md={4} lg={3} // These define the grid size per screen size
                                            className="image-container"
                                            style={{ position: "relative" }}

                                        >
                                            <Image
                                                src={url}
                                                alt={`image-${index}`}
                                                style={{
                                                    borderRadius: "5px",
                                                    width: "100%",
                                                    height: "150px",
                                                    objectFit: "cover"
                                                }}
                                            />
                                            <button
                                            type="button"
                                                className="btn btn-danger"
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    position: "absolute",
                                                    top: "8px",
                                                    right: "12px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: 0,
                                                    borderRadius: "50%",
                                                    fontSize: "12px",
                                                }}
                                                onClick={() => handleRemoveImage(index)}
                                            >
                                                <IoMdClose size={16} />
                                            </button>
                                        </Col>
                                    ))}
                                </Row>


                            </div>


                            <Form.Group className='' controlId="videoUpload" >
                                <Form.Label>{t("Vidéos")}</Form.Label>
                                <Form.Control
                                    key={inputKey + 1}
                                    type="file"
                                    accept="video/*"
                                    multiple
                                    onChange={handleVideoChange}
                                // style={{ padding: 10 }}
                                />
                            </Form.Group>

                            <div className="container my-2">
                                <Row className="g-3">
                                    {selectedVideoList?.map((url, index) => (
                                        <Col
                                            key={index}
                                            xs={12} sm={6} md={6} lg={4}
                                            className="video-container "
                                            style={{ position: "relative" }}
                                        >
                                            <video
                                                src={url}
                                                controls
                                                style={{
                                                    borderRadius: "5px",
                                                    background: '#0000000f',
                                                    width: "100%",
                                                    height: "auto"
                                                }}
                                            />
                                            <button
                                            type="button"
                                                className="btn btn-danger"
                                                style={{
                                                    width: "30px",
                                                    height: "30px",
                                                    position: "absolute",
                                                    top: "8px",
                                                    right: "12px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: 0,
                                                    borderRadius: "50%",
                                                    fontSize: "12px",
                                                }}
                                                onClick={() => handleRemoveVideo(index)}
                                            >
                                                <IoMdClose size={20} />
                                            </button>
                                        </Col>
                                    ))}
                                </Row>

                            </div>

                        </Form>
                    </div>

                    <Row className="py-3">
                        <Col className="text-end">
                            <Button size='lg' variant="primary" onClick={handleShow} disabled={isLoading || isEmpty}>
                                {isLoading ? <Spinner size="sm" animation="border" /> : t("Envoyer la commande")}
                            </Button>
                        </Col>
                    </Row>

                </CardBody>
            </Card>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Confirmation")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("Êtes-vous sûr de vouloir envoyer cette commande?")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Annuler")}
                    </Button>
                    <Button variant="primary" onClick={(e) => handleConfirmSubmit(e)} disabled={isLoading}>
                        {isLoading ? <Spinner size="sm" animation="border" /> : t("Confirmer")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

export default Add_demande;
