const DataCountries = [
    {value: "AF", title: "Afghanistan"},
    {value: "AL", title: "Albania"},
    {value: "DZ", title: "Algeria"},
    {value: "AD", title: "Andorra"},
    {value: "AO", title: "Angola"},
    {value: "AG", title: "Antigua and Barbuda"},
    {value: "AR", title: "Argentina"},
    {value: "AM", title: "Armenia"},
    {value: "AU", title: "Australia"},
    {value: "AT", title: "Austria"},
    {value: "AZ", title: "Azerbaijan"},
    {value: "BS", title: "Bahamas"},
    {value: "BH", title: "Bahrain"},
    {value: "BD", title: "Bangladesh"},
    {value: "BB", title: "Barbados"},
    {value: "BY", title: "Belarus"},
    {value: "BE", title: "Belgium"},
    {value: "BZ", title: "Belize"},
    {value: "BJ", title: "Benin"},
    {value: "BT", title: "Bhutan"},
    {value: "BO", title: "Bolivia"},
    {value: "BA", title: "Bosnia and Herzegovina"},
    {value: "BW", title: "Botswana"},
    {value: "BR", title: "Brazil"},
    {value: "BN", title: "Brunei"},
    {value: "BG", title: "Bulgaria"},
    {value: "BF", title: "Burkina Faso"},
    {value: "BI", title: "Burundi"},
    {value: "CV", title: "Cape Verde"},
    {value: "KH", title: "Cambodia"},
    {value: "CM", title: "Cameroon"},
    {value: "CA", title: "Canada"},
    {value: "CF", title: "Central African Republic"},
    {value: "TD", title: "Chad"},
    {value: "CL", title: "Chile"},
    {value: "CN", title: "China"},
    {value: "CO", title: "Colombia"},
    {value: "KM", title: "Comoros"},
    {value: "CG", title: "Congo-Brazzaville"},
    {value: "CD", title: "Congo-Kinshasa"},
    {value: "CR", title: "Costa Rica"},
    {value: "CI", title: "Ivory Coast"},
    {value: "HR", title: "Croatia"},
    {value: "CU", title: "Cuba"},
    {value: "CY", title: "Cyprus"},
    {value: "CZ", title: "Czech Republic"},
    {value: "DK", title: "Denmark"},
    {value: "DJ", title: "Djibouti"},
    {value: "DM", title: "Dominica"},
    {value: "DO", title: "Dominican Republic"},
    {value: "EC", title: "Ecuador"},
    {value: "EG", title: "Egypt"},
    {value: "SV", title: "El Salvador"},
    {value: "GQ", title: "Equatorial Guinea"},
    {value: "ER", title: "Eritrea"},
    {value: "EE", title: "Estonia"},
    {value: "SZ", title: "Eswatini"},
    {value: "ET", title: "Ethiopia"},
    {value: "FJ", title: "Fiji"},
    {value: "FI", title: "Finland"},
    {value: "FR", title: "France"},
    {value: "GA", title: "Gabon"},
    {value: "GM", title: "Gambia"},
    {value: "GE", title: "Georgia"},
    {value: "DE", title: "Germany"},
    {value: "GH", title: "Ghana"},
    {value: "GR", title: "Greece"},
    {value: "GD", title: "Grenada"},
    {value: "GT", title: "Guatemala"},
    {value: "GN", title: "Guinea"},
    {value: "GW", title: "Guinea-Bissau"},
    {value: "GY", title: "Guyana"},
    {value: "HT", title: "Haiti"},
    {value: "HN", title: "Honduras"},
    {value: "HU", title: "Hungary"},
    {value: "IS", title: "Iceland"},
    {value: "IN", title: "India"},
    {value: "ID", title: "Indonesia"},
    {value: "IR", title: "Iran"},
    {value: "IQ", title: "Iraq"},
    {value: "IE", title: "Ireland"},
    {value: "IL", title: "Israel"},
    {value: "IT", title: "Italy"},
    {value: "JM", title: "Jamaica"},
    {value: "JP", title: "Japan"},
    {value: "JO", title: "Jordan"},
    {value: "KZ", title: "Kazakhstan"},
    {value: "KE", title: "Kenya"},
    {value: "KI", title: "Kiribati"},
    {value: "KP", title: "North Korea"},
    {value: "KR", title: "South Korea"},
    {value: "KW", title: "Kuwait"},
    {value: "KG", title: "Kyrgyzstan"},
    {value: "LA", title: "Laos"},
    {value: "LV", title: "Latvia"},
    {value: "LB", title: "Lebanon"},
    {value: "LS", title: "Lesotho"},
    {value: "LR", title: "Liberia"},
    {value: "LY", title: "Libya"},
    {value: "LI", title: "Liechtenstein"},
    {value: "LT", title: "Lithuania"},
    {value: "LU", title: "Luxembourg"},
    {value: "MG", title: "Madagascar"},
    {value: "MW", title: "Malawi"},
    {value: "MY", title: "Malaysia"},
    {value: "MV", title: "Maldives"},
    {value: "ML", title: "Mali"},
    {value: "MT", title: "Malta"},
    {value: "MH", title: "Marshall Islands"},
    {value: "MR", title: "Mauritania"},
    {value: "MU", title: "Mauritius"},
    {value: "MX", title: "Mexico"},
    {value: "FM", title: "Micronesia"},
    {value: "MD", title: "Moldova"},
    {value: "MC", title: "Monaco"},
    {value: "MN", title: "Mongolia"},
    {value: "ME", title: "Montenegro"},
    {value: "MA", title: "Morocco"},
    {value: "MZ", title: "Mozambique"},
    {value: "MM", title: "Myanmar (Burma)"},
    {value: "NA", title: "Namibia"},
    {value: "NR", title: "Nauru"},
    {value: "NP", title: "Nepal"},
    {value: "NL", title: "Netherlands"},
    {value: "NZ", title: "New Zealand"},
    {value: "NI", title: "Nicaragua"},
    {value: "NE", title: "Niger"},
    {value: "NG", title: "Nigeria"},
    {value: "MK", title: "North Macedonia"},
    {value: "NO", title: "Norway"},
    {value: "OM", title: "Oman"},
    {value: "PK", title: "Pakistan"},
    {value: "PW", title: "Palau"},
    {value: "PA", title: "Panama"},
    {value: "PG", title: "Papua New Guinea"},
    {value: "PY", title: "Paraguay"},
    {value: "PE", title: "Peru"},
    {value: "PH", title: "Philippines"},
    {value: "PL", title: "Poland"},
    {value: "PT", title: "Portugal"},
    {value: "QA", title: "Qatar"},
    {value: "RO", title: "Romania"},
    {value: "RU", title: "Russia"},
    {value: "RW", title: "Rwanda"},
    {value: "KN", title: "Saint Kitts and Nevis"},
    {value: "LC", title: "Saint Lucia"},
    {value: "VC", title: "Saint Vincent and the Grenadines"},
    {value: "WS", title: "Samoa"},
    {value: "SM", title: "San Marino"},
    {value: "ST", title: "Sao Tome and Principe"},
    {value: "SA", title: "Saudi Arabia"},
    {value: "SN", title: "Senegal"},
    {value: "RS", title: "Serbia"},
    {value: "SC", title: "Seychelles"},
    {value: "SL", title: "Sierra Leone"},
    {value: "SG", title: "Singapore"},
    {value: "SK", title: "Slovakia"},
    {value: "SI", title: "Slovenia"},
    {value: "SB", title: "Solomon Islands"},
    {value: "SO", title: "Somalia"},
    {value: "ZA", title: "South Africa"},
    {value: "SS", title: "South Sudan"},
    {value: "ES", title: "Spain"},
    {value: "LK", title: "Sri Lanka"},
    {value: "SD", title: "Sudan"},
    {value: "SR", title: "Suriname"},
    {value: "SE", title: "Sweden"},
    {value: "CH", title: "Switzerland"},
    {value: "SY", title: "Syria"},
    {value: "TW", title: "Taiwan"},
    {value: "TJ", title: "Tajikistan"},
    {value: "TZ", title: "Tanzania"},
    {value: "TH", title: "Thailand"},
    {value: "TL", title: "Timor-Leste"},
    {value: "TG", title: "Togo"},
    {value: "TO", title: "Tonga"},
    {value: "TT", title: "Trinidad and Tobago"},
    {value: "TN", title: "Tunisia"},
    {value: "TR", title: "Turkey"},
    {value: "TM", title: "Turkmenistan"},
    {value: "TV", title: "Tuvalu"},
    {value: "UG", title: "Uganda"},
    {value: "UA", title: "Ukraine"},
    {value: "AE", title: "United Arab Emirates"},
    {value: "GB", title: "United Kingdom"},
    {value: "US", title: "United States"},
    {value: "UY", title: "Uruguay"},
    {value: "UZ", title: "Uzbekistan"},
    {value: "VU", title: "Vanuatu"},
    {value: "VA", title: "Vatican City"},
    {value: "VE", title: "Venezuela"},
    {value: "VN", title: "Vietnam"},
    {value: "YE", title: "Yemen"},
    {value: "ZM", title: "Zambia"},
    {value: "ZW", title: "Zimbabwe"}
];


export default DataCountries;