import {
    FETCH_DEVIS_REQUEST,
    FETCH_DEVIS_SUCCESS,
    FETCH_DEVIS_FAILURE,
    ADD_DEVIS_REQUEST,
    ADD_DEVIS_SUCCESS,
    ADD_DEVIS_FAILURE,
    UPDATE_DEVIS_REQUEST,
    UPDATE_DEVIS_SUCCESS,
    UPDATE_DEVIS_FAILURE,
    DELETE_DEVIS_REQUEST,
    DELETE_DEVIS_SUCCESS,
    DELETE_DEVIS_FAILURE
} from '../actions/DevisActions';

const initialState = {
    devis: [],
    loading: false,
    error: null
};

export const devisReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DEVIS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_DEVIS_SUCCESS:
            return { ...state, loading: false, devis: action.payload };
        case FETCH_DEVIS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ADD_DEVIS_REQUEST:
            return { ...state, loading: true, error: null };
        case ADD_DEVIS_SUCCESS:
            return { ...state, loading: false };
        case ADD_DEVIS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case UPDATE_DEVIS_REQUEST:
            return { ...state, loading: true, error: null };
        case UPDATE_DEVIS_SUCCESS:
            return { ...state, loading: false };
        case UPDATE_DEVIS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case DELETE_DEVIS_REQUEST:
            return { ...state, loading: true, error: null };
        case DELETE_DEVIS_SUCCESS:
            return { ...state, loading: false };
        case DELETE_DEVIS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};
