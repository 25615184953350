import React, { useEffect, useState } from 'react';
import { Client } from '@adzerk/decision-sdk';
import { Button } from 'react-bootstrap';

const AdComponent = () => {
    const [adContent, setAdContent] = useState(null);
    const [adContentVideo, setAdContentVideo] = useState(null);
    const [isVisible, setIsVisible] = useState(true);
    const [ad, setAd] = useState();

    useEffect(() => {

        const fetchAdDecision = async () => {
            try {
                // Initialize the Adzerk client with your network and site IDs
                const client = new Client({ networkId: 11596, siteId: 1285093 });

                // Create the request object
                const request = {
                    placements: [{ adTypes: [11] }],
                    keywords: ["video"]
                };

                // Get the decision response
                const response = await client.decisions.get(request);
                console.log("response..........: ", response);
                setAd(response.decisions.div0[0])
                if (response.decisions && response.decisions[Object.keys(response.decisions)[0]]) {
                    const ad = response.decisions.div0[0].contents[0];
                    const impression = response.decisions.div0[0].impressionUrl
                    setAdContent(ad);
                    setAdContentVideo(ad.data);
                    console.log("videoo",ad);
                    

                    {
                        console.log("addddss", ad.data)
                    }
                    console.log("impression in useEffect", impression);

                    // Fire the impression pixel when the ad is viewed
                    if (impression) {
                        client.pixels.fire({ url: impression });
                    }
                }
            } catch (error) {
                console.error('Error fetching ad decision:', error);
            }
        };

        fetchAdDecision();
    }, []);

    const handleAdClick = () => {
        console.log(ad);
        if (ad && ad.clickUrl) {
            // Fire the click pixel and open the ad URL in a new tab
            const client = new Client({ networkId: 11596, siteId: 1285093 });
            console.log("clickUrl", ad?.clickUrl);

            client.pixels.fire({ url: ad?.clickUrl }).then(r => {
                console.log(r);
                console.log(`status ${r.status}; location: ${r.location}`);
                window.open(ad.clickUrl, '_blank');
            });
        }
    };

    const handleManualImpression = () => {
        if (ad && ad.impressionUrl) {

            const client = new Client({ networkId: 11596, siteId: 1285093 });
            console.log("impressionUrl", ad?.impressionUrl);
            // Fire the impression pixel manually
            client.pixels.fire({ url: ad?.impressionUrl }).then((r) => {
                console.log(`Impression fired, status: ${r.status}`);
            });
        }
    };

    const handleCloseAd = () => {
        setIsVisible(false);
    };

    if (!isVisible || !adContent) return null;

    return (
        <div style={{ position: 'relative', border: '1px solid #ccc', padding: '10px', width: '300px', width: 'fit-content', margin: '10px auto', textAlign: 'center' }}>
            <button onClick={handleCloseAd} style={{ position: 'absolute', top: '0px', right: '0px', background: 'transparent', border: 'none', cursor: 'pointer' }}>
                ✖
            </button>

            {/* Ad content */}
            <div style={{ padding: 5 }} dangerouslySetInnerHTML={{ __html: adContent.body }} onClick={handleAdClick} />
            <img width={"250px"} height={"200px"} src="https://image.video-assets.kevel.com/021pazXOmmXE9YP8Ia2d7Y9shWtKWG3S02o75ZSafGh4M/thumbnail.png"></img>
            {/* <video src={adContentVideo.ctPlayback_URL} controls style={{ background: '#0000000f', width: "100%", height: "auto" }} /> */}
            {adContentVideo.id}
            {/* Click and Impression Buttons */}
            <div style={{ marginTop: '10px' }}>
                <Button onClick={handleAdClick} style={{ marginRight: '10px' }}>
                    Click
                </Button>
                <Button onClick={() => handleManualImpression()}>
                    Impression
                </Button>

            </div>
        </div>
    );
};

export default AdComponent;
