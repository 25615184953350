import PropTypes from 'prop-types'
import React, { useState } from "react"

import { connect } from "react-redux"
import { Row, Col } from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import { FaRegUser } from "react-icons/fa";
import { LuDollarSign } from "react-icons/lu";

// Reactstrap

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"

import logoLightPng from "../../../assets/images/logoradio.png"

//i18n

// Redux Store
import {
  toggleLeftmenu,

} from "../../store/actions"
import { useStateContext } from '../../../context/ContextProvider'
import { Avatar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import { Logout, PersonAdd, Settings } from '@mui/icons-material'
import { link } from '../../../axios-client'
import { useTranslation } from 'react-i18next'

const Header = props => {
  const {t} =useTranslation()
  const { user, setToken, setUser, setRefresh, token } = useStateContext();
  const navigate = useNavigate()
  const onLogout = (ev) => {
    ev.preventDefault();
    setUser({});
    setToken(null);
    localStorage.removeItem("role");
    navigate("/login")
  };

  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>

      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box" style={{ backgroundColor: "#232939" }} >

              <Link to="/" className="logo logo-light">
                <span className="logo-lg " style={{ marginRight: "160px" }}>
                  <img src={logoLightPng} alt="" height="19" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-24 header-item waves-effect vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="mdi mdi-menu"></i>
            </button>
            <LanguageDropdown />

            <div className="d-none d-sm-block">


            </div>
          </div>
          <div className="d-flex align-items-center">


            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

              <div className='d-flex flex-column justify-content-end align-items-end'>
                <Typography className='d-flex flex-column justify-content-end align-items-end' fontSize={15} fontWeight={"semi-bold"} sx={{ minWidth: 100 }}>{user.first_name} {user.last_name}</Typography>
                <Typography color={"#8699ad"} fontSize={13} sx={{ minWidth: 100 }}>{user.email}</Typography>
              </div>

              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >

                <Avatar sx={{ width: 45, height: 45 }} src={link + user.image}></Avatar>
              </IconButton>
            </Box>

            <Menu

              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem style={{ minWidth: 200 }} onClick={() => navigate("/profile")}>
                <Avatar src={link + user.image} /> {t("Paramètres")}
              </MenuItem>
              <MenuItem style={{ minWidth: 200 }} onClick={() => navigate("/payment")}>
                <ListItemIcon>
                  <LuDollarSign  fontSize="20px" />
                </ListItemIcon>
                {t("Paiements")}
              </MenuItem>

              <Divider />


              <MenuItem onClick={onLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                {t("Se déconnecter")}
              </MenuItem>
            </Menu>
            {/* <div
              onClick={onLogout}
              className="dropdown d-inline-block"
            >
             
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect"
              >
                <i style={{ color: "blue" }} className="mdi mdi-logout"></i>
              </button>
            </div> */}
          </div>
        </div>
      </header>

    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,

  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    layoutType,
    leftMenu,
    leftSideBarType,
  } = state.Layout
  return { layoutType, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  toggleLeftmenu,
})((Header))