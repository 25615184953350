import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Table, Spinner } from 'react-bootstrap';
import './custom.css';
import { Avatar, AvatarGroup, colors, Pagination, Stack } from '@mui/material';
import { MdCampaign, MdOutlineAdsClick, MdOutlineRadio, MdPublishedWithChanges } from 'react-icons/md';
import Slider from 'react-infinite-logo-slider'
import axiosClient, { link, pagination_size } from '../../../../axios-client';
import { FaSackDollar, FaUserTie } from "react-icons/fa6";
import { GiMusicalNotes } from "react-icons/gi";
import { FaAd, FaRegChartBar } from "react-icons/fa";
import ApexCharts from 'apexcharts';
import { GrAnnounce } from "react-icons/gr";

import CryptoJS from 'crypto-js';
import { useStateContext } from '../../../../context/ContextProvider';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const CardComponent = ({ bgGradientClass, amount, description, svgPath, loading }) => (
    <Col xs={12} sm={6} md={6} lg={4} xl={3}>
        <div className={`relative p-3 rounded-xl overflow-hidden ${bgGradientClass}`} style={{ height: '100px', borderRadius: "10px" }}>
            <div className="relative z-10 mb-1 text-white " style={{ fontWeight: "bold", fontSize: 25 }}>{loading ? <Spinner size='md' style={{ color: '#ffffff66' }} animation="border" /> : amount}</div>
            <div className="relative z-10 text-white" style={{ fontWeight: "semi-bold", fontSize: 16 }}>{description}</div>
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ position: "absolute", right: 0, bottom: 0, height: 140, width: 140, marginRight: -30, marginBottom: -30, opacity: 25 }} className="-mr-8 -mb-8 text-white opacity-25 z-0">
                {svgPath}
            </svg>
        </div>

    </Col>
);

function StatsAdvertiser() {
    const [count, setCount] = useState()
    const paginationSize = 3
    const [page, setPage] = useState(1);
    const [page2, setPage2] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalPages2, setTotalPages2] = useState(1);
    const { user } = useStateContext()
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation()
    const location = useLocation()
    const [advertiserStats, setAdvertiserStats] = useState({})

    const [adsCount, setAdsCount] = useState()

    useEffect(() => {

        axiosClient.get(`agences/${location.state.idAdvertiser}/`)
            .then((res) => {
                axiosClient.get(`advertiser-stats/${res.data.Advertiser}/`)
                    .then((response) => {
                        setAdvertiserStats(response.data);
                        setLoading(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                axiosClient.get(`advertiser-stats-by-day/${res.data.Advertiser}/`)
                    .then((response) => {
                        const data = response.data;

                        // Set the chart options with real data from the response
                        const options = {
                            series: [
                                {
                                    name: "Impression",
                                    data: data.impressions // Use the real impressions data
                                },
                                {
                                    name: t("Clics"),
                                    data: data.clicks // Use the real clicks data
                                }
                            ],
                            chart: {
                                height: 350,
                                type: 'line',
                                zoom: { enabled: false }
                            },
                            colors: ['#7b67ff', '#f5c461'],
                            dataLabels: { enabled: false },
                            stroke: {
                                width: [3, 5],
                                curve: 'straight',
                                dashArray: [0, 8]
                            },
                            title: {
                                text: t('Impressions vs Clics'),
                                align: 'left'
                            },
                            legend: {
                                tooltipHoverFormatter: function (val, opts) {
                                    return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>';
                                }
                            },
                            markers: { size: 0, hover: { sizeOffset: 6 } },
                            xaxis: {
                                categories: data.dates // Use the real dates for the x-axis
                            },
                            yaxis: [
                                {
                                    title: { text: "Impressions" },
                                    labels: {
                                        formatter: function (value) {
                                            return value.toFixed(0);
                                        }
                                    }
                                },
                                {
                                    opposite: true, // This places the second y-axis on the right
                                    title: { text: `${t("Clics")}` },
                                    labels: {
                                        formatter: function (value) {
                                            return value.toFixed(0);
                                        }
                                    }
                                }
                            ],
                            tooltip: {
                                y: [
                                    { title: { formatter: (val) => val } },
                                    { title: { formatter: (val) => val } }
                                ]
                            },
                            grid: { borderColor: '#f1f1f1', padding: { left: 30, right: 30 } }
                        };

                        const chart = new ApexCharts(document.querySelector("#chart"), options);
                        chart.render();

                    })
                    .catch((error) => {
                        console.log(error);
                    });

            })
            .catch((error) => {
                console.log(error);
            });

    }, [user]);


    const handlePageChange = (event, value) => {
        setPage(value);
    };
    const handlePageChange2 = (event, value) => {
        setPage2(value);
    };

    const startIndex = (page - 1) * paginationSize;
    const endIndex = startIndex + paginationSize;

    const startIndex2 = (page2 - 1) * paginationSize;
    const endIndex2 = startIndex2 + paginationSize;

    return (
        <div>
            {location.state.name && 
                <div className="px-3 my-3 mb-4">
                    <h3>{t("Statistiques de")} {location.state.name}</h3>

                </div>
            }
           
            <div className="py-3 d-flex align-items-center justify-content-center">
                <div className='px-3' style={{ width: "100%" }}>
                    <Row className="g-3">
                        <CardComponent
                            bgGradientClass="bg-gradient-teal-green"

                            amount={advertiserStats?.impressions}
                            description={t("impressions totales")}
                            svgPath={
                                <GrAnnounce size={22} />
                            }
                            loading={loading}
                        />
                        <CardComponent
                            bgGradientClass="bg-gradient-blue"
                            amount={advertiserStats?.clicks}
                            description={t("nombre total de clics")}
                            svgPath={
                                <MdOutlineAdsClick size={22} />
                            }
                            loading={loading}
                        />
                        <CardComponent
                            bgGradientClass="bg-gradient-red"
                            amount={`${advertiserStats?.revenue || 0} $`}
                            description={t("Revenu")}
                            svgPath={
                                <FaSackDollar size={22} />
                            }
                            loading={loading}
                        />
                        <CardComponent
                            bgGradientClass="bg-gradient-yellow"
                            amount={`${(advertiserStats.gmv / advertiserStats.revenue) || 0} %`}
                            description="ROAS"
                            svgPath={
                                <FaRegChartBar size={22} />
                            }
                            loading={loading}
                        />

                    </Row>
                </div>


            </div>
            <div style={{ borderRadius: 10 }} className='p-3 mx-3 bg-white'>
                <div id="chart"></div>
            </div>



        </div>


    )
}

export default StatsAdvertiser