import React, { useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { idTemplate, link } from '../../../../axios-client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert, Table } from 'reactstrap';
import DataCountries from './DataCountries';
import DataTimeZone from './DataTimeZone';
import moment from 'moment-timezone';
import { TablePagination } from '@mui/material';
import { useStateContext } from '../../../../context/ContextProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';


function AddFlight() {
    const { user } = useStateContext()
    const { id } = useParams();
    const location = useLocation()
    const navigate = useNavigate();
    const [addAdModal, setAddAdModal] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseAddAdModal = () => {
        setAddAdModal(false)
        setCreativeMapsFormData({
            IsActive: true,
            Body: '',
        })
        setTemplateSelected(null)
        setFormat('Image')
        setErrorsAd({})
        setLinkAsset()
    };
    const handleShowAddAdModal = () => setAddAdModal(true);

    const [updateAddModal, setUpdateAddModal] = useState(false);

    const handleCloseUpdateAddModal = () => {
        setUpdateAddModal(false)
        setCreativeMapsFormData({
            IsActive: true,
            Body: '',
        })
        setKeyAdSelected()
        setErrorsAd({})
        setTemplateSelected(null)
        setFormat('Image')
        setLinkAsset()
    };
    const handleShowUpdateAddModal = () => {
        setUpdateAddModal(true)
    };

    const [deleteAdModal, setDeleteAdModal] = useState(false)

    const handleCloseDeleteAdModal = () => {
        setDeleteAdModal(false)
        setKeyAdSelected()
    };
    const handleShowDeleteAdModal = () => {
        setDeleteAdModal(true)
    };

    const [keyAdSelected, setKeyAdSelected] = useState()
    const [idAdSelected, setIdAdSelected] = useState()

    const [loading, setLoading] = useState()
    const [loadingAds, setLoadingAds] = useState()

    // Variables
    const [flightFormData, setFlightFormData] = useState({
        CampaignId: location.state.idCampaign,
        // PriorityId:'',
        RateType: 2,
        StartDate: new Date().toISOString().slice(0, 16),
        EndDate: null,
        EndDateISO: null,
        IsTrackingConversions: false,
        GoalType: 2,
        CapType: null,
        Price: null
    })

    const [dataPriorities, setDataPriorities] = useState([])

    const [errors, setErrors] = useState({})
    const refAttributs = useRef({
        NameRef: null,
        PriorityIdRef: null,
        ImpressionsRef: null,
        EndDateRef: null,
        DailyCapAmountRef: null,
        LifetimeCapAmountRef: null,
        SiteZoneTargetingRef: null,
        GeoTargetingRef: null,
    })

    const Id = id ? id : 0


    const [siteTargetingFormData, setSiteTargetingFormData] = useState({
        IsExclude: false,
    })
    const [dataSiteZoneTargeting, setDataSiteZoneTargeting] = useState([])

    const [geoTargetingFormData, setGeoTargetingFormData] = useState({
        IsExclude: false,
    })
    const [dataGeoTargeting, setDataGeoTargeting] = useState([])

    const [format, setFormat] = useState("Image")

    const [dataTemplates, setDataTemplates] = useState([])
    const [templateSelected, setTemplateSelected] = useState(null)
    useEffect(() => {
        if (format === 'Video') {
            try {
                axios.get(`${link}/get-templates/`).then(res => {
                    console.log('TEMPLATES : ', res.data);
                    setDataTemplates(res.data.items)
                })
            } catch (error) {
                console.log('ERROR : ', error);

            }
        }
    }, [format])

    //-------------------------------------------------------------------------------------------------------------------
    const [assetFormData, setAssetFormData] = useState()
    const [linkAsset, setLinkAsset] = useState()
    const [uploadVideoLoading, setuploadVideoLoading] = useState()
    const addAsset = async () => {
        try {
            setuploadVideoLoading(true)
            let dataImagesAdded;
            const formData = new FormData()
            formData.append('video', assetFormData.video);
            formData.append('Id', 0);
            try {
                const response = await axios.post(`${link}/upload-video/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                dataImagesAdded = response.data;
                console.log(response.data);
                setLinkAsset(response.data.video_url)

                setCreativeMapsFormData({
                    ...creativeMapsFormData,
                    TemplateValues: {
                        ...creativeMapsFormData?.TemplateValues,
                        // 'ctPlayback_URL' : linkAsset,
                        ctPlayback_URL: response.data.video_url
                    },
                });
                setuploadVideoLoading(false)

            } catch (error) {
                setuploadVideoLoading(false)
                console.error('Error uploading image:', error.response ? error.response.data : error.message);
                throw error; // Rethrow error to stop execution and handle it in the calling function
            }
            // const data = {
            //     dataAsset: assetFormData.videoName,
            //     dataFile: dataImagesAdded
            // }
            // await axios.post(`${link}/create-asset/${user.Advertiser}/`, data).then(() => {
            //     console.log('res');

            // })
        } catch (error) {
            console.log('ERROR : ', error);

        }
    }
    //-------------------------------------------------------------------------------------------------------------------

    const [creativeMapsFormData, setCreativeMapsFormData] = useState({
        IsActive: true,
        Body: '',
    })
    const [dataCreativeMaps, setDataCreativeMaps] = useState([])
    const [dataFiles, setDataFiles] = useState([])


    useEffect(() => {
        console.log('Updated FormData:', flightFormData);
        console.log('Updated FormData: siteTargetingFormData ', siteTargetingFormData);
        console.log('Updated FormData: dataSiteZoneTargeting ', dataSiteZoneTargeting);
        console.log('Updated FormData: dataGeoTargeting ', dataGeoTargeting);
        console.log('Updated FormData: creativeMapsFormData ', creativeMapsFormData);
        console.log('Updated FormData: dataCreativeMaps ', dataCreativeMaps);
        console.log('Updated FormData: dataFiles ', dataFiles);
    }, [flightFormData, siteTargetingFormData, dataSiteZoneTargeting, creativeMapsFormData, dataCreativeMaps]); // This will log the updated state whenever it changes

    ////////////////////////////////the size of the table//////////////////////////////
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);

    const handlePageChange = (event, value) => {
        setPage(value);
    };
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    const getDataFlight = async () => {
        setLoading(true)
        if (parseInt(Id) !== 0) {
            try {
                await axios.get(`${link}/get-flight-with-id/${Id}/`).then(res => {
                    setFlightFormData(res.data)
                })
            } catch (error) {
                setLoading(false)
            }
        }
    }

    const getDataAds = async () => {
        setLoadingAds(true)
        try {
            await axios.get(`${link}/get-Ads/${Id}/?page=${page + 1}&page_size=${pageSize}`).then(res => {
                console.log('get-Ads : ', res.data.results);
                setDataCreativeMaps(res.data.results || [])
                setTotalPages(Math.ceil(res.data.count / pageSize));
                setLoadingAds(false)
            })
        } catch (error) {
            console.log('ERROR : ', error);
            setLoadingAds(false)
        }
    }

    const getDataSiteZoneFlight = async () => {
        // setLoading(true)
        if (parseInt(Id) !== 0) {
            try {
                await axios.get(`${link}/get-site-flight/${Id}/`).then(res => {
                    setDataSiteZoneTargeting(res.data || [])
                })
            } catch (error) {
                setLoading(false)
            }
        }
    }

    const getDataGeoTargetingFlight = async () => {
        // setLoading(true)
        if (parseInt(Id) !== 0) {
            try {
                await axios.get(`${link}/get-geoTargeting-flight/${Id}/`).then(res => {
                    setDataGeoTargeting(res.data || [])
                })
            } catch (error) {
                setLoading(false)
            }
        }
    }

    const getPriorities = async () => {
        try {
            await axios.get(`${link}/get-priorities/`).then(res => {
                console.log('DATA PRIORITIES : ', res.data.items);
                setDataPriorities(res.data.items)
            })
        } catch (error) {
            setLoading(false)

        }
    }
    useEffect(() => {
        if (parseInt(Id) !== 0) {
            getDataFlight()
            getDataAds()
            getDataSiteZoneFlight()
            getDataGeoTargetingFlight()
        }
        getPriorities()
        setLoading(false)
    }, [page, pageSize])

    //Change Date By TimeZone
    const InvalideDate = 'Invalid date'
    const handleTimeZoneChange = (e) => {
        const selectedTimeZone = e.target.value;

        const startDate = moment.tz(flightFormData.StartDateISO, flightFormData.TimeZone || moment.tz.guess())
            .tz(selectedTimeZone)
            .format('YYYY-MM-DDTHH:mm:ss');

        const endDate = moment.tz(flightFormData.EndDateISO, flightFormData.TimeZone || moment.tz.guess())
            .tz(selectedTimeZone)
            .format('YYYY-MM-DDTHH:mm:ss');

        console.log('Start Date : ', startDate);
        console.log('Date END : ', endDate !== InvalideDate ? endDate : 'null');


        setFlightFormData({
            ...flightFormData,
            'TimeZone': selectedTimeZone,
            'StartDate': startDate,
            'StartDateISO': startDate,
            'EndDate': endDate !== InvalideDate ? endDate : null,
            'EndDateISO': endDate !== InvalideDate ? endDate : null
        });
    };

    const [loadingAdd, setLoadingAdd] = useState(false)

    const [confirmFlight, setConfirmFlight] = useState(false)

    const handleCloseConfirmFlight = () => {
        setConfirmFlight(false)
    };
    const handleShowConfirmFlight = () => setConfirmFlight(true);

    const AddEditFlight = async () => {
        setErrors({})
        console.log('DATA GEEEEEEEEEEEO : ', dataGeoTargeting);


        let newErrors = { ...errors };
        let firstErrorRef = null;

        if (!flightFormData?.Name || flightFormData?.Name === '') {
            newErrors.Name = 'This field is required'
            console.log('USE REF NAME ! ', refAttributs.current.NameRef);
            if (!firstErrorRef) firstErrorRef = refAttributs.current.NameRef;
        } else {
            const { Name, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (!flightFormData?.PriorityId || flightFormData?.PriorityId === '') {
            newErrors.PriorityId = 'This field is required'
            console.log('USE REF NAME ! ', refAttributs.current.PriorityIdRef);
            if (!firstErrorRef) firstErrorRef = refAttributs.current.PriorityIdRef;
        } else {
            const { PriorityId, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (!flightFormData?.Impressions || flightFormData?.Impressions === '') {
            newErrors.Impressions = 'This field is required'
            console.log('USE REF IMPRESSIONS ! ', refAttributs.current.ImpressionsRef);
            if (!firstErrorRef) firstErrorRef = refAttributs.current.ImpressionsRef;
        } else {
            const { Impressions, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if ([1, 3, 7, 8].includes(flightFormData?.GoalType) && !flightFormData?.EndDate) {
            newErrors.EndDate = 'This field is required'
            console.log('USE REF IMPRESSIONS ! ', refAttributs.current.EndDateRef);
            if (!firstErrorRef) firstErrorRef = refAttributs.current.EndDateRef;

        } else {
            const { EndDate, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if ([1, 2, 3, 4].includes(flightFormData?.CapType) && !flightFormData?.DailyCapAmount) {
            newErrors.DailyCapAmount = 'This field is required'
            console.log('USE REF NAME ! ', refAttributs.current.DailyCapAmountRef);
            if (!firstErrorRef) firstErrorRef = refAttributs.current.DailyCapAmountRef;
        } else {
            const { DailyCapAmount, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if ([1, 2, 3, 4].includes(flightFormData?.CapType) && !flightFormData?.LifetimeCapAmount) {
            newErrors.LifetimeCapAmount = 'This field is required'
            console.log('USE REF NAME ! ', refAttributs.current.LifetimeCapAmountRef);
            if (!firstErrorRef) firstErrorRef = refAttributs.current.LifetimeCapAmountRef;
        } else {
            const { LifetimeCapAmount, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        setErrors(newErrors);
        console.log(newErrors);

        // Scroll to the first error if any
        if (firstErrorRef) {
            console.log('Scrolling/focusing on:', firstErrorRef);

            handleCloseConfirmFlight()
            setTimeout(() => {
                firstErrorRef.focus(); // For focusing
            }, 500)

            // Uncomment one of these depending on your requirement
            // firstErrorRef.scrollIntoView({ behavior: 'smooth', block: 'center' }); // For scrolling


            // Exit the function if there's an error
        }
        console.log('Scrolling/focusing on:', firstErrorRef);
        if (!firstErrorRef) {
            // Créer une nouvelle liste qui contient des objets {index, fileName}
            let dataImagesAdded = [];

            const newList = dataCreativeMaps.map(async (item, index) => {
                const formData = new FormData();
                const FieldName = item.Creative?.FieldName || ''; // Extraire FieldName s'il existe, sinon utiliser une chaîne vide
                const Id = item?.Creative?.Id || index; // Extraire Id s'il existe, sinon utiliser l'index

                if (FieldName) {
                    formData.append('file', FieldName);
                    formData.append('Id', Id);
                    try {
                        const response = await axios.post(`${link}/upload-image/`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        });
                        console.log(response.data);
                        dataImagesAdded.push(response.data); // Ajouter la réponse au tableau dataImagesAdded
                        delete item.Creative.FieldName; // Supprimer FieldName s'il existe
                    } catch (error) {
                        console.error('Error uploading image:', error.response ? error.response.data : error.message);
                        throw error; // Rethrow error to stop execution and handle it in the calling function
                    }
                }
                return { index, FieldName }; // Retourner l'objet {index, FieldName}
            });

            try {

                setLoadingAdd(true)
                // Attendre que toutes les promesses soient résolues
                const results = await Promise.all(newList);
                console.log('Nouvelle liste :', results); // Nouvelle liste après toutes les résolutions
                console.log('Liste initiale modifiée :', dataCreativeMaps);

                // Préparer les données pour la requête POST finale
                const data = {
                    flightFormData: flightFormData,
                    dataCreativeMaps: dataCreativeMaps,
                    IdsCreativeUpdated: IdsCreativeUpdated,
                    dataFiles: dataImagesAdded,
                };
                console.log('FIIIIIIIIIIIILE : ', data.dataCreativeMaps);

                // Envoyer la requête POST finale
                await axios.post(`${link}/add-edit-flight/${Id}/`, data).then((response) => {
                    console.log('RESPONSE DATA : after Post : ', response.data);
                    console.log('DATATA DATATA DATAta : ', flightFormData);
                    if (parseInt(Id) == 0) {
                        toast.success(("The flight is added"), {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        toast.success(("The flight is updated"), {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        });
                    }

                    if (response.data) {
                        setLoadingAdd(false)
                        navigate(-1)
                    }
                    return response.data;
                })
            } catch (error) {
                setErrors({})
                setLoadingAdd(false)
                let newErrors = { ...errors };
                let firstErrorRef = null;

                console.log('EEEEEEE : ', error.response.data);

                if (error.response.data[0].provided && error.response.data[0].provided.some(e => e.CountryName)) {
                    newErrors.GeoTargeting = JSON.stringify(error.response.data[0].message)
                    console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.GeoTargetingRef);
                    if (!firstErrorRef) firstErrorRef = refAttributs.current.GeoTargetingRef;
                } else {
                    const { GeoTargeting, ...updatedErrors } = newErrors;
                    newErrors = updatedErrors;
                }
                if (error.response.data[0].value && error.response.data[0].value.some(e => e.SiteId)) {
                    console.log('Message Erreur : Site : ', error.response.data[0].message);
                    newErrors.SiteZoneTargeting = error.response.data[0].message
                    console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.SiteZoneTargetingRef);
                    if (!firstErrorRef) firstErrorRef = refAttributs.current.SiteZoneTargetingRef;
                } else {
                    const { SiteZoneTargeting, ...updatedErrors } = newErrors;
                    newErrors = updatedErrors;
                }


                setErrors(newErrors);

                if (firstErrorRef) {
                    console.log('Scrolling/focusing on:', firstErrorRef);

                    handleCloseConfirmFlight()
                    setTimeout(() => {
                        firstErrorRef.focus(); // For focusing
                    }, 500)
                }



                console.error('Error creating flight:', error.response ? error.response.data : error.message);
                // throw error; // Rethrow to handle it in the calling function
            }
        }

    }


    const getDataSites = async () => {
        try {
            const res = await axios.get(`${link}/get-sites/`);
            console.log('DATATATATA :', res.data.items);
            return res.data.items; // Return the data directly
        } catch (error) {
            console.log('ERROR :', error);
            throw error; // Optional: re-throw the error if you want to handle it elsewhere
        }
    };

    const getDataZones = async (id) => {
        try {
            const res = await axios.get(`${link}/get-zones/`);
            console.log('DATATATATA :', res.data.items);
            return res.data; // Return the data directly

        } catch (error) {
            console.log('ERROR :', error);
            throw error;

        }
    }

    const [dataSites, setDataSites] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getDataSites();
                setDataSites(data)
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures this runs once when the component mounts


    const [dataZones, setDataZones] = useState([])
    const [idSiteSelected, setIdSiteSelected] = useState()
    useEffect(() => {
        const fetchDataZones = async () => {
            try {
                const data = await getDataZones(idSiteSelected)
                setDataZones(data)
            } catch (error) {
                console.log('Error fetching zones data :', error);
            }
        }
        fetchDataZones()
    }, [])

    const [manageSiteTargetingLoading, setManageSiteTargetingLoading] = useState()
    const addSiteTargeting = async () => {
        let newErrors = { ...errors };
        let firstErrorRef = null;
        try {
            // if (dataSiteZoneTargeting?.length !== 0 && dataSiteZoneTargeting?.some(e => e.IsExclude === siteTargetingFormData?.IsExclude && e.SiteId === siteTargetingFormData?.SiteId && e.ZoneId === siteTargetingFormData?.ZoneId)) {
            //     newErrors.SiteZoneTargeting = 'This site target already exists.'
            //     console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.SiteZoneTargetingRef);
            //     if (!firstErrorRef) firstErrorRef = refAttributs.current.SiteZoneTargetingRef;

            // }
            // if (dataSiteZoneTargeting?.length !== 0 && dataSiteZoneTargeting?.some(e => e.IsExclude !== siteTargetingFormData?.IsExclude && e.SiteId === siteTargetingFormData?.SiteId && e.ZoneId === siteTargetingFormData?.ZoneId)) {
            //     newErrors.SiteZoneTargeting = 'An include rule exactly matches an exclude rule and is therefore invalid.'
            //     console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.SiteZoneTargetingRef);
            //     if (!firstErrorRef) firstErrorRef = refAttributs.current.SiteZoneTargetingRef;

            // }
            // if (dataSiteZoneTargeting?.length !== 0 && siteTargetingFormData?.IsExclude === true && dataSiteZoneTargeting?.some(e => e.IsExclude === false && e.SiteId === siteTargetingFormData?.SiteId)) {
            //     newErrors.SiteZoneTargeting = 'A rule included a site and zone, but another rule excludes that site.'
            //     console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.SiteZoneTargetingRef);
            //     if (!firstErrorRef) firstErrorRef = refAttributs.current.SiteZoneTargetingRef;

            // }

            // if (dataSiteZoneTargeting?.length !== 0 && dataSiteZoneTargeting?.some(e => e.SiteId === siteTargetingFormData?.SiteId && e.ZoneId === siteTargetingFormData?.ZoneId)) {
            //     newErrors.SiteZoneTargeting = 'This site target already exists.'
            //     console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.SiteZoneTargetingRef);
            //     if (!firstErrorRef) firstErrorRef = refAttributs.current.SiteZoneTargetingRef;

            // }
            // if (dataSiteZoneTargeting?.length !== 0 && dataSiteZoneTargeting?.some(e => e.SiteId === siteTargetingFormData?.SiteId && e.ZoneId !== null && siteTargetingFormData?.ZoneId === null)) {
            //     newErrors.SiteZoneTargeting = 'This site target already exists.'
            //     console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.SiteZoneTargetingRef);
            //     if (!firstErrorRef) firstErrorRef = refAttributs.current.SiteZoneTargetingRef;

            // }

            if (firstErrorRef) {
                console.log('Scrolling/focusing on:', firstErrorRef);
                firstErrorRef.focus();
            } else {
                if (parseInt(Id) !== 0) {
                    setManageSiteTargetingLoading(true)
                    const data = { siteTargetingFormData }
                    try {
                        await axios.post(`${link}/add-site-flight/${parseInt(Id)}/`, data).then(() => {
                            setSiteTargetingFormData({ IsExclude: false, })
                            setManageSiteTargetingLoading(false)
                            getDataSiteZoneFlight()

                            toast.success(("The site/zone is added"), {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                                theme: "light",
                            });
                        })

                    } catch (error) {
                        setManageSiteTargetingLoading(false)
                        // console.error('Error creating flight:', error.response ? error.response.data : error.message);

                        newErrors.SiteZoneTargeting = error.response.data[0].message
                        console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.SiteZoneTargetingRef);
                        if (!firstErrorRef) firstErrorRef = refAttributs.current.SiteZoneTargetingRef;
                        setErrors(newErrors);
                        if (firstErrorRef) {
                            console.log('Scrolling/focusing on:', firstErrorRef);
                            firstErrorRef.focus();
                        }

                    }
                } else {
                    const { SiteZoneTargeting, ...updatedErrors } = newErrors;
                    newErrors = updatedErrors;
                    const data = [...dataSiteZoneTargeting, siteTargetingFormData]
                    setDataSiteZoneTargeting(data)
                    setFlightFormData({
                        ...flightFormData,
                        'SiteZoneTargeting': data
                    })
                    setSiteTargetingFormData({ IsExclude: siteTargetingFormData?.IsExclude, SiteId: '' })
                    console.log('DATTTTTTTTTTTTTTA : ', [...dataSiteZoneTargeting, siteTargetingFormData]);
                }

            }

            setErrors(newErrors);
            console.log('newErrorsnewErrors : ', newErrors);


        } catch (error) {
            console.log('ERROR ! ', error);

        }
    }


    const [deleteSiteZoneTargetingselected, setDeleteSiteZoneTargetingselected] = useState()
    const deleteSiteZoneTargeting = async (idSite) => {
        try {
            await axios.get(`${link}/delete-site-flight/${parseInt(Id)}/${parseInt(idSite)}/`).then(() => {
                setDataSiteZoneTargeting(dataSiteZoneTargeting.filter(e => e.Id !== idSite))
                setDeleteSiteZoneTargetingselected()

                toast.success(("The site/zone is deleted"), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            })
        } catch (error) {
            setDeleteSiteZoneTargetingselected()
            console.log('ERROR : ', error);
        }
    }



    const getDataRegions = async (country) => {
        try {
            const res = await axios.get(`${link}/get-regions/${country}/`);
            console.log('DATATATATA Regions :', res.data.Regions);
            console.log('DATATATATA AllMetros :', res.data.AllMetros);
            return {
                regions: res.data.Regions,
                metros: res.data.AllMetros
            };
        } catch (error) {
            console.log('ERROR :', error);
            throw error; // Optional: re-throw the error if you want to handle it elsewhere
        }
    };

    const [dataRegions, setDataRegions] = useState([])
    const [dataMetros, setDataMetros] = useState([])
    const [loadingDataRegions, setLoadingDataRegion] = useState()
    useEffect(() => {
        if (geoTargetingFormData?.CountryCode) {
            const fetchData = async () => {
                setLoadingDataRegion(true)
                try {
                    const { regions, metros } = await getDataRegions(geoTargetingFormData?.CountryCode)
                    console.log('DATAAAAAAAAAAAAA REGION : ', regions);
                    console.log('DATAAAAAAAAAAAAA Metros : ', metros);

                    setDataRegions(regions || [])
                    setDataMetros(metros || [])
                    setLoadingDataRegion(false)
                } catch (error) {
                    console.log('Error fetching data:', error);
                    setLoadingDataRegion(false)
                }
            };

            fetchData();
        }
    }, [geoTargetingFormData?.CountryCode]);

    const [manageGeoTargetingLoading, setManageGeoTargetingLoading] = useState()
    const addGeoTargeting = async () => {
        let newErrors = { ...errors };
        let firstErrorRef = null;
        try {
            // if (dataGeoTargeting && dataGeoTargeting?.some(e => e.Region === geoTargetingFormData?.Region)) {
            //     newErrors.GeoTargeting = 'Geolocation Record Already Exists.'
            //     console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.GeoTargetingRef);
            //     if (!firstErrorRef) firstErrorRef = refAttributs.current.GeoTargetingRef;

            // }
            console.log('newErrorsnewErrors : ', newErrors);

            if (firstErrorRef) {
                console.log('Scrolling/focusing on:', firstErrorRef);
                firstErrorRef.focus();
            }
            else {
                if (parseInt(Id) !== 0) {
                    setManageGeoTargetingLoading(true)
                    const data = { geoTargetingFormData }
                    try {
                        await axios.post(`${link}/add-geoTargeting-flight/${parseInt(Id)}/`, data).then(() => {
                            setGeoTargetingFormData({
                                IsExclude: false,
                            })
                            setManageGeoTargetingLoading(false)
                            getDataGeoTargetingFlight()

                            toast.success(("The Geo Targeting is added"), {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                                theme: "light",
                            });
                        })

                    } catch (error) {
                        setManageGeoTargetingLoading(false)

                        console.log('ERROR : ', JSON.stringify(error.response.data[0].message));
                        console.error('Error creating GeoTargeting:', error.response ? error.response.data : error.message);

                        newErrors.GeoTargeting = JSON.stringify(error.response.data[0].message)
                        console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.GeoTargetingRef);
                        if (!firstErrorRef) firstErrorRef = refAttributs.current.GeoTargetingRef;
                        setErrors(newErrors);
                        if (firstErrorRef) {
                            console.log('Scrolling/focusing on:', firstErrorRef);
                            firstErrorRef.focus();
                        }

                    }
                }
                else {
                    const { SiteZoneTargeting, ...updatedErrors } = newErrors;
                    newErrors = updatedErrors;
                    const data = [...dataGeoTargeting, geoTargetingFormData]
                    setDataGeoTargeting(data)
                    setFlightFormData({
                        ...flightFormData,
                        'GeoTargeting': data
                    })
                    setGeoTargetingFormData({ IsExclude: geoTargetingFormData?.IsExclude, CountryCode: '', Region: '', MetroCode: '' })
                    console.log('DATTTTTTTTTTTTTTA : ', [...geoTargetingFormData, geoTargetingFormData]);
                }
            }


        } catch (error) {
            console.log('ERROR ! ', error);

        }
    }


    const [deleteGeoTargetingselected, setDeleteGeoTargetingselected] = useState()
    const deleteGeoTargeting = async (idGeo) => {
        try {
            await axios.get(`${link}/delete-geoTargeting-flight/${parseInt(Id)}/${parseInt(idGeo)}/`).then(() => {
                setDataGeoTargeting(dataGeoTargeting.filter(e => e.LocationId !== idGeo))
                setDeleteGeoTargetingselected()

                toast.success(("The Geo Targeting is deleted"), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            })
        } catch (error) {
            setDeleteGeoTargetingselected()
            console.log('ERROR : ', error);
        }
    }

    const get_Region_Metros_Name = (CountryCode, regionCode, metrosCode) => {
        try {
            axios.get(`${link}/get-regions/${CountryCode}/`).then(async (res) => {
                console.log('TEEEEEEEST : ', res.data.Regions.find(e => e.Code === regionCode));
                const region = res.data.Regions.find(e => e.Code === regionCode)
                const regionName = region ? region.Name : '-'
                const MetroName = '-'
                console.log('TEEEEEEEST : ', 515, ' : ', res.data.AllMetros);
                return {
                    regionName,
                    MetroName
                }
            })
        } catch (error) {
            console.log('ERROR : ', error.message);
            return 'Error occurred'; // Retourner une valeur par défaut en cas d'erreur
        }
    };


    // AD Management
    const getDataAdTypes = async (format) => {
        try {
            const res = await axios.get(`${link}/get-adTypes/`);
            console.log('DATATATATA :', res.data.items);
            if (format === 'Image') {
                const data = res.data.items.filter(e => !e.Name.includes('video'))
                console.log('DATATATATA_DATATATATA : ', data);
                return data

            } else {
                const data = res.data.items.filter(e => e.Name.includes('video'))
                console.log('DATATATATA_DATATATATA : ', data);
                return data
            }
            // return res.data.items; // Return the data directly
        } catch (error) {
            console.log('ERROR :', error);
            throw error; // Optional: re-throw the error if you want to handle it elsewhere
        }
    }


    const [dataAdTypes, setDataAdTypes] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getDataAdTypes(format);
                console.log('DATA AD TYPEs : ', data);

                setDataAdTypes(data)
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };

        fetchData();
    }, [format]); // Empty dependency array ensures this runs once when the component mounts


    //Variable 
    const [fieldName, setFieldName] = useState()
    const [imageLink, setImageLink] = useState('')
    const [title, setTitle] = useState('')
    const [adTypeId, setAdTypeId] = useState()
    const [url, setUrl] = useState('')
    const [altText, setAltText] = useState('')
    const [metadata, setMetadata] = useState('')
    const [ctDuration, setCtDuration] = useState('')

    const [IdsCreativeUpdated, setIdsCreativeUpdated] = useState([])

    const [errorsAd, setErrorsAd] = useState({})
    const refAttributsAd = useRef({
        FieldNameRef: null,
        ImageLinkRef: null,
        TitleRef: null,
        AdTypeIdRef: null,
        UrlRef: null,
        AltRef: null,
        MetadataRef: null,
        ctPlayback_URLRef: null,
        ctdurationRef: null,
    })

    const [manageAdLoading, setManageAdLoading] = useState()
    const createNewAd = async () => {

        let newErrors = { ...errorsAd };
        let firstErrorRef = null;

        if (format === "Image" && !creativeMapsFormData?.ImageLink && (!creativeMapsFormData?.FieldName || creativeMapsFormData?.ImageLink === null)) {
            newErrors.FieldName = 'This field is required'
            console.log('USE ImageLink ! ', refAttributs.current.FieldNameRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.FieldNameRef;
        } else {
            const { ImageLink, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (format === "Image" && !creativeMapsFormData?.FieldName && (!creativeMapsFormData?.ImageLink || creativeMapsFormData?.ImageLink === '')) {
            newErrors.ImageLink = 'This field is required'
            console.log('USE ImageLink ! ', refAttributs.current.ImageLinkRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.ImageLinkRef;
        } else {
            const { ImageLink, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (format === "Video" && !creativeMapsFormData?.TemplateValues?.ctPlayback_URL) {
            newErrors.ctPlayback_URL = 'Upload new video'
            console.log('USE ImageLink ! ', refAttributs.current.ctPlayback_URLRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.ctPlayback_URLRef;
        } else {
            const { ctPlayback_URL, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (format === "Video" && (!creativeMapsFormData?.TemplateValues?.ctduration || !creativeMapsFormData?.TemplateValues?.ctduration === '')) {
            newErrors.ctduration = 'This field is required'
            console.log('USE ImageLink ! ', refAttributs.current.ctdurationRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.ctdurationRef;
        } else {
            const { ctduration, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (!creativeMapsFormData?.Title || creativeMapsFormData?.Title === '') {
            newErrors.Title = 'This field is required'
            console.log('USE Title ! ', refAttributs.current.TitleRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.TitleRef;
        } else {
            const { Title, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (!creativeMapsFormData?.AdTypeId || creativeMapsFormData?.AdTypeId === '') {
            newErrors.AdTypeId = 'This field is required'
            console.log('USE AdTypeId ! ', refAttributs.current.AdTypeIdRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.AdTypeIdRef;
        } else {
            const { AdTypeId, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (!creativeMapsFormData?.Url || creativeMapsFormData?.Url === '') {
            newErrors.Url = 'This field is required'
            console.log('USE Url ! ', refAttributs.current.UrlRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.UrlRef;
        } else {
            const { Url, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (creativeMapsFormData?.Metadata && typeof creativeMapsFormData.Metadata === 'object') {
            newErrors.Metadata = 'Invalid JSON object'
            console.log('USE Metadata ! ', refAttributs.current.MetadataRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.MetadataRef;
        } else {
            const { Metadata, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        setErrorsAd(newErrors);
        console.log(newErrors);
        if (firstErrorRef) {
            console.log('Scrolling/focusing on:', firstErrorRef);
            firstErrorRef.focus();
        } else {
            if (parseInt(Id) !== 0) {
                setManageAdLoading(true)
                console.log('parseInt(Id) : ', parseInt(Id));

                // console.log('creativeMapsFormDatacreativeMapsFormData : ', creativeMapsFormData);


                let dataImagesAdded;
                const formData = new FormData();
                const FieldName = creativeMapsFormData?.FieldName || ''; // Extraire FieldName s'il existe, sinon utiliser une chaîne vide
                const index = 0; // Extraire Id s'il existe, sinon utiliser l'index
                if (FieldName) {

                    formData.append('file', FieldName);
                    formData.append('Id', index);


                    try {
                        const response = await axios.post(`${link}/upload-image/`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        });
                        dataImagesAdded = response.data;
                        console.log(response.data);
                    } catch (error) {
                        console.error('Error uploading image:', error.response ? error.response.data : error.message);
                        throw error; // Rethrow error to stop execution and handle it in the calling function
                    }

                }

                try {
                    const data = {
                        dataCreativeMaps: {
                            IsActive: true,
                            Creative: {
                                ...creativeMapsFormData,
                                TemplateValues: format === "Video" ?
                                    JSON.stringify({
                                        ctPlayback_URL: linkAsset,
                                        ctduration: ctDuration
                                    }) : null, // Conversion de TemplateValues en chaîne JSON encodée,
                                TemplateId: format === "Video" ? idTemplate : null,
                            }
                        },
                        dataFiles: dataImagesAdded,
                    };
                    await axios.post(`${link}/add-Ad/${parseInt(Id)}/`, data).then(() => {
                        handleCloseAddAdModal()
                        setManageAdLoading(false)
                        getDataAds()

                        toast.success(("The Ad is added"), {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        });
                    })
                    console.log('DATA : ', data);


                    // return response.data;
                } catch (error) {
                    setManageAdLoading(false)
                    console.error('Error creating flight:', error.response ? error.response.data : error.message);
                    throw error; // Rethrow to handle it in the calling function
                }
            }
            else {
                setDataCreativeMaps(
                    [
                        ...dataCreativeMaps,
                        {
                            IsActive: true,
                            Creative: {
                                ...creativeMapsFormData,
                                TemplateValues: format === "Video" ?
                                    JSON.stringify({
                                        ctPlayback_URL: linkAsset,
                                        ctduration: ctDuration
                                    }) : null, // Conversion de TemplateValues en chaîne JSON encodée,
                                TemplateId: format === "Video" ? idTemplate : null,
                            }
                        }
                    ]
                )
                setCreativeMapsFormData({
                    IsActive: true,
                    Body: '',
                })
                setErrorsAd({})
                handleCloseAddAdModal()
            }

        }

    }

    const updateAd = async () => {

        let newErrors = { ...errorsAd };
        let firstErrorRef = null;

        if (format === "Image" && !imageLink && !fieldName) {
            newErrors.FieldName = 'This field is required';
            newErrors.ImageLink = 'This field is required';
            console.log('USE FieldName !', refAttributs.current.FieldNameRef);
            if (!firstErrorRef) {
                firstErrorRef = refAttributsAd.current.ImageLinkRef
                firstErrorRef = refAttributsAd.current.FieldNameRef
            }
        } else {
            const { FieldName, ImageLink, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (format === "Video" && (!linkAsset || linkAsset === '')) {
            newErrors.ctPlayback_URL = 'Upload new video'
            console.log('USE ImageLink ! ', refAttributs.current.ctPlayback_URLRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.ctPlayback_URLRef;
        } else {
            const { ctPlayback_URL, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (format === "Video" && (!ctDuration || !ctDuration === '')) {
            newErrors.ctduration = 'This field is required'
            console.log('USE ImageLink ! ', refAttributs.current.ctdurationRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.ctdurationRef;
        } else {
            const { ctduration, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        // if ((!fieldName) || imageLink === null || imageLink === '') {
        //     newErrors.ImageLink = 'This field is required';
        //     console.log('USE ImageLink !', refAttributs.current.ImageLinkRef);
        //     if (!firstErrorRef) firstErrorRef = refAttributsAd.current.ImageLinkRef;
        // } else {
        //     const { ImageLink, ...updatedErrors } = newErrors;
        //     newErrors = updatedErrors;
        // }

        if (!title || title === '') {
            newErrors.Title = 'This field is required'
            console.log('USE Title ! ', refAttributs.current.TitleRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.TitleRef;
        } else {
            const { Title, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (!adTypeId || adTypeId === '') {
            newErrors.AdTypeId = 'This field is required'
            console.log('USE AdTypeId ! ', refAttributs.current.AdTypeIdRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.AdTypeIdRef;
        } else {
            const { AdTypeId, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (!url || url === '') {
            newErrors.Url = 'This field is required'
            console.log('USE Url ! ', refAttributs.current.UrlRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.UrlRef;
        } else {
            const { Url, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (metadata && typeof metadata === 'object') {
            newErrors.Metadata = 'Invalid JSON object'
            console.log('USE Metadata ! ', refAttributs.current.MetadataRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.MetadataRef;
        } else {
            const { Metadata, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        setErrorsAd(newErrors);
        console.log(newErrors);
        if (firstErrorRef) {
            console.log('Scrolling/focusing on:', firstErrorRef);
            firstErrorRef.focus();
        }
        else {
            if (parseInt(Id) !== 0) {
                setManageAdLoading(true)
                try {
                    let idCreative
                    let creative
                    await dataCreativeMaps.map((val, key) => {
                        if (key === keyAdSelected) {
                            idCreative = val.Creative.Id
                            creative = {
                                ...val.Creative,
                                FieldName: format === "Image" ? fieldName : '',
                                ImageLink: fieldName ? '' : imageLink,
                                ImageName: '',
                                Title: title,
                                AdTypeId: parseInt(adTypeId),
                                Url: url,
                                Alt: altText,
                                Metadata: metadata,
                                TemplateValues: format === "Video" ?
                                    JSON.stringify({
                                        ctPlayback_URL: linkAsset,
                                        ctduration: ctDuration
                                    }) : null, // Conversion de TemplateValues en chaîne JSON encodée,
                                TemplateId: format === "Video" ? idTemplate : null,
                            }
                            console.log('creativecreativecreativecreative : ', creative);

                        }
                    })

                    console.log('dataCreativeMapsdataCreativeMapsdataCreativeMaps : ',dataCreativeMaps);
        
                    let dataImagesAdded;
                    const formData = new FormData();
                    if (fieldName) {

                        formData.append('file', fieldName);
                        formData.append('Id', idCreative);


                        try {
                            const response = await axios.post(`${link}/upload-image/`, formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            });
                            dataImagesAdded = response.data;
                            console.log(response.data);
                        } catch (error) {
                            console.error('Error uploading image:', error.response ? error.response.data : error.message);
                            throw error; // Rethrow error to stop execution and handle it in the calling function
                        }

                    }
                    try {
                        const data = {
                            dataCreativeMaps: creative,
                            dataFiles: format === "Image" && dataImagesAdded,
                        };
                        axios.put(`${link}/edit-Ad/${parseInt(idCreative)}/`, data).then((res) => {
                            handleCloseUpdateAddModal()
                            setManageAdLoading(false)
                            getDataAds()
                            console.log('RRRRRRRRRRRRRRRRRRRRRR : ', res.data);

                            toast.success(("The Ad is updated"), {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                                theme: "light",
                            });

                        })
                    } catch (error) {
                        setManageAdLoading(false)
                        console.error('Error updating flight:', error.response ? error.response.data : error.message);
                        throw error; // Rethrow to handle it in the calling function
                    }

                } catch (error) {
                    setManageAdLoading(false)
                    console.log('ERROR UPDATE AD FUNCTION : ', error);

                }
            }
            // else {

            //     await setDataCreativeMaps(dataCreativeMaps.map((val, key) => {
            //         if (key === keyAdSelected) {
            //             setIdsCreativeUpdated([...IdsCreativeUpdated, val.Id])
            //             return {
            //                 ...val,
            //                 Creative: {
            //                     ...val.Creative,
            //                     FieldName: format === "Image" ? fieldName : '',
            //                     ImageLink: fieldName ? '' : imageLink,
            //                     ImageName: '',
            //                     Title: title,
            //                     AdTypeId: parseInt(adTypeId),
            //                     Url: url,
            //                     Alt: altText,
            //                     Metadata: metadata,
            //                     TemplateValues: format === "Video" ?
            //                         JSON.stringify({
            //                             ctPlayback_URL: linkAsset,
            //                             ctduration: ctDuration
            //                         }) : null, // Conversion de TemplateValues en chaîne JSON encodée,
            //                     TemplateId: format === "Video" ? idTemplate : null,
            //                 }
            //             }
            //         }
            //         return val
            //     }))
            //     handleCloseUpdateAddModal()
            // }
        }

        
    }

    const deletedAd = async () => {
        try {
            setLoadingAds(true)
            await axios.get(`${link}/delete-Ad/${parseInt(Id)}/${parseInt(idAdSelected)}/`).then(() => {
                const data = dataCreativeMaps.filter((e) => e.Id !== idAdSelected)
                setDataCreativeMaps(data)
                setIdAdSelected()
                handleCloseDeleteAdModal()
                setLoadingAds(false)

                toast.success(("The Ad is deleted"), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            })
        } catch (error) {
            console.log('ERROR : ', error);
            setLoadingAds(false)

        }
    }

    const updateIsActive = async (id) => {
        axios.put(`${link}/update-isActive-Ad/${Id}/${id}/`)
            .then(res => {
                setDataCreativeMaps(dataCreativeMaps.map(item => {
                    if (item.Id === res.data.Id) {
                        return {
                            ...item,
                            IsActive: res.data.IsActive
                        }
                    }
                    return item
                }))
                if (res.data.IsActive) {
                    toast.success(("The Ad is enabled"), {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    toast.success(("The Ad is disabled"), {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                }

            }).catch(err => {
                console.error('ERROOR : ', err)

            })
    }

    useEffect(() => {
        console.log('IdsCreativeUpdated FROM UPDATE AD FUNCTION : ', IdsCreativeUpdated);
        console.log('dataCreativeMaps FROM UPDATE AD FUNCTION : ', dataCreativeMaps);

    }, [IdsCreativeUpdated, dataCreativeMaps])

    return (
        <>
            {!loading ?
                <div style={{ position: 'relative' }}>
                    <ToastContainer />

                    <Accordion
                        defaultActiveKey={[
                            '0',
                            '1',
                            `${dataCreativeMaps.length !== 0 ? 1 : null}`,
                            `${flightFormData?.Keywords ? 3 : null}`,
                            `${dataSiteZoneTargeting.length !== 0 ? 6 : null}`,
                            `${dataGeoTargeting.length !== 0 ? 7 : null}`,
                        ]}
                        alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>General Settings</Accordion.Header>
                            <Accordion.Body>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        ref={(e) => refAttributs.current.NameRef = e}
                                        type="text"
                                        placeholder="Name"
                                        value={flightFormData?.Name}
                                        onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'Name': e.target.value
                                            })

                                        }
                                        } />
                                    {errors?.Name && <Form.Text className="text-danger">{errors?.Name}</Form.Text>}
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasiPriority">
                                    <Form.Label>Priority</Form.Label>
                                    <Form.Select
                                        ref={(e) => refAttributs.current.PriorityIdRef = e}
                                        value={flightFormData?.PriorityId}
                                        onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'PriorityId': parseInt(e.target.value)
                                            })

                                        }
                                        } >
                                        <option value=''>-- Choose --</option>
                                        {dataPriorities.map((val, key) => {
                                            return (
                                                <option key={key} value={val.Id}>
                                                    <span>{val.Name}</span>
                                                    <em>(All sites)</em>
                                                </option>
                                            )
                                        })}
                                    </Form.Select>
                                    {errors?.PriorityId && <Form.Text className="text-danger">{errors?.PriorityId}</Form.Text>}
                                </Form.Group>
                                <div className='d-flex flex-column flex-md-row gap-2 mb-3'>
                                    <Form.Group className="w-100" controlId="formBasicTimeZone">
                                        <Form.Label>Time Zone</Form.Label>

                                        <Form.Select value={flightFormData?.TimeZone} onChange={handleTimeZoneChange}>
                                            {DataTimeZone.map((val, key) => {
                                                return (
                                                    <option key={key} value={val.value} title={val.label}>{val.label} | {val.value}</option>
                                                )
                                            })}
                                        </Form.Select>

                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicStartDate">
                                        <Form.Label>Start Date</Form.Label>
                                        <Form.Control type="datetime-local" value={flightFormData.StartDate} onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'StartDate': e.target.value,
                                                'StartDateISO': e.target.value,
                                            })

                                        }
                                        } />
                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicEndDate">
                                        <Form.Label>End Date</Form.Label>
                                        <Form.Control ref={(e) => refAttributs.current.EndDateRef = e} type="datetime-local" value={flightFormData?.EndDate} onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'EndDate': e.target.value,
                                                'EndDateISO': e.target.value,
                                            })

                                        }
                                        } />
                                        {errors?.EndDate && <Form.Text className="text-danger">{errors?.EndDate}</Form.Text>}
                                    </Form.Group>
                                </div>
                                <div className='d-flex flex-column flex-md-row gap-2 mb-3'>
                                    <Form.Group className="w-100" controlId="formBasicRate">
                                        <Form.Label>Rate</Form.Label>

                                        <Form.Select value={flightFormData?.RateType} onChange={e => {
                                            if ([4, 5, 6].includes(parseInt(flightFormData?.RateType))) {
                                                console.log('YEEEEEEEEEEs');

                                                setFlightFormData({
                                                    ...flightFormData,
                                                    'RateType': parseInt(e.target.value),
                                                    'IsTrackingConversions': true
                                                })

                                            } else {
                                                console.log('NOOOOOOOOOoooooo');
                                                setFlightFormData({
                                                    ...flightFormData,
                                                    'RateType': parseInt(e.target.value)
                                                })

                                            }
                                        }
                                        } >
                                            <option value={1}>
                                                <span>Flat</span>
                                            </option>
                                            <option value={2}>
                                                <span>CPM</span>
                                            </option>
                                            <option value={3}>
                                                <span>CPC</span>
                                            </option>
                                            <option value={4}>
                                                <span>CPA (View)</span>
                                            </option>
                                            <option value={5}>
                                                <span>CPA (Click)</span>
                                            </option>
                                            <option value={6}>
                                                <span>CPA (View & Click)</span>
                                            </option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicPrice">
                                        <Form.Label>Price</Form.Label>
                                        <Form.Control type="text" placeholder="Price" value={flightFormData?.Price} onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'Price': e.target.value
                                            })

                                        }
                                        } />
                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="dailyRevenue">
                                        <Form.Label></Form.Label>
                                        <Form.Check
                                            type="checkbox"
                                            label="Track Conversions"
                                            checked={flightFormData?.IsTrackingConversions}
                                            onChange={e => {
                                                if (![4, 5, 6].includes(parseInt(flightFormData?.RateType))) {
                                                    console.log('NONONONONONONO');
                                                    setFlightFormData({
                                                        ...flightFormData,
                                                        'IsTrackingConversions': e.target.checked
                                                    })
                                                }
                                            }
                                            }
                                        />
                                    </Form.Group>
                                </div>
                                <div className='d-flex flex-column flex-md-row gap-2 mb-3'>
                                    <Form.Group className="w-100" controlId="formBasicGoalType">
                                        <Form.Label>Goal Type</Form.Label>

                                        <Form.Select value={flightFormData?.GoalType} onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'GoalType': parseInt(e.target.value)
                                            })

                                        }
                                        } >
                                            <option value={1}>
                                                <span>Impressions</span>
                                            </option>
                                            <option value={2}>
                                                <span>Percentage</span>
                                            </option>
                                            <option value={3}>
                                                <span>Click</span>
                                            </option>
                                            <option value={7}>
                                                <span>Conversions</span>
                                            </option>
                                            <option value={8}>
                                                <span>Revenue</span>
                                            </option>
                                            <option value={9}>
                                                <span>Daily Revenue</span>
                                            </option>
                                            <option value={10}>
                                                <span>Monthly Revenue</span>
                                            </option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicGoalAmount">
                                        <Form.Label>Goal Amount</Form.Label>
                                        <Form.Control
                                            ref={(e) => refAttributs.current.ImpressionsRef = e}
                                            type="text"
                                            placeholder="Goal Amount"
                                            value={flightFormData?.Impressions}
                                            onChange={e => {
                                                setFlightFormData({
                                                    ...flightFormData,
                                                    'Impressions': e.target.value
                                                })

                                            }
                                            } />

                                        {errors?.Impressions && <Form.Text className="text-danger">{errors?.Impressions}</Form.Text>}
                                    </Form.Group>
                                </div>
                                <div className='d-flex flex-column flex-md-row gap-2 mb-3'>
                                    <Form.Group className="w-100" controlId="formBasicCapType">
                                        <Form.Label>Cap Type</Form.Label>

                                        <Form.Select value={flightFormData?.CapType} onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'CapType': parseInt(e.target.value)
                                            })

                                        }
                                        } >
                                            <option value=''>
                                                <span>None</span>
                                            </option>
                                            <option value={1}>
                                                <span>Impressions</span>
                                            </option>
                                            <option value={2}>
                                                <span>Click</span>
                                            </option>
                                            <option value={3}>
                                                <span>Converions</span>
                                            </option>
                                            <option value={4}>
                                                <span>Budget</span>
                                            </option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicDaily">
                                        <Form.Label>Daily</Form.Label>
                                        <Form.Control ref={(e) => refAttributs.current.DailyCapAmountRef = e} type="text" placeholder="Daily" value={flightFormData?.DailyCapAmount} onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'DailyCapAmount': e.target.value,
                                                'DailyCapAmountDecimal': parseFloat(e.target.value).toFixed(2),
                                            })

                                        }
                                        } />
                                        {errors?.DailyCapAmount && <Form.Text className="text-danger">{errors?.DailyCapAmount}</Form.Text>}
                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicLifetime">
                                        <Form.Label>Lifetime</Form.Label>
                                        <Form.Control ref={(e) => refAttributs.current.LifetimeCapAmountRef = e} type="text" placeholder="Lifetime" value={flightFormData?.LifetimeCapAmount} onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'LifetimeCapAmount': e.target.value,
                                                'LifetimeCapAmountDecimal': parseFloat(e.target.value).toFixed(2),
                                            })

                                        }
                                        } />
                                        {errors?.LifetimeCapAmount && <Form.Text className="text-danger">{errors?.LifetimeCapAmount}</Form.Text>}
                                    </Form.Group>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item className='mt-4' eventKey="1">
                            <Accordion.Header>Ads</Accordion.Header>
                            {!loadingAds ?
                                <Accordion.Body className='p-0'>
                                    <div className='p-4' style={{ maxHeight: 400, overflow: 'auto' }}>
                                        {dataCreativeMaps.length !== 0 ?
                                            <>

                                                {/* PAGINATION */}
                                                <Row>
                                                    <Col lg={12} className="d-flex justify-content-start">

                                                        <TablePagination
                                                            component="div"
                                                            count={totalPages * pageSize} // Total number of items
                                                            page={page}
                                                            onPageChange={handlePageChange}
                                                            rowsPerPage={pageSize}
                                                            onRowsPerPageChange={handlePageSizeChange}

                                                            rowsPerPageOptions={[10, 20, 30, 40, 50]} // Define page size options
                                                            showFirstButton={true}
                                                            showLastButton={true}
                                                            sx={{
                                                                ".MuiTablePagination-displayedRows": {
                                                                    margin: 0
                                                                },
                                                                ".MuiTablePagination-selectLabel": {
                                                                    display: 'none',
                                                                    margin: 0
                                                                },
                                                                ".MuiSelect-select": {
                                                                    backgroundColor: "#ededed"
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>

                                                {/* PAGINATION */}
                                                <Table className='mt-4' style={{ width: '100%', borderColor: 'lightgray' }} responsive>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col">Active</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Ad ID</th>
                                                            {/* <th scope="col">Status</th> */}
                                                            <th scope="col">Impressions</th>
                                                            <th scope="col">Clicks</th>
                                                            <th scope="col">Unique Clicks</th>
                                                            <th scope="col">Conversions</th>
                                                            <th scope="col">Revenue</th>
                                                            <th scope="col">CTR %</th>
                                                            <th scope="col">GMV</th>
                                                            <th scope="col">Roas</th>
                                                            <th scope="col">Last Updated</th>
                                                            <th scope="col">Options</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dataCreativeMaps.map((val, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>
                                                                        <img src={val.Creative?.ImageLink} style={{
                                                                            height: 'auto',
                                                                            maxWidth: 40,
                                                                            width: 'auto !important',
                                                                        }} />
                                                                    </td>
                                                                    <td className='d-flex justify-content-center align-self-center'>
                                                                        <div className="form-check form-switch">
                                                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" style={{ cursor: 'pointer' }} checked={val.IsActive} onClick={(e) => {
                                                                                updateIsActive(val.Id)
                                                                                // setDataCreativeMaps(dataCreativeMaps.map((v, k) => {
                                                                                //     if (v.Id === val.Id) {
                                                                                //         if (val.Id !== undefined && !IdsCreativeUpdated.includes(val.Id)) {
                                                                                //             setIdsCreativeUpdated([...IdsCreativeUpdated, val.Id]);
                                                                                //         }
                                                                                //         return ({
                                                                                //             ...v,
                                                                                //             IsActive: !val.IsActive
                                                                                //         })
                                                                                //     }
                                                                                //     return v
                                                                                // }))
                                                                            }}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span>{val.Creative?.Title}</span>

                                                                        {/* <a className='d-flex flex-column' onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}>
                                    <span>Second campaign</span>
                                </a> */}
                                                                    </td>
                                                                    <td>{val.Id}</td>
                                                                    {/* <td>
                                                                        status
                                                                    </td> */}
                                                                    <td>0</td>
                                                                    <td>
                                                                        0
                                                                    </td>
                                                                    <td>
                                                                        -
                                                                    </td>
                                                                    <td>
                                                                        0
                                                                    </td>
                                                                    <td>$0.00</td>
                                                                    <td>-</td>
                                                                    <td>-</td>
                                                                    <td>-</td>
                                                                    <td>
                                                                        {new Date().toUTCString(val.LastModified)}
                                                                    </td>
                                                                    <td>
                                                                        <div className='d-flex justify-content-between'>
                                                                            {/* <Link to={`/add-flight/${val.Id}/`} style={{}}> */}
                                                                            <span class="mdi mdi-pencil" style={{ fontSize: 25, cursor: "pointer" }} onClick={() => {
                                                                                handleShowUpdateAddModal()
                                                                                setKeyAdSelected(key)
                                                                                setImageLink(dataCreativeMaps.find((_, index) => index === key)?.Creative?.ImageLink != 'https://static.adzerk.net/Advertisers/' ? dataCreativeMaps.find((_, index) => index === key)?.Creative?.ImageLink : '')
                                                                                setTitle(dataCreativeMaps.find((_, index) => index === key)?.Creative?.Title)
                                                                                setAdTypeId(dataCreativeMaps.find((_, index) => index === key)?.Creative?.AdTypeId)
                                                                                setUrl(dataCreativeMaps.find((_, index) => index === key)?.Creative?.Url)
                                                                                setAltText(dataCreativeMaps.find((_, index) => index === key)?.Creative?.Alt)
                                                                                setMetadata(dataCreativeMaps.find((_, index) => index === key)?.Creative?.Metadata)
                                                                                setLinkAsset(dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues ? JSON.parse(dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues)?.ctPlayback_URL : '')
                                                                                setCtDuration(dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues ? JSON.parse(dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues)?.ctduration : '')
                                                                                setFormat(dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues?.length > 0 ? 'Video' : 'Image')
                                                                                console.log('sddsfsdf : ',dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues?.length > 0 ? 'Video' : 'Image');
                                                                                
                                                                            }
                                                                            }></span>
                                                                            {/* </Link> */}
                                                                            <span class="mdi mdi-close" style={{ fontSize: 25, cursor: 'pointer' }} onClick={() => {
                                                                                handleShowDeleteAdModal()
                                                                                setIdAdSelected(val.Id)

                                                                                // setFlightFormData({
                                                                                //     ...flightFormData,
                                                                                //     'SiteZoneTargeting': data
                                                                                // })
                                                                            }}></span>



                                                                            <IconButton
                                                                                aria-label="more"
                                                                                id="long-button"
                                                                                aria-controls={open ? 'long-menu' : undefined}
                                                                                aria-expanded={open ? 'true' : undefined}
                                                                                aria-haspopup="true"
                                                                                onClick={handleClick}
                                                                            >
                                                                                <MoreVertIcon />
                                                                            </IconButton>
                                                                            <Menu
                                                                                id="long-menu"
                                                                                MenuListProps={{
                                                                                    'aria-labelledby': 'long-button',
                                                                                }}
                                                                                anchorEl={anchorEl}
                                                                                open={open}
                                                                                onClose={handleClose}
                                                                                slotProps={{
                                                                                    paper: {
                                                                                        style: {
                                                                                            maxHeight: 48 * 4.5,
                                                                                            width: '20ch',
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            >

                                                                                {/* <MenuItem onClick={handleClose}>
                                                            Item1
                                                        </MenuItem> */}
                                                                                <MenuItem onClick={() => {
                                                                                    handleClose()
                                                                                    toast.success(("This feature is currently under development"), {
                                                                                        position: "top-center",
                                                                                        autoClose: 3000,
                                                                                        hideProgressBar: false,
                                                                                        closeOnClick: false,
                                                                                        pauseOnHover: false,
                                                                                        draggable: false,
                                                                                        progress: undefined,
                                                                                        theme: "light",
                                                                                    });
                                                                                }}>
                                                                                    Generate Ad Code
                                                                                </MenuItem>
                                                                                <MenuItem onClick={() => {
                                                                                    handleClose()
                                                                                    toast.success(("This feature is currently under development"), {
                                                                                        position: "top-center",
                                                                                        autoClose: 3000,
                                                                                        hideProgressBar: false,
                                                                                        closeOnClick: false,
                                                                                        pauseOnHover: false,
                                                                                        draggable: false,
                                                                                        progress: undefined,
                                                                                        theme: "light",
                                                                                    });
                                                                                }}>
                                                                                    View Report
                                                                                </MenuItem>
                                                                            </Menu>


                                                                            {/* <DropdownButton
                                        as={ButtonGroup}
                                        align="end"
                                        title={<span class="mdi mdi-dots-vertical" style={{ fontSize: 25 }}></span>}
                                        id="dropdown-menu-align-end"
                                    >
                                        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                                        <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                                        <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
                                    </DropdownButton> */}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </>
                                            :
                                            <div>
                                                No items found.

                                            </div>}
                                    </div>

                                    <div className='w-100' style={{ height: 50, background: "lightgray", display: 'flex', alignItems: 'center' }}>
                                        <button style={{
                                            marginLeft: 20,
                                            padding: '5px 10px',
                                            borderRadius: 7,
                                            background: 'orange',
                                            border: 'none',
                                            color: 'white'
                                        }}
                                            onClick={handleShowAddAdModal}
                                        >
                                            Create Ad
                                        </button>
                                    </div>
                                </Accordion.Body>
                                :
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: 10,
                                    padding: 10
                                }}>
                                    <span>Loading</span>
                                    <Spinner animation="grow" size='sm' />
                                </div>}
                        </Accordion.Item>

                        {/* <Accordion.Item className='mt-4' eventKey="2">
                    <Accordion.Header>Behavioral Targeting</Accordion.Header>
                    <Accordion.Body style={{ padding: 0 }}>
                        <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                            <strong>Behavioral Targeting delivers ads to users based on their interests, as determined by their interactions with other ads.</strong><br />
                            <span>UserDB is required for storing interests.</span>
                        </Alert>

                        <div className='px-4'>
                            <div className='d-flex flex-wrap gap-1'>
                                <span>When a user</span>
                                <div>
                                    <Form.Group className="px-2 " controlId="clicks1" style={{ background: 'lightgray' }}>
                                        <Form.Check type="checkbox" label="clicks" />
                                    </Form.Group>
                                </div>
                                <span>or</span>
                                <div>
                                    <Form.Group className="px-2" controlId="converts1" style={{ background: 'lightgray' }}>
                                        <Form.Check type="checkbox" label="converts" />
                                    </Form.Group>
                                </div>
                                <span>
                                    on an ad from this flight <strong>stop showing</strong> them ads in this <strong>flight</strong>.
                                </span>
                            </div>
                        </div>

                        <div className='mt-1 px-4'>
                            <div className='d-flex flex-wrap gap-1'>
                                <span>When a user</span>
                                <div>
                                    <Form.Group className="px-2 " controlId="clicks2" style={{ background: 'lightgray' }}>
                                        <Form.Check type="checkbox" label="clicks" />
                                    </Form.Group>
                                </div>
                                <span>or</span>
                                <div>
                                    <Form.Group className="px-2" controlId="converts2" style={{ background: 'lightgray' }}>
                                        <Form.Check type="checkbox" label="converts" />
                                    </Form.Group>
                                </div>
                                <span>
                                    on an ad from this flight <strong>stop showing</strong> them ads from this <strong>advertiser</strong>.
                                </span>
                            </div>
                        </div>

                        <div className='mt-1 px-4'>
                            <div className='d-flex flex-wrap gap-1'>
                                <span>When a user</span>
                                <div>
                                    <Form.Group className="px-2 " controlId="clicks3" style={{ background: 'lightgray' }}>
                                        <Form.Check type="checkbox" label="clicks" />
                                    </Form.Group>
                                </div>
                                <span>or</span>
                                <div>
                                    <Form.Group className="px-2" controlId="converts3" style={{ background: 'lightgray' }}>
                                        <Form.Check type="checkbox" label="converts" />
                                    </Form.Group>
                                </div>
                                <span>
                                    on an ad from this flight <strong>store this flight's categories</strong> as an interest for them.
                                </span>
                            </div>
                        </div>

                        <Form.Group className="px-4 my-3" controlId="formBasicCategories">
                            <Form.Label>Categories</Form.Label>
                            <Form.Control type="text" placeholder="Categories" />
                        </Form.Group>

                    </Accordion.Body>
                </Accordion.Item> */}

                        <Accordion.Item className='mt-4' eventKey="3">
                            <Accordion.Header>Keyword Targeting</Accordion.Header>
                            <Accordion.Body style={{ padding: 0 }}>
                                <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                                    <strong>Keyword targeting is a filter you can apply to a flight or ad so that the flight's ads serve only to placements where those keywords are passed in the ad code or Decision API request.</strong><br />
                                    <span>If no keywords are set, this flight will be eligible to serve regardless of the keywords passed with the request.</span>
                                </Alert>

                                <Form.Group className="px-4 mb-3" controlId="formBasicKeywords">
                                    <Form.Label>Keywords</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px', resize: 'none' }}
                                        value={flightFormData?.Keywords} onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'Keywords': e.target.value
                                            })
                                        }
                                        }
                                    />
                                </Form.Group>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item className='mt-4' eventKey="4">
                            <Accordion.Header>Custom Targeting</Accordion.Header>
                            <Accordion.Body style={{ padding: 0 }}>
                                <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                                    <strong>Custom targeting allows you to set up rules to target certain combinations of key/value pairs.</strong>
                                </Alert>

                                <Form.Group className="px-4 mb-3" controlId="formBasicCustomTargeting">
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px', resize: 'none' }}
                                        value={flightFormData?.CustomTargeting}
                                        onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'CustomTargeting': e.target.value
                                            })
                                        }
                                        }
                                    />
                                </Form.Group>
                            </Accordion.Body>
                        </Accordion.Item>

                        {/* <Accordion.Item className='mt-4' eventKey="5" style={{ zIndex: 0 }}>
                    <Accordion.Header>Frequency Capping</Accordion.Header>
                    <Accordion.Body style={{ padding: 0 }}>
                        <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                            <strong>Cap the number of times the flight can be displayed to any particular person in a particular time period.</strong>
                        </Alert>
                        <div className='mb-3 px-4 d-flex flex-column gap-3'>
                            <Form.Group className="w-100" controlId="Enable frequency capping">
                                <Form.Check type="checkbox" label="Enable frequency capping" />
                            </Form.Group>
                            <Form.Group className="w-100" controlId="This flight should not affect the advertiser/campaign frequency cap">
                                <Form.Check type="checkbox" label="This flight should not affect the advertiser/campaign frequency cap" />
                            </Form.Group>
                        </div>
                    </Accordion.Body>
                </Accordion.Item> */}

                        <Accordion.Item className='mt-4' eventKey="6">
                            <Accordion.Header>Site/Zone Targeting</Accordion.Header>
                            <Accordion.Body style={{ padding: 0 }}>
                                <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                                    <strong>By default your flight will display across all sites and zones in the channel.</strong><br />
                                    <span>You can limit by site, zone, or site and zone here. You can also target by site or zone on specific creatives when you add them.</span>
                                </Alert>

                                <div className='px-4 my-3 d-flex flex-column flex-md-row gap-2'>
                                    <Form.Group className="w-100" controlId="formBasicInclude/Exclude1" ref={(e) => refAttributs.current.SiteZoneTargetingRef = e}>
                                        <Form.Label>Include/Exclude</Form.Label>
                                        <Form.Select value={siteTargetingFormData?.IsExclude} onChange={e => {
                                            setSiteTargetingFormData({
                                                ...siteTargetingFormData,
                                                'IsExclude': e.target.value === "true"
                                            })

                                        }
                                        }>
                                            <option value={false}>Include</option>
                                            <option value={true}>Exclude</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <div className="w-100" style={{ display: 'flex', gap: 5 }}>
                                        <Form.Group className="w-50" controlId="formBasicSite">
                                            <Form.Label>Site</Form.Label>
                                            <Form.Select value={siteTargetingFormData?.SiteId || ''} onChange={e => {
                                                setSiteTargetingFormData({
                                                    ...siteTargetingFormData,
                                                    'SiteId': parseInt(e.target.value),
                                                    'Title': e.target.options[e.target.selectedIndex].text
                                                })
                                                setIdSiteSelected(parseInt(e.target.value))
                                            }
                                            }>
                                                <option>-- Choose --</option>
                                                {dataSites.map((val, key) => {
                                                    return (
                                                        <option key={key} value={val.Id} title={val.title}>{val.Title}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="w-50" controlId="formBasicSite">
                                            <Form.Label>Zone</Form.Label>
                                            <Form.Select value={siteTargetingFormData?.ZoneId} onChange={e => {
                                                setSiteTargetingFormData({
                                                    ...siteTargetingFormData,
                                                    'ZoneId': parseInt(e.target.value),
                                                    'Name': e.target.options[e.target.selectedIndex].text
                                                })
                                            }
                                            }>
                                                <option>-- Choose --</option>
                                                {siteTargetingFormData?.SiteId
                                                    ? dataZones.filter(e => e.SiteId === siteTargetingFormData?.SiteId).map((val, key) => {
                                                        return (
                                                            <option key={key} value={val.Id} title={val.Name}>{val.Name}</option>
                                                        )
                                                    })
                                                    :
                                                    <option disabled>Select a site to see zones</option>
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <Form.Group className="w-25" controlId="formBasicZone">
                                        <Form.Label></Form.Label>
                                        <div className='w-100 p-0 mt-2' style={{ display: 'flex', alignItems: "center", gap: 20 }}>
                                            {/* <Form.Control type="text" placeholder="Zone" /> */}
                                            <Button className='w-100' onClick={() => {
                                                // setDataCreativeMaps([...dataCreativeMaps, { IsActive: true, Creative: creativeMapsFormData }])
                                                addSiteTargeting()
                                            }} disabled={!siteTargetingFormData?.SiteId || manageSiteTargetingLoading}>
                                                {manageSiteTargetingLoading ?
                                                    <>
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        Loading...
                                                    </>
                                                    : <span style={{fontWeight:900}}>Add</span>}
                                            </Button>
                                            {/* <Button
                                                className='p-0'
                                                style={{
                                                    display: 'flex',
                                                    alignItems: "center",
                                                    gap: 7,
                                                    background: "none",
                                                    border: "none",
                                                    color: 'gray'
                                                }}
                                                onClick={addSiteTargeting}
                                                disabled={!siteTargetingFormData?.SiteId}
                                            >
                                                <span class="mdi mdi-plus-circle" style={{ color: 'black', fontSize: 22 }}></span>

                                                <span style={{ color: 'black' }}>Add</span>
                                            </Button> */}
                                            {/* <span class="mdi mdi-reload" style={{ fontSize: 22, cursor: 'pointer' }}></span> */}

                                        </div>
                                    </Form.Group>
                                </div>

                                {errors?.SiteZoneTargeting &&
                                    <div className='mx-4'>
                                        <Alert color="danger">
                                            {errors?.SiteZoneTargeting}
                                        </Alert>
                                    </div>}

                                {dataSiteZoneTargeting?.length !== 0 && <Table className='container'>
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Site</th>
                                            <th>Zone</th>
                                            <th>Option</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataSiteZoneTargeting?.map((val, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{val.IsExclude ? 'Exclude' : 'Include'}</td>
                                                    <td>{val.Title ? val.Title : dataSites.find(e => e.Id === val.SiteId)?.Title}</td>
                                                    <td>{val.Name ? val.Name : dataZones.find(e => e.Id === val.ZoneId)?.Name}</td>
                                                    <td>
                                                        {val.Id && val?.Id === deleteSiteZoneTargetingselected
                                                            ?
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                            :
                                                            <span class="mdi mdi-close" style={{ cursor: 'pointer' }} onClick={() => {
                                                                if (parseInt(Id) !== 0) {
                                                                    deleteSiteZoneTargeting(val.Id)
                                                                    setDeleteSiteZoneTargetingselected(val.Id)
                                                                } else {
                                                                    const data = dataSiteZoneTargeting.filter((_, index) => index !== key)
                                                                    setDataSiteZoneTargeting(data)
                                                                    setFlightFormData({
                                                                        ...flightFormData,
                                                                        'SiteZoneTargeting': data
                                                                    })
                                                                }
                                                            }}></span>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>}

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item className='mt-4' eventKey="7">
                            <Accordion.Header>Geo Targeting</Accordion.Header>
                            <Accordion.Body style={{ padding: 0 }}>
                                <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                                    <strong>Target users based on their current location.</strong><br />
                                </Alert>

                                <div className='px-4 my-3 d-flex flex-column flex-md-row gap-2'>
                                    <Form.Group className="w-100" controlId="formBasicInclude/Exclude2">
                                        <Form.Label>Include/Exclude</Form.Label>
                                        <Form.Select ref={(e) => refAttributs.current.GeoTargetingRef = e} value={geoTargetingFormData?.IsExclude} onChange={e => {
                                            setGeoTargetingFormData({
                                                ...geoTargetingFormData,
                                                'IsExclude': e.target.value === "true"
                                            })

                                        }
                                        }>
                                            <option value={false}>Include</option>
                                            <option value={true}>Exclude</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicCountry">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Select ref={(e) => refAttributs.current.geoTargetingRef = e} value={geoTargetingFormData?.CountryCode || ''} onChange={e => {
                                            setGeoTargetingFormData({
                                                ...geoTargetingFormData,
                                                'CountryCode': e.target.value,
                                                'CountryName': e.target.options[e.target.selectedIndex].text
                                            })

                                        }
                                        }>
                                            <option value=''>-- Choose --</option>
                                            {DataCountries.filter(country =>
                                                dataSiteZoneTargeting?.some(zone => zone.IsExclude === false && zone.ZoneId === dataZones.find(v=>v.Name === 'DAB+')?.Id) ?
                                                    (country.value === 'FR' || country.value === 'CH') : country
                                            ).map((val, key) => (
                                                <option key={key} value={val.value} title={val.title}>{val.title}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicRegion">
                                        <Form.Label>Region</Form.Label>
                                        <Form.Select ref={(e) => refAttributs.current.geoTargetingRef = e} value={geoTargetingFormData?.Region} onChange={e => {
                                            setGeoTargetingFormData({
                                                ...geoTargetingFormData,
                                                'Region': e.target.value,
                                                'RegionName': e.target.options[e.target.selectedIndex].text
                                            })

                                        }
                                        }>
                                            {geoTargetingFormData?.CountryCode && !loadingDataRegions ?
                                                <>
                                                    <option value=''>All</option>
                                                    {dataRegions
                                                        .filter(e => geoTargetingFormData?.MetroCode ? dataMetros.find(e => e.Code === geoTargetingFormData?.MetroCode)?.regionCodes.includes(e.Code) : true)
                                                        .map((val, key) => {
                                                            return (
                                                                <option key={key} value={val.Code} title={val.Name}>{val.Code}- {val.Name}</option>
                                                            )
                                                        })}
                                                </>
                                                :
                                                <option disabled>Select a country to see regions</option>}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicMetro/DMA_Code">
                                        <Form.Label>Metro/DMA code</Form.Label>
                                        <div className='w-100 p-0' style={{ display: 'flex', alignItems: "center", gap: 15 }}>
                                            <Form.Select ref={(e) => refAttributs.current.geoTargetingRef = e} value={geoTargetingFormData?.MetroCode} onChange={e => {
                                                setGeoTargetingFormData({
                                                    ...geoTargetingFormData,
                                                    'MetroCode': e.target.value,
                                                    'MetroName': e.target.options[e.target.selectedIndex].text
                                                })
                                            }
                                            }>
                                                {geoTargetingFormData?.CountryCode && !loadingDataRegions ?
                                                    <>
                                                        <option value=''>All</option>
                                                        {dataMetros
                                                            .filter(e => geoTargetingFormData?.Region ? e.regionCodes.includes(geoTargetingFormData?.Region) : true)
                                                            .map((val, key) => {
                                                                return (
                                                                    <option key={key} value={val.Code} title={val.Name}>{val.Code}: {val.Name}</option>
                                                                )
                                                            })}
                                                    </>
                                                    :
                                                    <option disabled>Select a region to see metro/DMA code</option>}
                                            </Form.Select>
                                            <Button className='w-50' onClick={addGeoTargeting} disabled={!geoTargetingFormData?.CountryCode || manageGeoTargetingLoading}>
                                                {manageGeoTargetingLoading ?
                                                    <div style={{
                                                        display:'flex',
                                                        justifyContent:'center',
                                                        alignItems:'center'
                                                    }}>
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        <span>Adding...</span>                                                        
                                                    </div>
                                                    : <span style={{fontWeight:900}}>Add</span>}
                                            </Button>
                                            {/* <Button
                                                className='p-0'
                                                style={{
                                                    display: 'flex',
                                                    alignItems: "center",
                                                    gap: 7,
                                                    background: "none",
                                                    border: "none",
                                                    color: 'gray'
                                                }}
                                                onClick={addGeoTargeting}
                                                disabled={!geoTargetingFormData?.CountryCode}
                                            >
                                                <span class="mdi mdi-plus-circle" style={{ color: 'black', fontSize: 22 }}></span>
                                                <span style={{ color: 'black' }}>Add</span>
                                            </Button> */}
                                            {/* <span class="mdi mdi-reload" style={{ fontSize: 22, cursor: 'pointer' }}></span> */}

                                        </div>
                                    </Form.Group>
                                </div>

                                {errors?.GeoTargeting &&
                                    <div className='mx-4'>
                                        <Alert color="danger">
                                            {errors?.GeoTargeting}
                                        </Alert>
                                    </div>}

                                {dataGeoTargeting?.length !== 0 && <Table className='container' responsive>
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Country</th>
                                            <th>Region</th>
                                            <th>Metro</th>
                                            <th>Option</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataGeoTargeting?.map((val, key) => {

                                            return (
                                                <tr key={key}>
                                                    <td>{val.IsExclude ? 'Exclude' : 'Include'}</td>
                                                    <td>{val.CountryName ? `${val.CountryCode}-${val.CountryName}` : `${val.CountryCode}-${DataCountries.find(e => e.value === val.CountryCode)?.title}`}</td>
                                                    <td>{val.RegionName ? `${val.Region}` : val.Region ? `${val.Region}` : '-'}</td>
                                                    <td>{val.MetroCode ? `${val.MetroCode}` : val.MetroCode ? `${val.MetroCode}` : '-'}</td>
                                                    {/* <td>{val.RegionName }</td> */}
                                                    <td>
                                                        {val.LocationId && val.LocationId === deleteGeoTargetingselected
                                                            ?
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                            :
                                                            <span class="mdi mdi-close" style={{ cursor: 'pointer' }} onClick={() => {
                                                                if (parseInt(Id) !== 0) {
                                                                    deleteGeoTargeting(val.LocationId)
                                                                    setDeleteGeoTargetingselected(val.LocationId)
                                                                } else {
                                                                    const data = dataGeoTargeting.filter((_, index) => index !== key)
                                                                    setDataGeoTargeting(data)
                                                                    setFlightFormData({
                                                                        ...flightFormData,
                                                                        'GeoTargeting': data
                                                                    })
                                                                }
                                                            }}></span>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>}

                            </Accordion.Body>
                        </Accordion.Item>

                        {/* <Accordion.Item className='mt-4' eventKey="8">
                    <Accordion.Header>Day Parting</Accordion.Header>
                    <Accordion.Body style={{ padding: 0 }}>
                        <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                            <strong>You can configure specific times on specific days for this flight to serve. Times are in the time zone of this flight which is GMT. You can change this above.</strong><br />
                            <span>Blank start time or end time will default to midnight (i.e. "00:00"). Leaving all days unchecked will select the entire week.</span>
                        </Alert>

                        <div className='px-4 my-3 d-flex flex-column flex-md-row gap-2'>
                            <Form.Group className="w-100" controlId="formBasicBeginTime">
                                <Form.Label>Begin Time</Form.Label>
                                <Form.Control type="text" placeholder="Begin Time" />
                                <Form.Label style={{ fontWeight: 100, marginLeft: 2 }}>HH:mm (e.g. "09:00")</Form.Label>
                            </Form.Group>
                            <Form.Group className="w-100" controlId="formBasicEndTime">
                                <Form.Label>End Time</Form.Label>
                                <Form.Control type="text" placeholder="End Time" />
                                <Form.Label style={{ fontWeight: 100, marginLeft: 2 }}>HH:mm (e.g. "21:00")</Form.Label>
                            </Form.Group>
                            <div className='w-100 d-flex gap-2'>
                                <Form.Group className="" controlId="Sun">
                                    <Form.Label>Sun</Form.Label>
                                    <Form.Check className='mt-1' type="checkbox" />
                                </Form.Group>
                                <Form.Group className="" controlId="Mon">
                                    <Form.Label>Mon</Form.Label>
                                    <Form.Check className='mt-1' type="checkbox" />
                                </Form.Group>
                                <Form.Group className="" controlId="Tue">
                                    <Form.Label>Tue</Form.Label>
                                    <Form.Check className='mt-1' type="checkbox" />
                                </Form.Group>
                                <Form.Group className="" controlId="Wed">
                                    <Form.Label>Wed</Form.Label>
                                    <Form.Check className='mt-1' type="checkbox" />
                                </Form.Group>
                                <Form.Group className="" controlId="Thu">
                                    <Form.Label>Thu</Form.Label>
                                    <Form.Check className='mt-1' type="checkbox" />
                                </Form.Group>
                                <Form.Group className="" controlId="Fri">
                                    <Form.Label>Fri</Form.Label>
                                    <Form.Check className='mt-1' type="checkbox" />
                                </Form.Group>
                                <Form.Group className="" controlId="Sat">
                                    <Form.Label>Sat</Form.Label>
                                    <Form.Check className='mt-1' type="checkbox" />
                                </Form.Group>

                                <div className='w-100' style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button className='p-0' style={{ display: 'flex', alignItems: "center", gap: 7, background: "none", border: "none", color: 'gray' }}>
                                        <span class="mdi mdi-plus-circle" style={{ fontSize: 22 }}></span>
                                        <span>Add</span>
                                    </Button>
                                </div>
                            </div>

                        </div>

                    </Accordion.Body>
                </Accordion.Item> */}

                        {/* <Accordion.Item className='mt-4' eventKey="9">
                    <Accordion.Header>Distribution</Accordion.Header>
                    <Accordion.Body style={{ padding: 0 }}>
                        <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                            <strong>
                                To use these settings, creatives in this flight must be served through the same ad request. These settings can't be used together on one flight.
                            </strong><br />
                        </Alert>

                        <div className='px-4'>
                            <Form.Group className="w-100 d-flex align-items-start gap-2" controlId="t">
                                <Form.Check className='mt-1' type="checkbox" />
                                <Form.Label className='d-flex flex-column align-items-start'>
                                    <div className='d-flex align-items-center'>
                                        <span>Don't allow duplicates with other creatives from this</span>
                                        <Form.Group className="" controlId="formBasicRegion">
                                            <Form.Control type="text" placeholder="Region" />
                                        </Form.Group>

                                    </div>
                                    <span style={{ fontWeight: 100 }}>Prevents creatives from showing multiple times per page view</span>
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className="w-100 d-flex align-items-start gap-2" controlId="p">
                                <Form.Check className='' type="checkbox" />
                                <Form.Label className='d-flex flex-column align-items-start'>
                                    <span>Always serve creatives in this flight together</span>
                                    <span style={{ fontWeight: 100 }}>This makes creatives in this flight Companion Ads. What are Companion Ads?</span>
                                </Form.Label>
                            </Form.Group>
                        </div>

                    </Accordion.Body>
                </Accordion.Item> */}

                    </Accordion>



                    <div
                        className=' w-100 d-flex align-items-center pt-4 mt-4'
                        style={{ background: 'whitesmoke', borderTop: '1px solid lightgray', }}
                    >
                        <Button onClick={handleShowConfirmFlight} disabled={loadingAdd}>
                            {loadingAdd ?
                                <>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Loading...
                                </>
                                : <span>Save</span>}
                        </Button>
                    </div>




                    {/* Boite de dialogue */}

                    <Modal
                        size="lg"
                        show={confirmFlight}
                        onHide={handleCloseConfirmFlight}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm {parseInt(Id) > 0 ? 'edit' : "add"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                            style={{
                                maxHeight: '80vh',
                                overflow: 'auto'
                            }}>

                            <div>Are you sure you want to {parseInt(Id) > 0 ? 'edit' : 'add'} this flight?</div>



                            <div className='mt-3 d-flex gap-2' style={{ height: 50, display: 'flex', alignItems: 'center' }}>
                                <Button onClick={AddEditFlight} disabled={loadingAdd}>
                                    {loadingAdd ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Loading...
                                        </>
                                        : <span>Save</span>}
                                </Button>
                                <Button variant="outline-warning" onClick={handleCloseConfirmFlight} disabled={loadingAdd}>
                                    Cancel
                                </Button>
                            </div>

                        </Modal.Body>
                        {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddAdModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCloseAddAdModal}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
                    </Modal>


                    {/* Modal to Add new Ad */}

                    <Modal
                        size="lg"
                        show={addAdModal}
                        onHide={handleCloseAddAdModal}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Create Ad</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                            style={{
                                maxHeight: '80vh',
                                overflow: 'auto'
                            }}>

                            <Accordion defaultActiveKey={['0']} alwaysOpen>
                                <Accordion.Item className='mt-4' eventKey="0">
                                    <Accordion.Header>Creative Details</Accordion.Header>
                                    <Accordion.Body>
                                        <Form>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Format</Form.Label>
                                                <Form.Select value={format} onChange={e => setFormat(e.target.value)}>
                                                    <option value='Image'>Image (.gif, .jpg, .png, .svg, .webp)</option>
                                                    <option value='Video'>Video</option>
                                                </Form.Select>
                                            </Form.Group>

                                            {format === "Image" &&
                                                <>
                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Form.Label>Upload</Form.Label>
                                                        <Form.Control
                                                            type="file"
                                                            placeholder="Campaign Name"
                                                            accept=''
                                                            ref={(e) => refAttributsAd.current.FieldNameRef = e}
                                                            onChange={e => {
                                                                const file = e.target.files[0];
                                                                setCreativeMapsFormData({
                                                                    ...creativeMapsFormData,
                                                                    FieldName: file,
                                                                    ImageLink: ''
                                                                });
                                                                // setDataFiles([...dataFiles,{
                                                                //     FieldName:file
                                                                // }])
                                                            }}
                                                        />
                                                        {errorsAd?.FieldName && <Form.Text className="text-danger">{errorsAd?.FieldName}</Form.Text>}
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>File URL</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="File URL"
                                                            value={creativeMapsFormData?.ImageLink}
                                                            ref={(e) => refAttributsAd.current.ImageLinkRef = e}
                                                            onChange={e => {
                                                                setCreativeMapsFormData({
                                                                    ...creativeMapsFormData,
                                                                    ImageLink: e.target.value
                                                                })
                                                            }} />
                                                        {errorsAd?.ImageLink && <Form.Text className="text-danger">{errorsAd?.ImageLink}</Form.Text>}
                                                    </Form.Group>
                                                </>
                                            }

                                            {
                                                format === "Video" &&
                                                <>
                                                    {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Templates</Form.Label>
                                                        <Form.Select value={templateSelected} onChange={e => setTemplateSelected(e.target.value)}>
                                                            {dataTemplates.map((v, k) => {
                                                                return (
                                                                    <option key={k} value={v.Id}>{v.Name}</option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </Form.Group> */}

                                                    <div style={{
                                                        display: 'flex',
                                                        gap: 4
                                                    }}>
                                                        <Form.Group className="mb-3 col-11" controlId="formBasicPassword">
                                                            <Form.Label>Upload</Form.Label>
                                                            <Form.Control
                                                                ref={(e) => refAttributsAd.current.ctPlayback_URLRef = e}
                                                                type="file"
                                                                placeholder="Upload video"
                                                                accept=''
                                                                onChange={e => {
                                                                    const file = e.target.files[0];
                                                                    setAssetFormData({
                                                                        ...assetFormData,
                                                                        video: file
                                                                    })
                                                                }}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="col-2" style={{ marginTop: 28 }} controlId="formBasicPassword">

                                                            <Button onClick={addAsset} disabled={uploadVideoLoading}>
                                                                {uploadVideoLoading ?
                                                                    <>
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="grow"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                        Loading...
                                                                    </>
                                                                    : <span>Add</span>}
                                                            </Button>
                                                        </Form.Group>
                                                    </div>

                                                    {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Video Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Video name"
                                                            onChange={e => {
                                                                setAssetFormData({
                                                                    ...assetFormData,
                                                                    videoName: e.target.value
                                                                })
                                                            }} />
                                                    </Form.Group> */}



                                                    <Form.Group className="mb-3" controlId="Playback_URL">
                                                        <Form.Label>Playback URL</Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            placeholder="Playback URL."
                                                            value={linkAsset}
                                                            disabled={true}
                                                        />
                                                        {errorsAd?.ctPlayback_URL && <Form.Text className="text-danger">{errorsAd?.ctPlayback_URL}</Form.Text>}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="duration">
                                                        <Form.Label>Duration</Form.Label>
                                                        <Form.Control
                                                            ref={(e) => refAttributsAd.current.ctdurationRef = e}
                                                            type="text"
                                                            placeholder="Duration."
                                                            // value={v.Name === "Playback_URL" ? linkAsset : null}
                                                            // disabled={v.Name === "Playback_URL" && true}
                                                            onChange={e => {
                                                                setCreativeMapsFormData({
                                                                    ...creativeMapsFormData,
                                                                    TemplateValues: {
                                                                        ...creativeMapsFormData?.TemplateValues,
                                                                        ctduration: e.target.value
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                        {errorsAd?.ctduration && <Form.Text className="text-danger">{errorsAd?.ctduration}</Form.Text>}
                                                    </Form.Group>

                                                    {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Form.Label>Playback URL</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Playback URL"
                                                            value={creativeMapsFormData?.TemplateValues?.ctPlayback_URL || ''}
                                                            onChange={e => {
                                                                setCreativeMapsFormData({
                                                                    ...creativeMapsFormData,
                                                                    TemplateValues: {
                                                                        ...creativeMapsFormData?.TemplateValues,
                                                                        'ctPlayback_URL': `${e.target.value}`
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                        {errorsAd?.FieldName && <Form.Text className="text-danger">{errorsAd?.FieldName}</Form.Text>}
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Duration</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Duration"
                                                            value={creativeMapsFormData?.TemplateValues?.ctduration || ''}
                                                            onChange={e => {
                                                                setCreativeMapsFormData({
                                                                    ...creativeMapsFormData,
                                                                    TemplateValues: {
                                                                        ...creativeMapsFormData?.TemplateValues,
                                                                        'ctduration': `${e.target.value}`
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                        {errorsAd?.ImageLink && <Form.Text className="text-danger">{errorsAd?.ImageLink}</Form.Text>}
                                                    </Form.Group> */}
                                                </>

                                            }

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Friendly Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Friendly Name"
                                                    value={creativeMapsFormData.Title}
                                                    ref={(e) => refAttributsAd.current.TitleRef = e}
                                                    onChange={e => {
                                                        setCreativeMapsFormData({
                                                            ...creativeMapsFormData,
                                                            Title: e.target.value
                                                        })
                                                    }} />
                                                {errorsAd?.Title && <Form.Text className="text-danger">{errorsAd?.Title}</Form.Text>}
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Ad Size</Form.Label>
                                                <Form.Select
                                                    ref={(e) => refAttributsAd.current.AdTypeIdRef = e}
                                                    value={creativeMapsFormData?.AdTypeId}
                                                    onChange={e => {
                                                        setCreativeMapsFormData({
                                                            ...creativeMapsFormData,
                                                            AdTypeId: e.target.value
                                                        })

                                                    }
                                                    }>
                                                    <option value=''>-- Choose --</option>
                                                    {dataAdTypes.map((val, key) => {
                                                        return (
                                                            <option key={key} value={val.Id}>{val.Name} - {val.Width}x{val.Height}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                                {errorsAd?.AdTypeId && <Form.Text className="text-danger">{errorsAd?.AdTypeId}</Form.Text>}
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Click URL - separate URLs with newlines for multiple landing pages</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ height: '100px', resize: 'none' }}
                                                    ref={(e) => refAttributsAd.current.UrlRef = e}
                                                    value={creativeMapsFormData?.Url}
                                                    onChange={e => {
                                                        setCreativeMapsFormData({
                                                            ...creativeMapsFormData,
                                                            Url: e.target.value
                                                        })

                                                    }
                                                    }
                                                />
                                                {errorsAd?.Url && <Form.Text className="text-danger">{errorsAd?.Url}</Form.Text>}
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Alt Text(optional)</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Alt Text"
                                                    value={creativeMapsFormData?.Alt}
                                                    onChange={e => {
                                                        setCreativeMapsFormData({
                                                            ...creativeMapsFormData,
                                                            Alt: e.target.value
                                                        })
                                                    }} />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Metadata(optional)</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ height: '100px', resize: 'none' }}
                                                    value={creativeMapsFormData?.Metadata}
                                                    ref={(e) => refAttributsAd.current.MetadataRef = e}
                                                    onChange={e => {
                                                        setCreativeMapsFormData({
                                                            ...creativeMapsFormData,
                                                            Metadata: e.target.value
                                                        })
                                                    }}
                                                    placeholder='Requires JSON format (http://json.org)'
                                                />
                                                {errorsAd?.Metadata && <Form.Text className="text-danger">{errorsAd?.Metadata}</Form.Text>}
                                            </Form.Group>

                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* <Accordion.Item className='mt-4' eventKey="1">
                            <Accordion.Header>Delivery Settings</Accordion.Header>
                            <Accordion.Body>

                                <Form.Group className="mb-3" controlId="overrideFlight">
                                    <Form.Check type="checkbox" label="Override Flight Start/End Dates" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Distribution</Form.Label>
                                    <Form.Control type="text" placeholder="Distribution" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="frequencyCapping">
                                    <Form.Check type="checkbox" label="Frequency Capping" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="frequencyCap">
                                    <Form.Check type="checkbox" label="This ad should not affect the advertiser/campaign/flight frequency cap" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Bid Price(optional)</Form.Label>
                                    <Form.Control type="text" placeholder="Bid Price" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Site Specific(optional)</Form.Label>
                                    <Form.Control type="text" placeholder="Site Specific" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Zone Specific(optional)</Form.Label>
                                    <Form.Control type="text" placeholder="Zone Specific" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Custom Targeting(optional)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px', resize: 'none' }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Keyword Targeting(optional)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px', resize: 'none' }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Ad-Specific Metadata(optional)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px', resize: 'none' }}
                                    />
                                </Form.Group>

                            </Accordion.Body>
                        </Accordion.Item> */}
                            </Accordion>



                            <div className='mt-3 d-flex gap-2' style={{ height: 50, display: 'flex', alignItems: 'center' }}>
                                <Button style={{ background: '#f18bf1', border: 'none' }} onClick={() => {
                                    // setDataCreativeMaps([...dataCreativeMaps, { IsActive: true, Creative: creativeMapsFormData }])
                                    createNewAd()
                                }} disabled={manageAdLoading}>
                                    {manageAdLoading ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Loading...
                                        </>
                                        : <span>Save</span>}
                                </Button>
                                <Button variant="outline-warning" onClick={handleCloseAddAdModal} disabled={manageAdLoading}>
                                    Cancel
                                </Button>
                            </div>

                        </Modal.Body>
                        {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddAdModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCloseAddAdModal}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
                    </Modal>


                    {/* Modal to Update Ad */}

                    <Modal
                        size="lg"
                        show={updateAddModal}
                        onHide={handleCloseUpdateAddModal}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Update Ad</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                            style={{
                                maxHeight: '80vh',
                                overflow: 'auto'
                            }}>

                            <Accordion defaultActiveKey={['0']} alwaysOpen>
                                <Accordion.Item className='mt-4' eventKey="0">
                                    <Accordion.Header>Creative Details</Accordion.Header>
                                    <Accordion.Body>
                                        <Form>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Format</Form.Label>
                                                <Form.Select value={format} onChange={e => setFormat(e.target.value)}>
                                                    <option value='Image'>Image (.gif, .jpg, .png, .svg, .webp)</option>
                                                    <option value='Video'>Video</option>
                                                </Form.Select>
                                            </Form.Group>

                                            {format === "Image" &&
                                                <>
                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Form.Label>Upload</Form.Label>
                                                        <Form.Control
                                                            type="file"
                                                            placeholder="Campaign Name"
                                                            accept=''
                                                            // value={fieldName}
                                                            ref={(e) => refAttributsAd.current.FieldNameRef = e}
                                                            onChange={e => {
                                                                setFieldName(e.target.files[0])
                                                                setImageLink('')
                                                            }}
                                                        />
                                                        {errorsAd?.FieldName && <Form.Text className="text-danger">{errorsAd?.FieldName}</Form.Text>}
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>File URL</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="File URL"
                                                            value={imageLink}
                                                            ref={(e) => refAttributsAd.current.ImageLinkRef = e}
                                                            onChange={e => {
                                                                setImageLink(e.target.value)
                                                            }} />
                                                        {errorsAd?.ImageLink && <Form.Text className="text-danger">{errorsAd?.ImageLink}</Form.Text>}
                                                    </Form.Group>
                                                </>}


                                            {
                                                format === "Video" &&
                                                <>

                                                    <div style={{
                                                        display: 'flex',
                                                        gap: 4
                                                    }}>
                                                        <Form.Group className="mb-3 col-11" controlId="formBasicPassword">
                                                            <Form.Label>Upload</Form.Label>
                                                            <Form.Control
                                                                type="file"
                                                                placeholder="Upload video"
                                                                // value={imageLink}
                                                                accept=''
                                                                onChange={e => {
                                                                    const file = e.target.files[0];
                                                                    setAssetFormData({
                                                                        ...assetFormData,
                                                                        video: file
                                                                    })
                                                                }}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="col-2" style={{ marginTop: 28 }} controlId="formBasicPassword">

                                                            <Button onClick={addAsset} disabled={uploadVideoLoading}>
                                                                {uploadVideoLoading ?
                                                                    <>
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="grow"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                        Loading...
                                                                    </>
                                                                    : <span>Add</span>}
                                                            </Button>
                                                        </Form.Group>
                                                    </div>


                                                    <Form.Group className="mb-3" controlId="Playback_URL">
                                                        <Form.Label>Playback URL</Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            placeholder="Playback URL."
                                                            value={linkAsset}
                                                            disabled={true}
                                                        />
                                                        {errorsAd?.ctPlayback_URL && <Form.Text className="text-danger">{errorsAd?.ctPlayback_URL}</Form.Text>}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="duration">
                                                        <Form.Label>Duration</Form.Label>
                                                        <Form.Control
                                                            ref={(e) => refAttributsAd.current.ctdurationRef = e}
                                                            type="text"
                                                            placeholder="Duration."
                                                            value={ctDuration}
                                                            // disabled={v.Name === "Playback_URL" && true}
                                                            onChange={e => {
                                                                // setCreativeMapsFormData({
                                                                //     ...creativeMapsFormData,
                                                                //     TemplateValues: {
                                                                //         ...creativeMapsFormData?.TemplateValues,
                                                                //         ctduration: e.target.value
                                                                //     },
                                                                // });
                                                                setCtDuration(e.target.value)
                                                            }}
                                                        />
                                                        {errorsAd?.ctduration && <Form.Text className="text-danger">{errorsAd?.ctduration}</Form.Text>}
                                                    </Form.Group>

                                                    {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Form.Label>Playback URL</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Playback URL"
                                                            value={creativeMapsFormData?.TemplateValues?.ctPlayback_URL || ''}
                                                            onChange={e => {
                                                                setCreativeMapsFormData({
                                                                    ...creativeMapsFormData,
                                                                    TemplateValues: {
                                                                        ...creativeMapsFormData?.TemplateValues,
                                                                        'ctPlayback_URL': `${e.target.value}`
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                        {errorsAd?.FieldName && <Form.Text className="text-danger">{errorsAd?.FieldName}</Form.Text>}
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Duration</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Duration"
                                                            value={creativeMapsFormData?.TemplateValues?.ctduration || ''}
                                                            onChange={e => {
                                                                setCreativeMapsFormData({
                                                                    ...creativeMapsFormData,
                                                                    TemplateValues: {
                                                                        ...creativeMapsFormData?.TemplateValues,
                                                                        'ctduration': `${e.target.value}`
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                        {errorsAd?.ImageLink && <Form.Text className="text-danger">{errorsAd?.ImageLink}</Form.Text>}
                                                    </Form.Group> */}
                                                </>

                                            }

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Friendly Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Friendly Name"
                                                    value={title}
                                                    ref={(e) => refAttributsAd.current.TitleRef = e}
                                                    onChange={e => {
                                                        setTitle(e.target.value)
                                                    }} />
                                                {errorsAd?.Title && <Form.Text className="text-danger">{errorsAd?.Title}</Form.Text>}
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Ad Size</Form.Label>
                                                <Form.Select
                                                    ref={(e) => refAttributsAd.current.AdTypeIdRef = e}
                                                    value={adTypeId}
                                                    onChange={e => {
                                                        setAdTypeId(e.target.value)
                                                    }
                                                    }>
                                                    <option value=''>-- Choose --</option>
                                                    {dataAdTypes.map((val, key) => {
                                                        return (
                                                            <option key={key} value={val.Id}>{val.Width}x{val.Height} - {val.Name}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                                {errorsAd?.AdTypeId && <Form.Text className="text-danger">{errorsAd?.AdTypeId}</Form.Text>}
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Click URL - separate URLs with newlines for multiple landing pages</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ height: '100px', resize: 'none' }}
                                                    ref={(e) => refAttributsAd.current.UrlRef = e}
                                                    value={url}
                                                    onChange={e => {
                                                        setUrl(e.target.value)
                                                    }
                                                    }
                                                />
                                                {errorsAd?.Url && <Form.Text className="text-danger">{errorsAd?.Url}</Form.Text>}
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Alt Text(optional)</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={altText}
                                                    placeholder="Alt Text"
                                                    onChange={e => setAltText(e.target.value)} />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Metadata(optional)</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ height: '100px', resize: 'none' }}
                                                    value={metadata}
                                                    ref={(e) => refAttributsAd.current.MetadataRef = e}
                                                    placeholder='Requires JSON format (http://json.org)'
                                                    onChange={e => setMetadata(e.target.value)}
                                                />
                                                {errorsAd?.Metadata && <Form.Text className="text-danger">{errorsAd?.Metadata}</Form.Text>}
                                            </Form.Group>

                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* 
                                <Accordion.Item className='mt-4' eventKey="1">
                            <Accordion.Header>Delivery Settings</Accordion.Header>
                            <Accordion.Body>

                                <Form.Group className="mb-3" controlId="overrideFlight">
                                    <Form.Check type="checkbox" label="Override Flight Start/End Dates" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Distribution</Form.Label>
                                    <Form.Control type="text" placeholder="Distribution" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="frequencyCapping">
                                    <Form.Check type="checkbox" label="Frequency Capping" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="frequencyCap">
                                    <Form.Check type="checkbox" label="This ad should not affect the advertiser/campaign/flight frequency cap" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Bid Price(optional)</Form.Label>
                                    <Form.Control type="text" placeholder="Bid Price" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Site Specific(optional)</Form.Label>
                                    <Form.Control type="text" placeholder="Site Specific" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Zone Specific(optional)</Form.Label>
                                    <Form.Control type="text" placeholder="Zone Specific" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Custom Targeting(optional)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px', resize: 'none' }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Keyword Targeting(optional)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px', resize: 'none' }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Ad-Specific Metadata(optional)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px', resize: 'none' }}
                                    />
                                </Form.Group>

                            </Accordion.Body>
                        </Accordion.Item> 
                        */}
                            </Accordion>



                            <div className='mt-3 d-flex gap-2' style={{ height: 50, display: 'flex', alignItems: 'center' }}>
                                <Button style={{ background: '#f18bf1', border: 'none' }} onClick={() => {
                                    // setDataCreativeMaps([...dataCreativeMaps, { IsActive: true, Creative: creativeMapsFormData }])
                                    updateAd()
                                }} disabled={manageAdLoading}>
                                    {manageAdLoading ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Loading...
                                        </>
                                        : <span>Update</span>}
                                </Button>
                                <Button variant="outline-warning" onClick={handleCloseUpdateAddModal} disabled={manageAdLoading}>
                                    Cancel
                                </Button>
                            </div>

                        </Modal.Body>
                        {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddAdModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCloseAddAdModal}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
                    </Modal>

                    {/* Modal Delete Campaign */}

                    <Modal show={deleteAdModal} onHide={handleCloseDeleteAdModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Ad</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>

                                <span>Are you sure you want to delete this Ad?</span>

                                <div className='d-flex gap-2 mt-3'>
                                    <Button style={{ background: '#f18bf1', border: 'none' }} onClick={deletedAd} disabled={loadingAds}>
                                        {loadingAds ?
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Loading...
                                            </>
                                            : <span>Delete</span>}
                                    </Button>
                                    <Button variant="outline-warning" onClick={handleCloseDeleteAdModal} disabled={loadingAds}>
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                        </Modal.Body>
                    </Modal>


                </div>
                :
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10
                }}>
                    <span>Loading</span>
                    <Spinner animation="grow" size='sm' />
                </div>

            }
        </>
    )
}

export default AddFlight