// Payment.js
import React from 'react';
import { Container, Card, CardBody } from 'react-bootstrap';
import CheckoutForm from './CheckoutForm';
import { useTranslation } from 'react-i18next';

const Payment = () => {
  const {t}=useTranslation()
  return (
    <Container>
      <Card className="container col-12 col-lg-6 ">
        <h4 className="card-title px-sm-4 my-1 my-sm-4">{t("Paiement")}</h4>
        <CardBody className="container  px-sm-4 py-2">
          <CheckoutForm />
        </CardBody>
      </Card>
    </Container>
  );
};

export default Payment;
