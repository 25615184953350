import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button } from 'react-bootstrap';
import axiosClient from '../../../axios-client';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    // Ensure PaymentElement is properly initialized
    const { error: submitError } = await elements.submit();

    if (submitError) {
      console.log('[submitError]', submitError);
      setIsProcessing(false);
      return;
    }

    try {
      // Fetch the clientSecret here
      const { data } = await axiosClient.post('/create-payment-intent', {
        amount: 1099, 
        currency: 'usd',
        clientDetails: {
            name: 'John Doe',
            email: 'john.doe@example.com'
          }
      });

      const { clientSecret } = data;

      // Confirm the payment with the fetched clientSecret
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin
        },
        clientSecret
      });

      if (error) {
        console.log('[error]', error);
      } else {
        console.log('Payment successful');
      }
    } catch (error) {
      console.error('Error fetching client secret:', error);
    }

    setIsProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <Button className='my-3' type="submit" disabled={isProcessing}>
          {isProcessing ? 'Traitement...' : 'Payer'}
        </Button>
      </div>
    </form>
  );
};

export default CheckoutForm;
