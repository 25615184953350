import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useNavigation } from 'react-router-dom';
import { connect } from 'react-redux';
import { adminRoutes } from './admin/routes/allRoutes';
import Authmiddleware from './admin/routes/middleware/Authmiddleware';
import VerticalLayout from './admin/components/VerticalLayout';
import './assets/scss/theme.scss';

import axiosClient from './axios-client';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import Dash from './admin/pages/dash_';
import Login from './admin/pages/Auth/Login';
import ResetPassword from './admin/pages/Auth/ResetPassword';
import ResetPwdPage from './admin/pages/Auth/ResetPwdPage';
import Register from './admin/pages/Auth/Register';
import Profile from './admin/pages/User-Settings/Profile';
import i18n from './i18n';
// import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { pdfjs } from 'react-pdf';
import Payment from './admin/pages/payment/Payment';
import Dashboard from './admin/pages/dashboard/Dashboard';
import Advertisers from './admin/pages/Admin/Gestion_advertisers/Advertisers';
import Add_advertiser from './admin/pages/Admin/Gestion_advertisers/Add_advertiser';
import { useStateContext } from './context/ContextProvider';
import Edit_advertiser from './admin/pages/Admin/Gestion_advertisers/Edit_advertiser';
import AdsDisplay from './admin/pages/Ads-display/AdsDisplay';
import Mes_Demandes from './admin/pages/User-demandes/Mes_Demandes';
import Add_demande from './admin/pages/User-demandes/Add_demande';
import Edit_demande from './admin/pages/User-demandes/Edit_demande';
import AllDemandes from './admin/pages/Admin/Gestion_demandes/AllDemandes';
import Response from './admin/pages/Admin/Gestion_demandes/Response';
import Campaign_Agancy from './admin/pages/Admin/Display_advertiser_data/Campaign';
import Flight_Agancy from './admin/pages/Admin/Display_advertiser_data/Flight';
import Ads from './admin/pages/Admin/Display_advertiser_data/Ads';
import StatsAdvertiser from './admin/pages/Admin/Gestion_advertisers/StatsAdvertiser';

const styles = `
body{
  overflow-y: auto;
}
`
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const App = () => {
  function getLayout() {
    return VerticalLayout;
  }

  const Layout = getLayout();

  // const navigation = useNavigation()

  const [backendAvailable, setBackendAvailable] = useState(false);

  const notifyError = (text) => toast.error(text, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    transition: Bounce,
  });


  useEffect(() => {
    const checkBackendAvailability = async () => {
      try {
        await axiosClient.get('/');
        setBackendAvailable(true);
      } catch (error) {
        setBackendAvailable(false);
        // Pass specific error message based on error type
        if (error.response) {
          // Request made and server responded with a status code
          notifyError(`Error ${error.response.status}: ${error.response.data.message}`);
        } else if (error.request) {
          // Request made but no response received
          // notifyError("No response received from server. Please try again later.");
        } else {
          // Something else happened in making the request
          notifyError("Error occurred. Please try again later.");
        }
      }
    };

    checkBackendAvailability();

    // Clean up any resources if needed
    return () => {
      // Clean-up function (optional)
    };
  }, []);

  const { user } = useStateContext()

  return (

    <React.Fragment>
      < style> {styles}</ style>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition="bounce"
      />

      <Router>
        <Routes>
          <Route
            path="/"
            element={<Authmiddleware layout={Layout} component={Dashboard} />}
          />
          {adminRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={<Authmiddleware layout={Layout} component={route.component} />}
            />
          ))}
          <Route
            path="/profile"
            element={<Authmiddleware layout={Layout} component={Profile} />}
          />
          <Route
            path="/payment"
            element={<Authmiddleware layout={Layout} component={Payment} />}
          />
          <Route
            path="/ads"
            element={<Authmiddleware layout={Layout} component={AdsDisplay} />}
          />
          <Route
            path="/requests"
            element={<Authmiddleware layout={Layout} component={AllDemandes} />}
          />
          <Route
            path="/my-requests"
            element={<Authmiddleware layout={Layout} component={Mes_Demandes} />}
          />
          <Route
            path="/send-request"
            element={<Authmiddleware layout={Layout} component={Add_demande} />}
          />
          <Route
            path="/edit-request"
            element={<Authmiddleware layout={Layout} component={Edit_demande} />}
          />
          <Route
            path="/order_details"
            element={<Authmiddleware layout={Layout} component={Response} />}
          />
          <Route
            path="/advertiser-campaigns/:id/:userId"
            element={<Authmiddleware layout={Layout} component={Campaign_Agancy} />}
          />
          <Route
            path="/advertiser-flights/:id"
            element={<Authmiddleware layout={Layout} component={Flight_Agancy} />}
          />
          <Route
            path="/flight-ads/:id"
            element={<Authmiddleware layout={Layout} component={Ads} />}
          />
          <Route
            path="/stats-advertiser"
            element={<Authmiddleware layout={Layout} component={StatsAdvertiser} />}
          />
          {/* --------------------------------Advertisers------------------------------ */}
          {user.role === "Admin" &&
            <>
              <Route
                path="/advertisers"
                element={<Authmiddleware layout={Layout} component={Advertisers} />}
              />
              <Route
                path="/add-advertiser"
                element={<Authmiddleware layout={Layout} component={Add_advertiser} />}
              />
              <Route
                path="/edit-advertiser"
                element={<Authmiddleware layout={Layout} component={Edit_advertiser} />}
              />
            </>
          }

          {/* --------------------------------Advertisers------------------------------ */}
          <Route
            path="/home"
            element={<Dash />}
          />
          <Route
            path="/login"
            element={<Login />}
          />
          <Route
            path="/register"
            element={<Register />}
          />
          <Route
            path="/reset_password"
            element={<ResetPassword />}
          />
          <Route
            path="/reinitialisation_de_motdepasse/:userId/:token/:expires"
            element={<ResetPwdPage />}
          />

          
        </Routes>
      </Router>
    </React.Fragment>
  );
};



export default App
