import React from 'react';
import './custom.css';

import { useStateContext } from '../../../context/ContextProvider';
import DashAdmin from './DashAdmin';
import DashAdvertiser from './DashAdvertiser';



function Dashboard() {
    
    const {user}= useStateContext()
    return (
        <div>
           {user?.role === "Admin" ?
           <DashAdmin/>
            :
            <DashAdvertiser/>}
        </div>


    )
}

export default Dashboard