import {
    FETCH_PAIEMENTS_REQUEST,
    FETCH_PAIEMENTS_SUCCESS,
    FETCH_PAIEMENTS_FAILURE,
    ADD_PAIEMENT_REQUEST,
    ADD_PAIEMENT_SUCCESS,
    ADD_PAIEMENT_FAILURE,
    UPDATE_PAIEMENT_REQUEST,
    UPDATE_PAIEMENT_SUCCESS,
    UPDATE_PAIEMENT_FAILURE,
    DELETE_PAIEMENT_REQUEST,
    DELETE_PAIEMENT_SUCCESS,
    DELETE_PAIEMENT_FAILURE
} from '../actions/PaiementActions';

const initialState = {
    paiements: [],
    loading: false,
    error: null
};

export const paiementReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PAIEMENTS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_PAIEMENTS_SUCCESS:
            return { ...state, loading: false, paiements: action.payload };
        case FETCH_PAIEMENTS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ADD_PAIEMENT_REQUEST:
            return { ...state, loading: true, error: null };
        case ADD_PAIEMENT_SUCCESS:
            return { ...state, loading: false };
        case ADD_PAIEMENT_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case UPDATE_PAIEMENT_REQUEST:
            return { ...state, loading: true, error: null };
        case UPDATE_PAIEMENT_SUCCESS:
            return { ...state, loading: false };
        case UPDATE_PAIEMENT_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case DELETE_PAIEMENT_REQUEST:
            return { ...state, loading: true, error: null };
        case DELETE_PAIEMENT_SUCCESS:
            return { ...state, loading: false };
        case DELETE_PAIEMENT_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};
