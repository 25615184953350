import React, { useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { link } from '../../../../axios-client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert, Table } from 'reactstrap';
import moment from 'moment-timezone';
import { TablePagination } from '@mui/material';
import { useStateContext } from '../../../../context/ContextProvider';
import { useTranslation } from 'react-i18next';


function Ads() {
    const { id } = useParams();
    const location = useLocation()
    const [addAdModal, setAddAdModal] = useState(false);
    const { t } = useTranslation()
    const handleCloseAddAdModal = () => {
        setAddAdModal(false)
        setCreativeMapsFormData({
            IsActive: true,
            Body: '',
        })
        setTemplateSelected(null)
        setFormat('Image')
    };
    const handleShowAddAdModal = () => setAddAdModal(true);

    const [updateAddModal, setUpdateAddModal] = useState(false);

    const handleCloseUpdateAddModal = () => {
        setUpdateAddModal(false)
        setCreativeMapsFormData({
            IsActive: true,
            Body: '',
        })
        setKeyAdSelected()
        setTemplateSelected(null)
        setFormat('Image')
    };
    const handleShowUpdateAddModal = () => {
        setUpdateAddModal(true)
    };

    const [deleteAdModal, setDeleteAdModal] = useState(false)

    const handleCloseDeleteAdModal = () => {
        setDeleteAdModal(false)
        setKeyAdSelected()
    };
    const handleShowDeleteAdModal = () => {
        setDeleteAdModal(true)
    };

    const [keyAdSelected, setKeyAdSelected] = useState()
    const [idAdSelected, setIdAdSelected] = useState()

    const [loading, setLoading] = useState()
    const [loadingAds, setLoadingAds] = useState()

    // Variables
    const [flightFormData, setFlightFormData] = useState({
        CampaignId: location.state.idCampaign,
        // PriorityId:'',
        RateType: 2,
        StartDate: new Date().toISOString().slice(0, 16),
        EndDate: null,
        EndDateISO: null,
        IsTrackingConversions: false,
        GoalType: 2,
        CapType: null,
        Price: null
    })

    const [dataPriorities, setDataPriorities] = useState([])

    const [errors, setErrors] = useState({})
    const refAttributs = useRef({
        NameRef: null,
        PriorityIdRef: null,
        ImpressionsRef: null,
        EndDateRef: null,
        DailyCapAmountRef: null,
        LifetimeCapAmountRef: null,
        SiteZoneTargetingRef: null,
        GeoTargetingRef: null,
    })

    const Id = id ? id : 0


    const [siteTargetingFormData, setSiteTargetingFormData] = useState({
        IsExclude: true,
    })
    const [dataSiteZoneTargeting, setDataSiteZoneTargeting] = useState([])

    const [geoTargetingFormData, setGeoTargetingFormData] = useState({
        IsExclude: true,
    })
    const [dataGeoTargeting, setDataGeoTargeting] = useState([])

    const [format, setFormat] = useState("Image")

    const [dataTemplates, setDataTemplates] = useState([])
    const [templateSelected, setTemplateSelected] = useState(null)
    useEffect(() => {
        if (format === 'Video') {
            try {
                axios.get(`${link}/get-templates/`).then(res => {
                    console.log('TEMPLATES : ', res.data);
                    setDataTemplates(res.data.items)
                })
            } catch (error) {
                console.log('ERROR : ', error);

            }
        }
    }, [format])

    const [creativeMapsFormData, setCreativeMapsFormData] = useState({
        IsActive: true,
        Body: '',
    })
    const [dataCreativeMaps, setDataCreativeMaps] = useState([])
    const [dataFiles, setDataFiles] = useState([])


    useEffect(() => {
        console.log('Updated FormData:', flightFormData);
        console.log('Updated FormData: siteTargetingFormData ', siteTargetingFormData);
        console.log('Updated FormData: dataSiteZoneTargeting ', dataSiteZoneTargeting);
        console.log('Updated FormData: dataGeoTargeting ', dataGeoTargeting);
        console.log('Updated FormData: creativeMapsFormData ', creativeMapsFormData);
        console.log('Updated FormData: dataCreativeMaps ', dataCreativeMaps);
        console.log('Updated FormData: dataFiles ', dataFiles);
    }, [flightFormData, siteTargetingFormData, dataSiteZoneTargeting, creativeMapsFormData, dataCreativeMaps]); // This will log the updated state whenever it changes

    ////////////////////////////////the size of the table//////////////////////////////
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);

    const handlePageChange = (event, value) => {
        setPage(value);
    };
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    const getDataFlight = async () => {
        setLoading(true)
        if (parseInt(Id) !== 0) {
            try {
                await axios.get(`${link}/get-flight-with-id/${Id}/`).then(res => {
                    setFlightFormData(res.data)
                })
            } catch (error) {
                setLoading(false)
            }
        }
    }

    const getDataAds = async () => {
        setLoadingAds(true)
        try {
            await axios.get(`${link}/get-Ads/${Id}/?page=${page + 1}&page_size=${pageSize}`).then(res => {
                console.log('get-Ads : ', res.data.results);
                setDataCreativeMaps(res.data.results || [])
                setTotalPages(Math.ceil(res.data.count / pageSize));
                setLoadingAds(false)
            })
        } catch (error) {
            console.log('ERROR : ', error);
            setLoadingAds(false)
        }
    }

    const getDataSiteZoneFlight = async () => {
        // setLoading(true)
        if (parseInt(Id) !== 0) {
            try {
                await axios.get(`${link}/get-site-flight/${Id}/`).then(res => {
                    setDataSiteZoneTargeting(res.data || [])
                })
            } catch (error) {
                setLoading(false)
            }
        }
    }

    const getDataGeoTargetingFlight = async () => {
        // setLoading(true)
        if (parseInt(Id) !== 0) {
            try {
                await axios.get(`${link}/get-geoTargeting-flight/${Id}/`).then(res => {
                    setDataGeoTargeting(res.data || [])
                })
            } catch (error) {
                setLoading(false)
            }
        }
    }

    const getPriorities = async () => {
        try {
            await axios.get(`${link}/get-priorities/`).then(res => {
                console.log('DATA PRIORITIES : ', res.data.items);
                setDataPriorities(res.data.items)
            })
        } catch (error) {
            setLoading(false)

        }
    }
    useEffect(() => {
        if (parseInt(Id) !== 0) {
            getDataFlight()
            getDataAds()
            getDataSiteZoneFlight()
            getDataGeoTargetingFlight()
        }
        getPriorities()
        setLoading(false)
    }, [page, pageSize])

    //Change Date By TimeZone


    const getDataSites = async () => {
        try {
            const res = await axios.get(`${link}/get-sites/`);
            console.log('DATATATATA :', res.data.items);
            return res.data.items; // Return the data directly
        } catch (error) {
            console.log('ERROR :', error);
            throw error; // Optional: re-throw the error if you want to handle it elsewhere
        }
    };

    const getDataZones = async (id) => {
        try {
            const res = await axios.get(`${link}/get-zones/`);
            console.log('DATATATATA :', res.data.items);
            return res.data; // Return the data directly

        } catch (error) {
            console.log('ERROR :', error);
            throw error;

        }
    }

    const [dataSites, setDataSites] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getDataSites();
                setDataSites(data)
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures this runs once when the component mounts


    const [dataZones, setDataZones] = useState([])
    const [idSiteSelected, setIdSiteSelected] = useState()
    useEffect(() => {
        const fetchDataZones = async () => {
            try {
                const data = await getDataZones(idSiteSelected)
                setDataZones(data)
            } catch (error) {
                console.log('Error fetching zones data :', error);
            }
        }
        fetchDataZones()
    }, [])

    const getDataRegions = async (country) => {
        try {
            const res = await axios.get(`${link}/get-regions/${country}/`);
            console.log('DATATATATA Regions :', res.data.Regions);
            console.log('DATATATATA AllMetros :', res.data.AllMetros);
            return {
                regions: res.data.Regions,
                metros: res.data.AllMetros
            };
        } catch (error) {
            console.log('ERROR :', error);
            throw error; // Optional: re-throw the error if you want to handle it elsewhere
        }
    };

    const [dataRegions, setDataRegions] = useState([])
    const [dataMetros, setDataMetros] = useState([])
    const [loadingDataRegions, setLoadingDataRegion] = useState()

    useEffect(() => {
        if (geoTargetingFormData?.CountryCode) {
            const fetchData = async () => {
                setLoadingDataRegion(true)
                try {
                    const { regions, metros } = await getDataRegions(geoTargetingFormData?.CountryCode)
                    console.log('DATAAAAAAAAAAAAA REGION : ', regions);
                    console.log('DATAAAAAAAAAAAAA Metros : ', metros);

                    setDataRegions(regions || [])
                    setDataMetros(metros || [])
                    setLoadingDataRegion(false)
                } catch (error) {
                    console.log('Error fetching data:', error);
                    setLoadingDataRegion(false)
                }
            };

            fetchData();
        }
    }, [geoTargetingFormData?.CountryCode]);


    // AD Management
    const getDataAdTypes = async () => {
        try {
            const res = await axios.get(`${link}/get-adTypes/`);
            console.log('DATATATATA :', res.data.items);
            return res.data.items; // Return the data directly
        } catch (error) {
            console.log('ERROR :', error);
            throw error; // Optional: re-throw the error if you want to handle it elsewhere
        }
    }


    const [dataAdTypes, setDataAdTypes] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getDataAdTypes();
                console.log('DATA AD TYPEs : ', data);

                setDataAdTypes(data)
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures this runs once when the component mounts


    //Variable 






    useEffect(() => {

    }, [dataCreativeMaps])

    return (
        <>
            {!loadingAds ?
                <div>
                    <div className="d-flex justify-content-between align-items-center mt-3 ">
                    <h3>{t("Liste des ads")}</h3>
                   
                </div>
                    {dataCreativeMaps.length !== 0 ?
                        <>



                            <Table className='mt-4' style={{ width: '100%', borderColor: 'lightgray' }} responsive>
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        {/* <th scope="col">Active</th> */}
                                        <th scope="col">{t("Nom")}</th>
                                        <th scope="col">Ad ID</th>
                                        <th scope="col">{t("Status")}</th>
                                        <th scope="col">Impressions</th>
                                        <th scope="col">{t("Clics")}</th>
                                        <th scope="col">{t("Clics uniques")}</th>
                                        <th scope="col">Conversions</th>
                                        <th scope="col">{t("Revenue")}</th>
                                        <th scope="col">CTR %</th>
                                        <th scope="col">GMV</th>
                                        <th scope="col">Roas</th>
                                        <th scope="col">{t("Dernière mise à jour")}</th>
                                        {/* <th scope="col">Options</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataCreativeMaps.map((val, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    <img src={val.Creative?.ImageLink} style={{
                                                        height: 'auto',
                                                        maxWidth: 40,
                                                        width: 'auto !important',
                                                    }} />
                                                </td>
                                                {/* <td className='d-flex justify-content-center align-self-center'>
                                                    <div className="form-check form-switch">
                                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" style={{ cursor: 'pointer' }} checked={val.IsActive} onClick={(e) => {
                                                            updateIsActive(val.Id)
                                                            // setDataCreativeMaps(dataCreativeMaps.map((v, k) => {
                                                            //     if (v.Id === val.Id) {
                                                            //         if (val.Id !== undefined && !IdsCreativeUpdated.includes(val.Id)) {
                                                            //             setIdsCreativeUpdated([...IdsCreativeUpdated, val.Id]);
                                                            //         }
                                                            //         return ({
                                                            //             ...v,
                                                            //             IsActive: !val.IsActive
                                                            //         })
                                                            //     }
                                                            //     return v
                                                            // }))
                                                        }}
                                                        />
                                                    </div>
                                                </td> */}
                                                <td>
                                                    <span>{val.Creative?.Title}</span>

                                                    {/* <a className='d-flex flex-column' onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}>
                                    <span>Second campaign</span>
                                </a> */}
                                                </td>
                                                <td>{val.Id}</td>
                                                <td>
                                                    status
                                                </td>
                                                <td>0</td>
                                                <td>
                                                    0
                                                </td>
                                                <td>
                                                    -
                                                </td>
                                                <td>
                                                    0
                                                </td>
                                                <td>$0.00</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>
                                                    {new Date(val.LastModified).toLocaleDateString(t("fr-FR"))}
                                                </td>

                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </>
                        :
                        <div>
                            {t("Aucun élément trouvé.")}

                        </div>}

                    {/* PAGINATION */}
                    {dataCreativeMaps.length !== 0 &&
                        <Row>
                            <Col lg={12} className="d-flex justify-content-end">

                                <TablePagination
                                    component="div"
                                    count={totalPages * pageSize} // Total number of items
                                    page={page}
                                    onPageChange={handlePageChange}
                                    rowsPerPage={pageSize}
                                    onRowsPerPageChange={handlePageSizeChange}

                                    rowsPerPageOptions={[10, 20, 30, 40, 50]} // Define page size options
                                    showFirstButton={true}
                                    showLastButton={true}
                                    sx={{
                                        ".MuiTablePagination-displayedRows": {
                                            margin: 0
                                        },
                                        ".MuiTablePagination-selectLabel": {
                                            display: 'none',
                                            margin: 0
                                        },
                                        ".MuiSelect-select": {
                                            backgroundColor: "#ededed"
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    }
                </div>
                :
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10
                }}>
                    <span>Loading</span>
                    <Spinner animation="grow" size='sm' />
                </div>

            }
        </>
    )
}

export default Ads