import React, { useState, useEffect } from 'react';
import { Button, Form, Alert, Card, CardBody, Image, Row, Col, Spinner, Modal, CardHeader } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosClient, { link } from '../../../../axios-client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Resizer from 'react-image-file-resizer';
import { IoMdClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { RiChatNewLine } from 'react-icons/ri';
import { LuFileEdit } from 'react-icons/lu';
import { useStateContext } from '../../../../context/ContextProvider';

function Response() {
    const { t } = useTranslation();
    const {user}=useStateContext()
    const navigate = useNavigate();
    const location = useLocation();
    const { idDemande } = location.state || {};
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => (
        setShowModal(false),
        setSelectedImageList([]),
        setSelectedVideoList([]),
        setDescription('')
    );
    const [showModalEdit, setShowModalEdit] = useState(false);

    const handleOpenModalEdit = () => setShowModalEdit(true);
    const handleCloseModalEdit = () => (
        setShowModalEdit(false),
        setSelectedImageList([]),
        setSelectedVideoList([]),
        setDescription('')
    );
    const [titleOrder, setTitleOrder] = useState('');
    const [title, setTitle] = useState('');
    const [descriptionOrder, setDescriptionOrder] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState(false);
    const [agancyName, setAgancyName] = useState('');
    const [agancyId, setAgancyId] = useState('');
    const [imageListOrder, setImageListOrder] = useState([]);
    const [imageList, setImageList] = useState([]);
    const [videoListOrder, setVideoListOrder] = useState([]);
    const [videoList, setVideoList] = useState([]);
    const [selectedImageList, setSelectedImageList] = useState([]);
    const [selectedVideoList, setSelectedVideoList] = useState([]);
    const [selectedImageListOrder, setSelectedImageListOrder] = useState([]);
    const [selectedVideoListOrder, setSelectedVideoListOrder] = useState([]);

    const [resVideoList, setResVideoList] = useState([]);
    const [resImagesList, setResImagesList] = useState([]);
    const [selectedResVideoList, setSelectedResVideoList] = useState([]);
    const [selectedResImagesList, setSelectedResImagesList] = useState([]);

    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [inputKey, setInputKey] = useState(0);
    const [uploaded, setUploaded] = useState([])

    const [responseData, setResponseData] = useState()

    useEffect(() => {
        axiosClient.get(`/get_ResponseByCmd/${location.state.idRequest}/`)
            .then(response => {
                const data = response.data;
                setResponseData(data)

                const listimg = [];
                const res_img = response.data.images
                for (let index = 0; index < res_img?.length; index++) {
                    listimg.push(res_img[index].image);
                    setSelectedResImagesList(listimg);
                    setResImagesList(listimg);
                }
                const listvideos = [];
                const res_videos = response.data.videos
                for (let index = 0; index < res_videos?.length; index++) {
                    listvideos.push(res_videos[index].video);
                    setSelectedResVideoList(listvideos);
                    setResVideoList(listvideos);
                }
            })
            .catch(error => {
                console.log("no response yet");

            });
        axiosClient.get(`/get_RequestById/${location.state.idRequest}/`)
            .then(response => {
                const data = response.data;
                console.log("hmmmm", data);

                setTitleOrder(data.object);
                setDescriptionOrder(data.description);
                setStatus(data.etat);
                setAgancyName(data.advertiser_title)
                setAgancyId(data.advertiser_id)

                const listimg = [];
                const services_img = response.data.images
                for (let index = 0; index < services_img?.length; index++) {
                    listimg.push(services_img[index].image);
                    setSelectedImageListOrder(listimg);
                    setImageListOrder(listimg);
                }
                const listvideos = [];
                const services_videos = response.data.videos
                for (let index = 0; index < services_videos?.length; index++) {
                    listvideos.push(services_videos[index].video);
                    setSelectedVideoListOrder(listvideos);
                    setVideoListOrder(listvideos);
                }
            })
            .catch(error => {
                toast.error(t("Erreur lors du chargement de la commande"), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            });

    }, []);
    function update() {
        axiosClient.get(`/get_ResponseByCmd/${location.state.idRequest}/`)
        .then(response => {
            const data = response.data;
            setResponseData(data)

            const listimg = [];
            const res_img = response.data.images
            for (let index = 0; index < res_img?.length; index++) {
                listimg.push(res_img[index].image);
                setSelectedResImagesList(listimg);
                setResImagesList(listimg);
            }
            const listvideos = [];
            const res_videos = response.data.videos
            for (let index = 0; index < res_videos?.length; index++) {
                listvideos.push(res_videos[index].video);
                setSelectedResVideoList(listvideos);
                setResVideoList(listvideos);
            }
        })
        .catch(error => {
            console.log("no response yet");

        });
    axiosClient.get(`/get_RequestById/${location.state.idRequest}/`)
        .then(response => {
            const data = response.data;
            console.log("hmmmm", data);

            setTitleOrder(data.object);
            setDescriptionOrder(data.description);
            setStatus(data.etat);
            setAgancyName(data.advertiser_title)
            setAgancyId(data.advertiser_id)

            const listimg = [];
            const services_img = response.data.images
            for (let index = 0; index < services_img?.length; index++) {
                listimg.push(services_img[index].image);
                setSelectedImageListOrder(listimg);
                setImageListOrder(listimg);
            }
            const listvideos = [];
            const services_videos = response.data.videos
            for (let index = 0; index < services_videos?.length; index++) {
                listvideos.push(services_videos[index].video);
                setSelectedVideoListOrder(listvideos);
                setVideoListOrder(listvideos);
            }
        })
        .catch(error => {
            toast.error(t("Erreur lors du chargement de la commande"), {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        });
    }
    const handleChangeImage = (e) => {
        const images = e.target.files;
        const extensions = ['.png', '.jpg', '.jpeg', '.webp'];

        if (images && images.length > 0) {
            if (selectedImageList.length + images.length > 5) {
                toast.error(t("Vous ne pouvez sélectionner que 5 images au maximum !"), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                setInputKey((prevKey) => prevKey + 1);
                return;
            }

            const selectedImages = Array.from(images);
            
            selectedImages.forEach(async (image) => {
                if (!extensions.includes(image.name.substring(image.name.lastIndexOf(".")))) {

                    toast.error(t("Extension de fichier non valide. Veuillez utiliser des fichiers .png, .jpg, .jpeg ou .webp."), {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    const imageURL = URL.createObjectURL(image);
                    const resizedImage = await new Promise((resolve, reject) => {
                        Resizer.imageFileResizer(
                            image,
                            451,
                            800,
                            'png',
                            100,
                            0,
                            (uri) => resolve(uri),
                            'file'
                        );
                    });
                    setImageList((prevImageList) => [...prevImageList, resizedImage]);
                    setSelectedImageList((prevSelectedImages) => [...prevSelectedImages, imageURL]);
                }
            });

            setInputKey((prevKey) => prevKey + 1);
        }
    };
    
    const handleImageChangeRes = (e) => {
        const images = e.target.files;
        const allowedExtensions = ['.png', '.jpg', '.jpeg', '.webp'];
    
        if (images && images.length > 0) {
            if (selectedResImagesList.length + images.length > 5) {
                toast.error(t("Vous ne pouvez sélectionner que 5 images au maximum !"), {
                    position: "top-center",
                    autoClose: 3000,
                });
                setInputKey(prevKey => prevKey + 1);
                return;
            }
    
            const selectedImages = Array.from(images);
            selectedImages.forEach(async (image) => {
                const extension = image.name.substring(image.name.lastIndexOf(".")).toLowerCase();
                if (!allowedExtensions.includes(extension)) {
                    toast.error(t("Extension de fichier non valide."), {
                        position: "top-center",
                        autoClose: 3000,
                    });
                } else {
                    try {
                        const imageURL = URL.createObjectURL(image);
                        const resizedImage = await new Promise((resolve, reject) => {
                            Resizer.imageFileResizer(
                                image,
                                451,
                                800,
                                'png',
                                100,
                                0,
                                (uri) => resolve(uri),
                                'file'
                            );
                        });
                        setResImagesList(prevImageList => [...prevImageList, resizedImage]);
                        setSelectedResImagesList(prevSelectedImages => [...prevSelectedImages, imageURL]);
                    } catch (error) {
                        toast.error(t("Une erreur s'est produite."), { autoClose: 3000 });
                    }
                }
            });
            setInputKey(prevKey => prevKey + 1);
        }
    };
    
        const handleVideoChangeRes = (e) => {
            const videos = e.target.files;

            if (videos && videos.length > 0) {
                if (selectedResVideoList.length + videos.length > 5) {
                    toast.error(t("Vous ne pouvez sélectionner que 5 vidéos au maximum !"), {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                    setInputKey(prevKey => prevKey + 1);
                    return;
                }

                const selectedVideos = Array.from(videos);
                selectedVideos.forEach((video) => {
                    const videoURL = URL.createObjectURL(video);
                    setResVideoList(prevVideoList => [...prevVideoList, video]);
                    setSelectedResVideoList(prevSelectedVideos => [...prevSelectedVideos, videoURL]);
                });

                setInputKey(prevKey => prevKey + 1);
            }
        };

    const handleVideoChange = (e) => {
        const videos = e.target.files;

        if (videos && videos.length > 0) {
            if (selectedVideoList.length + videos.length > 5) {
                toast.error(t("Vous ne pouvez sélectionner que 5 vidéos au maximum !"), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                setInputKey((prevKey) => prevKey + 1);
                return;
            }

            const selectedVideos = Array.from(videos);

            selectedVideos.forEach((video) => {
                const videoURL = URL.createObjectURL(video);
                setVideoList((prevVideoList) => [...prevVideoList, video]);
                setSelectedVideoList((prevSelectedVideos) => [...prevSelectedVideos, videoURL]);
            });

            setInputKey((prevKey) => prevKey + 1);
        }
    };
    const handleRemoveImage = (index) => {
        const newSelectedImageList = [...selectedImageList];
        newSelectedImageList.splice(index, 1);

        setSelectedImageList(newSelectedImageList);
    };

    const handleRemoveVideo = (index) => {
        const newSelectedVideoList = [...selectedVideoList];
        newSelectedVideoList.splice(index, 1);

        setSelectedVideoList(newSelectedVideoList);
    };
    const addImages = (data) => {
        const formDataArray = imageList.map((image) => {
            const formData = new FormData();
            formData.append('admin_response', data.id);
            formData.append('image', image);
            return formData;
        });

        Promise.all(formDataArray.map((formData, index) => {
            let progressBar = 0;

            return axiosClient.post("/response-images/", formData, {
                onUploadProgress: (data) => {
                    progressBar = Math.round((data.loaded / data.total) * 100);
                    setUploaded({
                        item: index,
                        uploaded,
                        progressBar,
                    });
                },
            })
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.error('Error uploading image:', error.response?.data || error.message);
                    throw error;
                });

        }))
            .then((responses) => {
                const newImages = responses.map(response => ({
                    id: response.id,
                    demande: data.id,
                    image: response.image,
                }));

                data.images = newImages;
                console.log('List image inside loop: ', data);
                // navigate(-1);
                update()
                handleCloseModal()
            })
            .catch((error) => {
                console.error('Error uploading images:', error.response?.data || error.message);
            });
    };

    const addVideos = (data) => {
        const formDataArray = videoList.map((video) => {
            const formData = new FormData();
            formData.append('admin_response', data.id);
            formData.append('video', video);
            return formData;
        });

        Promise.all(formDataArray.map((formData, index) => {
            return axiosClient.post("/response-videos/", formData)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.error('Error uploading video:', error.response?.data || error.message);
                    throw error;
                });
        }))
            .then((responses) => {
                const newVideos = responses.map(response => ({
                    id: response.id,
                    demande: data.id,
                    video: response.video,
                }));

                data.videos = newVideos;
                console.log('List video inside loop: ', data);
            })
            .catch((error) => {
                console.error('Error uploading videos:', error.response?.data || error.message);
            });
    };
    const onSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append('demande', location.state.idRequest);
        formData.append('response_text', description);

        setMessage('');

        axiosClient.post('/responses/', formData)
            .then((response) => {
                addImages(response.data);
                addVideos(response.data);

            })
            .catch((err) => {
                const response = err.response;
                setMessage(response.data);
            });
    };
    const handleEdit = () => {
        const formData = new FormData();
        if (description) formData.append('response_text', description);
        try {
            axiosClient.patch(`/responses/${responseData?.id}/`, formData).then((res) => {
                console.log('res data patch, ', res.data);
                // dispatch(setAjoutArticle(res.data))
            });
        } catch (error) {
            console.error('Error fetching boosting value:', error);
        }
        
        axiosClient.patch(`/responses/${responseData?.id}/`, formData)
            .then(() => {

                // Handle Images
                axiosClient.get(`/response-images/?search=${responseData?.id}`)
                    .then((res) => {
                        const existingImages = res.data.results;

                        const deleteImagesList = existingImages.filter((e) => !resImagesList.includes(e.image));
                        console.log("Data image:", deleteImagesList);
                        console.log("Image list:", resImagesList);

                        // Delete images not present in the updated resImagesList
                        deleteImagesList.forEach(image => {
                            axiosClient.delete(`/response-images/${image.id}/`);
                        });

                        // Upload new images
                        console.log("res images",resImagesList);
                        
                        const newImagesToAdd = resImagesList.filter((image) => typeof image !== "string");

                        console.log('Neeeeeeeeeeeew images : ',newImagesToAdd);
                        

                        const imageUploadPromises = newImagesToAdd.map(async(newImage) => {
                            console.log(newImage);

                            const imageData = new FormData();
                            imageData.append("admin_response", responseData?.id);
                            imageData.append("image", newImage);
                            return await axiosClient.post(`/response-images/`, imageData);
                        });
                        Promise.all(imageUploadPromises)
                            .then(() => {

                                // Fetch updated service data with images
                                return axiosClient.get(`/responses/${responseData?.id}/`);
                            })
                            .then((res) => {
                                const { images, ...myNewData } = res.data;
                                // navigate('/my-requests');
                                handleCloseModalEdit()
                                update()
                            })
                            .catch((error) => {
                                console.error('Error uploading new images:', error);
                            });
                        // // Handle Videos
                        axiosClient.get(`/response-videos/?search=${responseData?.id}`)
                            .then((res) => {
                                const existingVideos = res.data.results;
                                const deleteVideosList = existingVideos.filter((e) => !resVideoList.includes(e.video));
                                console.log("Data video:", deleteVideosList);
                                console.log("Video list:", resVideoList);

                                // Delete videos not present in the updated resVideoList
                                deleteVideosList.forEach(video => {
                                    axiosClient.delete(`/response-videos/${video.id}/`);
                                });

                                // Upload new videos
                                const newVideosToAdd = resVideoList.filter((video) => typeof video !== "string");
                                const videoUploadPromises = newVideosToAdd.map((newVideo) => {
                                    const videoData = new FormData();
                                    videoData.append("admin_response", responseData?.id);
                                    videoData.append("video", newVideo);
                                    return axiosClient.post(`/response-videos/`, videoData);
                                });

                                // Wait for all uploads to complete
                                Promise.all([...imageUploadPromises, ...videoUploadPromises])
                                    .then(() => {
                                        // Fetch updated demande data with images and videos
                                        return axiosClient.get(`/responses/${responseData?.id}/`);
                                    })
                                    .then((res) => {
                                        console.log('Updated order with images and videos:', res.data);
                                        // navigate('/my-requests');
                                        handleCloseModalEdit()
                                        update()
                                    })
                                    .catch((error) => {
                                        console.error('Error uploading new images or videos:', error);
                                    });
                            })
                            .catch((error) => {
                                console.error('Error fetching existing videos:', error);
                            });
                    })
                    .catch((error) => {
                        console.error('Error fetching existing images:', error);
                    });
            })
            .catch((error) => {
                console.error('Error updating order:', error);
            });
    };

    useEffect(()=>{        
        console.log('IMAAAAaaages: ',selectedResImagesList);
    },[selectedResImagesList])

    return (
        <React.Fragment>
            <ToastContainer />
            <div className="container my-4">
                <h3 className="fw-bold">Detail de commande</h3>
            </div>

            <Card className=" container py-3" style={{ background: "#f6f6f6", borderRadius: "10px" }}>


                <CardBody className="container px-2 px-sm-5 py-2">
                    <div className="rounded">
                        <div className="agency-details d-flex justify-content-between align-items-center">
                            <div>
                                <h5 className="mb-2">
                                    <strong>Agence:</strong> {agancyName} - {agancyId}
                                </h5>
                                <h5 className="mb-2">
                                    <strong>Objet:</strong> {titleOrder}
                                </h5>
                                <h5>
                                    <strong>Description:</strong> {descriptionOrder}
                                </h5>
                            </div>

                            <div>
                                {(!responseData && user.role ==="Admin")  && <Button
                                    variant="primary"
                                    style={{
                                        borderRadius: '50%',
                                        width: '50px',
                                        height: '50px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    onClick={handleOpenModal}
                                >
                                    <RiChatNewLine size={24} color="#fff" /> 
                                </Button>}

                            </div>
                        </div>
                    </div>
                </CardBody>

            </Card>
            {selectedImageListOrder.length > 0 &&
                <div className="container my-4 px-2 px-sm-5">
                    <h4 className="mb-3">Images</h4>

                    <Row className="g-4 ">
                        {selectedImageListOrder.map((url, index) => (
                            <Col
                                key={index}
                                xs={6} sm={6} md={4} lg={3} // These define the grid size per screen size
                                className="image-container position-relative"
                            >
                                <Image
                                    src={link + url}
                                    alt={`image-${index}`}
                                    className="img-fluid rounded shadow-sm"
                                    // style={{ height: "200px", objectFit: "cover" }}
                                    style={{
                                        borderRadius: "5px",
                                        width: "100%",
                                        height: "200px",
                                        objectFit: "cover"
                                    }}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>}
            {selectedVideoListOrder.length > 0 &&
                <div className="container my-4 px-2 px-sm-5">
                    <h4 className="mb-3">Videos</h4>
                    <Row className="g-4">
                        {selectedVideoListOrder.map((url, index) => (
                            <Col
                                key={index}
                                xs={12} sm={6} md={6} lg={4}
                                className="video-container position-relative"
                            >
                                <video
                                    src={link + url}
                                    controls
                                    className="rounded shadow-sm w-100"
                                    style={{ backgroundColor: "#0000000f", height: "auto" }}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>}

            {responseData &&
                <Card className=" container py-3 my-5" style={{ background: "#f6f6f6", borderRadius: "10px" }}>
                    <CardBody className="container px-2 px-sm-5 py-2">
                        <div className="d-flex justify-content-between align-items-center my-2 mb-4">
                            <h3 className="fw-bold">La réponse</h3>
                            {user.role ==="Admin"&& 
                             <Button
                             variant="primary"
                             style={{
                                 borderRadius: '50%',
                                 width: '50px',
                                 height: '50px',
                                 display: 'flex',
                                 justifyContent: 'center',
                                 alignItems: 'center'
                             }}
                             onClick={handleOpenModalEdit}
                         >
                             <LuFileEdit size={24} color="#fff" /> {/* Icon */}
                         </Button>
                            }
                           
                        </div>
                        <div>
                            <h5 className="mb-2">
                                <strong>Contenu:</strong> {responseData.response_text}
                            </h5>
                            {resImagesList.length > 0 &&
                                <div className=" my-4 ">
                                    <h4 className="mb-3">Images</h4>

                                    <Row className="g-4 ">
                                        {resImagesList.map((url, index) => (
                                            <Col
                                                key={index}
                                                xs={6} sm={6} md={4} lg={3} // These define the grid size per screen size
                                                className="image-container position-relative"
                                            >
                                                <Image
                                                    src={url}
                                                    alt={`image-${index}`}
                                                    className="img-fluid rounded shadow-sm"
                                                    // style={{ height: "200px", objectFit: "cover" }}
                                                    style={{
                                                        borderRadius: "5px",
                                                        width: "100%",
                                                        height: "200px",
                                                        objectFit: "cover"
                                                    }}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </div>}
                            {resVideoList.length > 0 &&
                                <div className="my-4 ">
                                    <h4 className="mb-3">Videos</h4>
                                    <Row className="g-4">
                                        {resVideoList.map((url, index) => (
                                            <Col
                                                key={index}
                                                xs={12} sm={6} md={6} lg={4}
                                                className="video-container position-relative"
                                            >
                                                <video
                                                    src={url}
                                                    controls
                                                    className="rounded shadow-sm w-100"
                                                    style={{ backgroundColor: "#0000000f", height: "auto" }}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </div>}

                        </div>

                    </CardBody>
                </Card>}


            <Modal size='lg' show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Respond</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container " >
                        {message && <Alert variant="danger">{message}</Alert>}
                        <Form style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>



                            <Form.Group controlId="description">
                                <Form.Label>{t("Contenu")}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder={t("Entrez le contenu ici")}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className='' controlId="imageUpload">
                                <Form.Label>{t("Images")}</Form.Label>
                                <Form.Control
                                    key={inputKey}
                                    type="file"
                                    accept=".jpg,.jpeg,.png,.webp"
                                    multiple
                                    onChange={handleChangeImage}
                                // style={{ padding: 10 }}
                                />
                            </Form.Group>

                            <div className="container my-2">
                                <Row className="g-3">
                                    {selectedImageList?.map((url, index) => (
                                        <Col
                                            key={index}
                                            xs={6} sm={6} md={4} lg={3} // These define the grid size per screen size
                                            className="image-container"
                                            style={{ position: "relative" }}

                                        >
                                            <Image
                                                src={url}
                                                alt={`image-${index}`}
                                                style={{
                                                    borderRadius: "5px",
                                                    width: "100%",
                                                    height: "150px",
                                                    objectFit: "cover"
                                                }}
                                            />
                                            <button
                                                className="btn btn-danger"
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    position: "absolute",
                                                    top: "8px",
                                                    right: "12px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: 0,
                                                    borderRadius: "50%",
                                                    fontSize: "12px",
                                                }}
                                                onClick={() => handleRemoveImage(index)}
                                            >
                                                <IoMdClose size={16} />
                                            </button>
                                        </Col>
                                    ))}
                                </Row>


                            </div>


                            <Form.Group className='' controlId="videoUpload" >
                                <Form.Label>{t("Vidéos")}</Form.Label>
                                <Form.Control
                                    key={inputKey + 1}
                                    type="file"
                                    accept="video/*"
                                    multiple
                                    onChange={handleVideoChange}
                                // style={{ padding: 10 }}
                                />
                            </Form.Group>

                            <div className="container my-2">
                                <Row className="g-3">
                                    {selectedVideoList?.map((url, index) => (
                                        <Col
                                            key={index}
                                            xs={12} sm={6} md={6} lg={4}
                                            className="video-container "
                                            style={{ position: "relative" }}
                                        >
                                            <video
                                                src={url}
                                                controls
                                                style={{
                                                    borderRadius: "5px",
                                                    background: '#0000000f',
                                                    width: "100%",
                                                    height: "auto"
                                                }}
                                            />
                                            <button
                                                className="btn btn-danger"
                                                style={{
                                                    width: "30px",
                                                    height: "30px",
                                                    position: "absolute",
                                                    top: "8px",
                                                    right: "12px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: 0,
                                                    borderRadius: "50%",
                                                    fontSize: "12px",
                                                }}
                                                onClick={() => handleRemoveVideo(index)}
                                            >
                                                <IoMdClose size={20} />
                                            </button>
                                        </Col>
                                    ))}
                                </Row>

                            </div>

                        </Form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={(e) => onSubmit(e)} disabled={isLoading}>
                        {isLoading ? <Spinner size="sm" animation="border" /> : t("Repondre")}
                    </Button>
                </Modal.Footer>
            </Modal>



            <Modal size='lg' show={showModalEdit} onHide={handleCloseModalEdit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Respond</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container " >
                        {message && <Alert variant="danger">{message}</Alert>}
                        <Form style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>



                            <Form.Group controlId="description">
                                <Form.Label>{t("Contenu")}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder={t("Entrez le contenu ici")}
                                    defaultValue={responseData?.response_text}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className='' controlId="imageUpload">
                                <Form.Label>{t("Images")}</Form.Label>
                                <Form.Control
                                    key={inputKey}
                                    type="file"
                                    accept=".jpg,.jpeg,.png,.webp"
                                    multiple
                                    onChange={handleImageChangeRes}
                                // style={{ padding: 10 }}
                                />
                            </Form.Group>

                            <div className="container my-2">
                                <Row className="g-3">
                                    {selectedResImagesList?.map((url, index) => (
                                        <Col
                                            key={index}
                                            xs={6} sm={6} md={4} lg={3} // These define the grid size per screen size
                                            className="image-container"
                                            style={{ position: "relative" }}

                                        >
                                            <Image
                                                src={url}
                                                alt={`image-${index}`}
                                                style={{
                                                    borderRadius: "5px",
                                                    width: "100%",
                                                    height: "150px",
                                                    objectFit: "cover"
                                                }}
                                            />
                                            <button
                                            type="button"
                                                className="btn btn-danger"
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    position: "absolute",
                                                    top: "8px",
                                                    right: "12px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: 0,
                                                    borderRadius: "50%",
                                                    fontSize: "12px",
                                                }}
                                                onClick={() => {
                                                    // Filter out the selected image from both lists
                                                    const updatedSelectedResImagesList = selectedResImagesList.filter((e) => e !== url);
                                                    const updatedResImagesList = resImagesList.filter((image) => {
                                                        if (typeof image === 'string') {
                                                            // If it's a string, compare it with the image URL
                                                            return image !== url;
                                                        } else {
                                                            // If it's a file object, compare the object URL
                                                            return URL.createObjectURL(image) !== url;
                                                        }
                                                    });
                                            
                                                    setSelectedResImagesList(updatedSelectedResImagesList);
                                                    setResImagesList(updatedResImagesList);
                                                }}
                                            >
                                                <IoMdClose size={16} />
                                            </button>
                                        </Col>
                                    ))}
                                </Row>


                            </div>


                            <Form.Group className='' controlId="videoUpload" >
                                <Form.Label>{t("Vidéos")}</Form.Label>
                                <Form.Control
                                    key={inputKey + 1}
                                    type="file"
                                    accept="video/*"
                                    multiple
                                    onChange={handleVideoChangeRes}
                                // style={{ padding: 10 }}
                                />
                            </Form.Group>

                            <div className="container my-2">
                                <Row className="g-3">
                                    {selectedResVideoList?.map((url, index) => (
                                        <Col
                                            key={index}
                                            xs={12} sm={6} md={6} lg={4}
                                            className="video-container "
                                            style={{ position: "relative" }}
                                        >
                                            <video
                                                src={url}
                                                controls
                                                style={{
                                                    borderRadius: "5px",
                                                    background: '#0000000f',
                                                    width: "100%",
                                                    height: "auto"
                                                }}
                                            />
                                            <button
                                            type="button"
                                                className="btn btn-danger"
                                                style={{
                                                    width: "30px",
                                                    height: "30px",
                                                    position: "absolute",
                                                    top: "8px",
                                                    right: "12px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: 0,
                                                    borderRadius: "50%",
                                                    fontSize: "12px",
                                                }}
                                                onClick={() => {
                                                    // Filter out the selected video from both lists
                                                    const updatedSelectedResVideoList = selectedResVideoList.filter((e) => e !== url);
                                                    const updatedResVideoList = resVideoList.filter((video) => {
                                                        if (typeof video === 'string') {
                                                            // If it's a string, compare it with the video URL
                                                            return video !== url;
                                                        } else {
                                                            // If it's a file object, compare the object URL
                                                            return URL.createObjectURL(video) !== url;
                                                        }
                                                    });
                                            
                                                    setSelectedResVideoList(updatedSelectedResVideoList);
                                                    setResVideoList(updatedResVideoList);
                                                }}
                                                
                                            >
                                                <IoMdClose size={20} />
                                            </button>
                                        </Col>
                                    ))}
                                </Row>

                            </div>

                        </Form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalEdit}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={(e) => handleEdit(e)} disabled={isLoading}>
                        {isLoading ? <Spinner size="sm" animation="border" /> : t("Modifier la reponse")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

export default Response;
