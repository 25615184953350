import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// import { I18n } from "emoji-mart";
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TranslateIcon from '@mui/icons-material/Translate';
import LanguageIcon from '@mui/icons-material/Language';
import Flags from "country-flag-icons/react/3x2";

const LanguageChanger = ({ currency }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const changeLanguageTrigger = e => {
    console.log("I18n", i18n); // Log the i18n object
    const languageCode = e.target.value;
    i18n.changeLanguage(languageCode);
  };


  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('en'); // Default language

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (language) => {
    // Implement language change logic here
    console.log(`Selected language: ${language}`);
    setSelectedLanguage(language);
    const languageCode = language;
    i18n.changeLanguage(languageCode);
    handleClose();
  };

  const Flag = ({ countryCode }) => {
    const FlagComponent = Flags[countryCode.toUpperCase()];

    if (!FlagComponent) {
      // Handle the case where the country code is not valid or not supported
      return null;
    }

    return <FlagComponent style={{ width: '24px', height: 'auto' }} />;
  };


  return (

    <>
      <Button
        sx={{ color: '#000000' }}
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={<TranslateIcon style={{ color: '#000000' }} />}
      >
        <LanguageIcon />
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleLanguageChange('en')} style={{ display: 'flex', alignItems: 'center' }}>
          <Flag countryCode={"GB"} /> &nbsp;&nbsp;  English

        </MenuItem>

        <MenuItem onClick={() => handleLanguageChange('fn')} style={{ display: 'flex', alignItems: 'center' }}>
          <Flag countryCode={"FR"} /> &nbsp;   Français

        </MenuItem>
      </Menu>
    </>
  );
};

LanguageChanger.propTypes = {
  currency: PropTypes.shape({}),
};

export default LanguageChanger;
