import { success } from "toastr";

// Define action types for Paiement
export const FETCH_PAIEMENTS_REQUEST = 'FETCH_PAIEMENTS_REQUEST';
export const FETCH_PAIEMENTS_SUCCESS = 'FETCH_PAIEMENTS_SUCCESS';
export const FETCH_PAIEMENTS_FAILURE = 'FETCH_PAIEMENTS_FAILURE';

export const ADD_PAIEMENT_REQUEST = 'ADD_PAIEMENT_REQUEST';
export const ADD_PAIEMENT_SUCCESS = 'ADD_PAIEMENT_SUCCESS';
export const ADD_PAIEMENT_FAILURE = 'ADD_PAIEMENT_FAILURE';

export const UPDATE_PAIEMENT_REQUEST = 'UPDATE_PAIEMENT_REQUEST';
export const UPDATE_PAIEMENT_SUCCESS = 'UPDATE_PAIEMENT_SUCCESS';
export const UPDATE_PAIEMENT_FAILURE = 'UPDATE_PAIEMENT_FAILURE';

export const DELETE_PAIEMENT_REQUEST = 'DELETE_PAIEMENT_REQUEST';
export const DELETE_PAIEMENT_SUCCESS = 'DELETE_PAIEMENT_SUCCESS';
export const DELETE_PAIEMENT_FAILURE = 'DELETE_PAIEMENT_FAILURE';

// Fetch paiements
export const fetchPaiementsRequest = () => ({
    type: FETCH_PAIEMENTS_REQUEST
});

export const fetchPaiementsSuccess = (paiements) => ({
    type: FETCH_PAIEMENTS_SUCCESS,
    payload: paiements
});

export const fetchPaiementsFailure = (error) => ({
    type: FETCH_PAIEMENTS_FAILURE,
    payload: error
});

// Add paiement
export const addPaiementRequest = (paiementData) => ({
    type: ADD_PAIEMENT_REQUEST,
    payload: paiementData
});

export const addPaiementSuccess = () => ({
    type: ADD_PAIEMENT_SUCCESS
});

export const addPaiementFailure = (error) => ({
    type: ADD_PAIEMENT_FAILURE,
    payload: error
});

// Update paiement
export const updatePaiementRequest = (paiementData) => ({
    type: UPDATE_PAIEMENT_REQUEST,
    payload: paiementData
});

export const updatePaiementSuccess = () => ({
    type: UPDATE_PAIEMENT_SUCCESS
});

export const updatePaiementFailure = (error) => ({
    type: UPDATE_PAIEMENT_FAILURE,
    payload: error
});

// Delete paiement
export const deletePaiementRequest = (paiementId) => ({
    type: DELETE_PAIEMENT_REQUEST,
    payload: paiementId
});

export const deletePaiementSuccess = () => ({
    type: DELETE_PAIEMENT_SUCCESS
});

export const deletePaiementFailure = (error) => ({
    type: DELETE_PAIEMENT_FAILURE,
    payload: error
});
