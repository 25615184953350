import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import store from './admin/store';
import { ContextProvider } from './context/ContextProvider';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
const stripePromise = loadStripe('pk_test_51PpTxlKPqfF4w4ymJkAiYmRkKNo3pOI0xNbpiumKlgz95AO8Z0TKMxoXrQar19x45t3GGZh4uRFAk4BnbAFo2uIq00hju3LUGJ');

const options = {
  mode: 'payment',
  amount: 1099,
  currency: 'usd',
  appearance: {
    theme: 'stripe', 
    labels: 'floating',
  },
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ContextProvider>
      <Elements stripe={stripePromise} options={options}>
        <App />
      </Elements>
    </ContextProvider>

  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
